import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { KIND } from "baseui/button";
import { ConfirmDialog } from "components/confirm-dialog";
import { Content } from "components/content";
import { FormRenderer } from "components/form-renderer";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { DeviceFloppy, FileOff } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";

import { Allergen } from "../allergens";
import { ALLERGENS_FIELDS, AllergensFormInputs } from "../allergens.form";
import { ALLERGENS_SHOW, ALLERGENS_UPDATE } from "../allergens.gql";

export default function AllergensUpdate(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const { enqueueSnackbar } = useSnackbar();
  const { setIsFetching, isLoading, setIsLoading } = useLoading();
  const { checkPermission } = useAuth();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const {
    control,
    formState: { errors, isDirty, ...formState },
    handleSubmit,
    setValue,
    reset,
    watch,
    ...methods
  } = useForm<AllergensFormInputs>();

  const { refetch, data, error: queryError } = useQuery(ALLERGENS_SHOW, {
    variables: { id: parseInt(id) },
  });
  const allergen: Allergen = data?.allergen;

  useEffect(() => {
    setIsFetching(true);
    if (data?.allergen) refetch();
  }, []);

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  useEffect(() => {
    async function setFile() {
      const response = await fetch(allergen?.imageUrl);
      const data = await response.blob();

      const extension = /\.(jpe?g|png|gif|svg)$/gi.exec(
        allergen?.imageUrl
      )?.[1];

      const metadata = {
        type: `image/${extension === "svg" ? "svg+xml" : "png"}`,
      };

      const file = new File([data], `${allergen?.id}.${extension}`, metadata);

      setValue("image", file);
      setIsFetching(false);
    }

    if (allergen) {
      setValue("name", allergen?.name);
      setValue("isVisible", allergen?.isVisible);

      if (allergen?.imageUrl) {
        setFile();
      } else {
        setIsFetching(false);
      }
    }
  }, [data]);

  const [updateAllergen, { error }] = useMutation(ALLERGENS_UPDATE);

  const onSubmit = async ({
    isVisible,
    name,
    image,
  }: AllergensFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await updateAllergen({
        variables: {
          allergenUpdateInput: {
            id: parseInt(id),
            isVisible: !!isVisible,
            name,
            image,
          },
        },
      });
      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });
      history.push(`/allergens/${id}`);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!checkPermission(PERMISSIONS.allergen.update))
    return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title={allergen?.name}
        labels={["Alergeny", "Edytowanie"]}
        goBackOption
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "updateAllergen",
          },
        ]}
      />
      <Content>
        <FormProvider<AllergensFormInputs>
          control={control}
          formState={{ errors, isDirty, ...formState }}
          handleSubmit={handleSubmit}
          reset={reset}
          setValue={setValue}
          watch={watch}
          {...methods}
        >
          <FormRenderer<AllergensFormInputs>
            id="updateAllergen"
            onSubmit={handleSubmit(onSubmit)}
            type="update"
            isLoading={isLoading}
            error={error}
            fields={ALLERGENS_FIELDS}
          />
        </FormProvider>
        <ConfirmDialog
          isOpen={isCancelConfirmDialogOpen}
          label="Anulowanie edycji alergenu"
          close={() => setIsCancelConfirmDialogOpen(false)}
          confirm={() => history.goBack()}
        />
      </Content>
    </article>
  );
}
