import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { KIND } from "baseui/button";
import { ConfirmDialog } from "components/confirm-dialog";
import { Content } from "components/content";
import { FormRenderer } from "components/form-renderer";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { DeviceFloppy, FileOff } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";

import { Ingredient } from "../ingredients";
import { INGREDIENTS_FIELDS, IngredientsFormInputs } from "../ingredients.form";
import { INGREDIENTS_SHOW, INGREDIENTS_UPDATE } from "../ingredients.gql";

export default function IngredientsUpdate(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const { enqueueSnackbar } = useSnackbar();
  const { setIsFetching, isLoading, setIsLoading } = useLoading();
  const { checkPermission } = useAuth();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();

  const {
    control,
    formState: { errors, isDirty, ...formState },
    handleSubmit,
    setValue,
    reset,
    watch,
    ...methods
  } = useForm<IngredientsFormInputs>();

  const { refetch, data, error: queryError } = useQuery(INGREDIENTS_SHOW, {
    variables: { id: id ? parseInt(id) : null },
  });
  const ingredient: Ingredient = data?.ingredient;

  useEffect(() => {
    setIsFetching(true);
    if (data?.ingredient) refetch();
  }, []);

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  useEffect(() => {
    if (ingredient) {
      setValue("name", ingredient?.name);
      setValue("isVisible", ingredient?.isVisible);
      setValue("calorieAmount", ingredient?.calorieAmount);
      setValue("proteinAmount", ingredient?.proteinAmount);
      setValue("carbohydrateAmount", ingredient?.carbohydrateAmount);
      setValue("fatAmount", ingredient?.fatAmount);
      setValue("teaspoon", ingredient?.teaspoon);
      setValue("unit", ingredient?.unit);
      setValue("tablespoon", ingredient?.tablespoon);
      setValue("glass", ingredient?.glass);
      setValue("pinch", ingredient?.pinch);
      setValue("cube", ingredient?.cube);
      setValue("can", ingredient?.can);
      setValue("bunch", ingredient?.bunch);
      setValue("package", ingredient?.package);
      setValue("slice", ingredient?.slice);
      setValue("twig", ingredient?.twig);

      setIsFetching(false);
    }
  }, [data]);

  const [updateIngredient, { error }] = useMutation(INGREDIENTS_UPDATE);

  const onSubmit = async ({
    isVisible,
    ...values
  }: IngredientsFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await updateIngredient({
        variables: {
          ingredientUpdateInput: {
            id: id ? parseInt(id) : null,
            isVisible: !!isVisible,
            ...values,
          },
        },
      });
      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });
      history.push(`/ingredients/${id}`);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!checkPermission(PERMISSIONS.ingredient.update))
    return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title={ingredient?.name}
        labels={["Składniki", "Edytowanie"]}
        goBackOption
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "updateIngredient",
          },
        ]}
      />
      <Content>
        <FormProvider<IngredientsFormInputs>
          control={control}
          formState={{ errors, isDirty, ...formState }}
          handleSubmit={handleSubmit}
          reset={reset}
          setValue={setValue}
          watch={watch}
          {...methods}
        >
          <FormRenderer<IngredientsFormInputs>
            id="updateIngredient"
            onSubmit={handleSubmit(onSubmit)}
            type="update"
            isLoading={isLoading}
            error={error}
            fields={INGREDIENTS_FIELDS}
          />
        </FormProvider>
        <ConfirmDialog
          isOpen={isCancelConfirmDialogOpen}
          label="Anulowanie edycji składnika"
          close={() => setIsCancelConfirmDialogOpen(false)}
          confirm={() => history.goBack()}
        />
      </Content>
    </article>
  );
}
