import { useStyletron } from "baseui";
import { StyledLink } from "baseui/link";
import React, { MouseEvent } from "react";
import { useHistory } from "react-router";

type Props = {
  href: string;
  imageUrl: string | undefined;
  label?: string;
};

export default function Thumbnail({
  href,
  imageUrl,
  label,
}: Props): React.ReactElement {
  const [css, theme] = useStyletron();
  const history = useHistory();

  return (
    <StyledLink
      onClick={(event: MouseEvent) => {
        event.preventDefault();
        history.push(href);
      }}
      href={href}
    >
      <div className={css({ display: "flex", alignItems: "center" })}>
        <div
          className={css({
            minWidth: "25px",
            minHeight: "25px",
            borderRadius: "50%",
            border: "1px solid black",
            backgroundColor: theme.colors.primary100,
            ...(imageUrl && {
              backgroundImage: `url(${imageUrl}?preset=thumbnail)`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }),
          })}
        />
        {label}
      </div>
    </StyledLink>
  );
}
