import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { KIND } from "baseui/button";
import { ConfirmDialog } from "components/confirm-dialog";
import { Content } from "components/content";
import { FormRenderer } from "components/form-renderer";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { DeviceFloppy, FileOff } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";

import { Product } from "../products";
import { PRODUCTS_FIELDS, ProductsFormInputs } from "../products.form";
import { PRODUCTS_SHOW, PRODUCTS_UPDATE } from "../products.gql";

export default function ProductsUpdate(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const { enqueueSnackbar } = useSnackbar();
  const { setIsFetching, isLoading, setIsLoading } = useLoading();
  const { checkPermission } = useAuth();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();

  const {
    control,
    formState: { errors, isDirty, ...formState },
    handleSubmit,
    setValue,
    reset,
    watch,
    ...methods
  } = useForm<ProductsFormInputs>();

  const { refetch, data, error: queryError } = useQuery(PRODUCTS_SHOW, {
    variables: { id: id ? parseInt(id) : null },
  });
  const product: Product = data?.product;

  useEffect(() => {
    setIsFetching(true);
    if (data?.product) refetch();
  }, []);

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  useEffect(() => {
    async function setFile() {
      const response = await fetch(product?.imageUrl);
      const data = await response.blob();

      const extension = /\.(jpe?g|png|gif|svg)$/gi.exec(product?.imageUrl)?.[1];

      const metadata = {
        type: `image/${extension === "svg" ? "svg+xml" : "png"}`,
      };

      const file = new File([data], `${product?.slug}.${extension}`, metadata);

      setValue("image", file);
      setIsFetching(false);
    }

    if (product) {
      setValue("name", product?.name);
      setValue("content", product?.content);
      setValue("isVisible", product?.isVisible);
      setValue("slug", product?.slug);

      if (product?.imageUrl) {
        setFile();
      } else {
        setIsFetching(false);
      }
    }
  }, [data]);

  const [updateProduct, { error }] = useMutation(PRODUCTS_UPDATE);

  const onSubmit = async ({
    isVisible,
    ...values
  }: ProductsFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await updateProduct({
        variables: {
          productUpdateInput: {
            id: id ? parseInt(id) : null,
            isVisible: !!isVisible,
            ...values,
          },
        },
      });
      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });
      history.push(`/products/${id}`);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!checkPermission(PERMISSIONS.product.update))
    return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title={product?.name}
        labels={["Produkty", "Edytowanie"]}
        goBackOption
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "updateProduct",
          },
        ]}
      />
      <Content>
        <FormProvider<ProductsFormInputs>
          control={control}
          formState={{ errors, isDirty, ...formState }}
          handleSubmit={handleSubmit}
          reset={reset}
          setValue={setValue}
          watch={watch}
          {...methods}
        >
          <FormRenderer<ProductsFormInputs>
            id="updateProduct"
            onSubmit={handleSubmit(onSubmit)}
            type="update"
            isLoading={isLoading}
            error={error}
            fields={PRODUCTS_FIELDS}
          />
        </FormProvider>
        <ConfirmDialog
          isOpen={isCancelConfirmDialogOpen}
          label="Anulowanie edycji produktu"
          close={() => setIsCancelConfirmDialogOpen(false)}
          confirm={() => history.goBack()}
        />
      </Content>
    </article>
  );
}
