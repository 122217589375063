import { useLazyQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Article } from "containers/Articles/articles";
import { ARTICLES_LIVE_SEARCH } from "containers/Articles/articles.gql";
import { useAuth } from "contexts/auth-context";
import React, { FormEvent, useEffect } from "react";
import { Controller, UseControllerProps } from "react-hook-form";
import { Ban } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";

import { LiveSearchSelect, SelectProps } from "./select";

export default function ArticlesSelect({
  disabled,
  ...rest
}: SelectProps): React.ReactElement {
  const { checkPermission } = useAuth();
  const [css] = useStyletron();

  const [fetchData, { data, loading }] = useLazyQuery(ARTICLES_LIVE_SEARCH);

  useEffect(() => fetchData(), []);

  const hasPermission = checkPermission(PERMISSIONS.article.read);

  return (
    <LiveSearchSelect
      options={data?.articles?.nodes?.map(({ id, name }: Article) => ({
        id,
        label: name,
      }))}
      getValueLabel={({ option }) => `${option?.label}`}
      getOptionLabel={({ option }) => `${option?.label}`}
      onInputChange={(event: FormEvent) => {
        fetchData({
          variables: {
            filter: {
              or: [
                {
                  name: {
                    like: `%${(event.currentTarget as any).value}%`,
                  },
                },
              ],
            },
          },
        });
      }}
      placeholder={
        hasPermission ? (
          "Wybierz"
        ) : (
          <div
            className={css({
              display: "flex",
              alignItems: "center",
              gap: "5px",
            })}
          >
            <Ban size={16} />
            Brak uprawnień do przeglądania artykułów
          </div>
        )
      }
      disabled={disabled || !hasPermission}
      isLoading={loading}
      {...rest}
    />
  );
}

export function ControlledArticlesSelect({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & SelectProps): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <ArticlesSelect
          id={name}
          value={value}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          {...rest}
        />
      )}
    />
  );
}
