import { DataType } from "components/formatted-value";

import { FieldsGroup, FieldType } from "../../fields.d";

export type IngredientsFormInputs = {
  name: string;
  isVisible: boolean;
  calorieAmount: number;
  proteinAmount: number;
  carbohydrateAmount: number;
  fatAmount: number;
  teaspoon: number;
  unit: number;
  tablespoon: number;
  glass: number;
  pinch: number;
  cube: number;
  can: number;
  bunch: number;
  package: number;
  slice: number;
  twig: number;
};

export enum IngredientsField {
  Name = "name",
  CreatedAt = "createdAt",
  UpdatedAt = "updatedAt",
  IsVisible = "isVisible",
  CreatedBy = "createdBy",
  UpdatedBy = "updatedBy",
  CalorieAmount = "calorieAmount",
  ProteinAmount = "proteinAmount",
  CarbohydrateAmount = "carbohydrateAmount",
  FatAmount = "fatAmount",
  Teaspoon = "teaspoon",
  Unit = "unit",
  Tablespoon = "tablespoon",
  Glass = "glass",
  Pinch = "pinch",
  Cube = "cube",
  Can = "can",
  Bunch = "bunch",
  Package = "package",
  Slice = "slice",
  Twig = "twig",
}

export const INGREDIENTS_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: IngredientsField.Name,
        label: "Nazwa",
        type: FieldType.BigInput,
        span: 12,
        show: { visible: true },
        create: { visible: false },
        update: { visible: true, required: true },
      },
      {
        id: IngredientsField.IsVisible,
        label: "Widoczność",
        type: FieldType.Switch,
        dataType: DataType.VisibilityBoolean,
        readonly: true,
        span: 6,
        show: { visible: true },
        create: { visible: false },
        update: { visible: true },
      },
    ],
  },
  {
    id: "nutrients",
    label: "Składniki odżywcze (na 100g lub 100ml)",
    fields: [
      {
        id: IngredientsField.CalorieAmount,
        label: "Liczba kalorii",
        type: FieldType.NumberInput,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        update: { visible: true },
        endEnhancer: "kcal",
        dataType: DataType.Calories,
      },
      {
        id: IngredientsField.ProteinAmount,
        label: "Białko",
        type: FieldType.NumberInput,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        update: { visible: true },
        endEnhancer: "g",
        dataType: DataType.Gram,
      },
      {
        id: IngredientsField.CarbohydrateAmount,
        label: "Węglowodany",
        type: FieldType.NumberInput,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        update: { visible: true },
        endEnhancer: "g",
        dataType: DataType.Gram,
      },
      {
        id: IngredientsField.FatAmount,
        label: "Tłuszcz",
        type: FieldType.NumberInput,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        update: { visible: true },
        endEnhancer: "g",
        dataType: DataType.Gram,
      },
    ],
  },
  {
    id: "conversionRates",
    label: "Przeliczniki na gramy",
    fields: [
      {
        id: IngredientsField.Teaspoon,
        label: "Łyżeczka",
        type: FieldType.NumberInput,
        span: 2,
        show: { visible: true },
        create: { visible: false },
        update: { visible: true },
        endEnhancer: "g",
        dataType: DataType.Gram,
      },
      {
        id: IngredientsField.Tablespoon,
        label: "Łyżka",
        type: FieldType.NumberInput,
        span: 2,
        show: { visible: true },
        create: { visible: false },
        update: { visible: true },
        endEnhancer: "g",
        dataType: DataType.Gram,
      },
      {
        id: IngredientsField.Unit,
        label: "Sztuka",
        type: FieldType.NumberInput,
        span: 2,
        show: { visible: true },
        create: { visible: false },
        update: { visible: true },
        endEnhancer: "g",
        dataType: DataType.Gram,
      },
      {
        id: IngredientsField.Glass,
        label: "Szklanka",
        type: FieldType.NumberInput,
        span: 2,
        show: { visible: true },
        create: { visible: false },
        update: { visible: true },
        endEnhancer: "g",
        dataType: DataType.Gram,
      },
      {
        id: IngredientsField.Pinch,
        label: "Szczypta",
        type: FieldType.NumberInput,
        span: 2,
        show: { visible: true },
        create: { visible: false },
        update: { visible: true },
        endEnhancer: "g",
        dataType: DataType.Gram,
      },
      {
        id: IngredientsField.Cube,
        label: "Kostka",
        type: FieldType.NumberInput,
        span: 2,
        show: { visible: true },
        create: { visible: false },
        update: { visible: true },
        endEnhancer: "g",
        dataType: DataType.Gram,
      },
      {
        id: IngredientsField.Can,
        label: "Puszka",
        type: FieldType.NumberInput,
        span: 2,
        show: { visible: true },
        create: { visible: false },
        update: { visible: true },
        endEnhancer: "g",
        dataType: DataType.Gram,
      },
      {
        id: IngredientsField.Bunch,
        label: "Pęczek",
        type: FieldType.NumberInput,
        span: 2,
        show: { visible: true },
        create: { visible: false },
        update: { visible: true },
        endEnhancer: "g",
        dataType: DataType.Gram,
      },
      {
        id: IngredientsField.Package,
        label: "Opakowanie",
        type: FieldType.NumberInput,
        span: 2,
        show: { visible: true },
        create: { visible: false },
        update: { visible: true },
        endEnhancer: "g",
        dataType: DataType.Gram,
      },
      {
        id: IngredientsField.Slice,
        label: "Plaster",
        type: FieldType.NumberInput,
        span: 2,
        show: { visible: true },
        create: { visible: false },
        update: { visible: true },
        endEnhancer: "g",
        dataType: DataType.Gram,
      },
      {
        id: IngredientsField.Twig,
        label: "Łodyga",
        type: FieldType.NumberInput,
        span: 2,
        show: { visible: true },
        create: { visible: false },
        update: { visible: true },
        endEnhancer: "g",
        dataType: DataType.Gram,
      },
    ],
  },
];
