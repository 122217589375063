import { DataType } from "components/formatted-value";

import { FieldsGroup, FieldType } from "../../fields.d";

export type ProductsFormInputs = {
  name: string;
  slug: string;
  content: string;
  isVisible: boolean;
  image: File;
};

export enum ProductsField {
  Name = "name",
  Slug = "slug",
  Content = "content",
  CreatedAt = "createdAt",
  UpdatedAt = "updatedAt",
  IsVisible = "isVisible",
  ImageUrl = "imageUrl",
  Image = "image",
  CreatedBy = "createdBy",
  UpdatedBy = "updatedBy",
}

export const PRODUCTS_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: ProductsField.Name,
        label: "Nazwa",
        type: FieldType.BigInput,
        span: 12,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
      {
        id: ProductsField.Slug,
        label: "Slug",
        type: FieldType.SlugInput,
        dataType: DataType.Pre,
        span: 12,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
        caption:
          "Slug jest generowany automatycznie na podstawie nazwy. Możesz go zmienić, edytując powyższe pole.",
      },
      {
        id: ProductsField.Content,
        label: "Treść",
        span: 12,
        type: FieldType.TextArea,
        show: { visible: false },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ProductsField.IsVisible,
        label: "Widoczność",
        type: FieldType.Switch,
        dataType: DataType.VisibilityBoolean,
        readonly: true,
        span: 6,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: ProductsField.Image,
        label: "Grafika",
        span: 12,
        type: FieldType.ImageCropper,
        imageRequirements: { aspect: 1 },
        show: { visible: false },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
      {
        id: ProductsField.ImageUrl,
        label: "Grafika",
        span: 12,
        type: FieldType.Image,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
    ],
  },
  {
    id: "system",
    label: "Dane systemowe",
    fields: [
      {
        id: ProductsField.CreatedAt,
        label: "Utworzenie",
        span: 3,
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ProductsField.CreatedBy,
        label: "Utworzono przez",
        span: 3,
        type: FieldType.UserFullName,
        dataType: DataType.Users,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ProductsField.UpdatedAt,
        label: "Ostatnia aktualizacja",
        span: 3,
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ProductsField.UpdatedBy,
        label: "Zaktualizowano przez",
        span: 3,
        type: FieldType.UserFullName,
        dataType: DataType.Users,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
    ],
  },
];
