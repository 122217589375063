import { ApolloError, useMutation } from "@apollo/client";
import { InputValidationError } from "api";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { FormControl } from "baseui/form-control";
import { ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import { Button } from "components/button";
import {
  ControlledFilesPicker,
  FilesPickerType,
} from "components/files-picker/files-picker";
import { SEO_TAGSETS_UPDATE_META_FROM_XLSX_FILE } from "containers/SeoTagsets/seo-tagsets.gql";
import { useLoading } from "contexts/loading-context";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { AlertOctagon } from "tabler-icons-react";
import { FORM_VALIDATION_MESSAGES } from "utils/form/validation-messages";

import Modal from "./modal";

type ImportSeoTagsModalProps = {
  isOpen: boolean;
  refetch: () => void;
  close: () => void;
};

export default function ImportSeoTagsModal({
  isOpen,
  refetch,
  close,
}: ImportSeoTagsModalProps): React.ReactElement {
  const [css] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, setIsLoading } = useLoading();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<{ file: File[] }>();

  const [importFile, { error, loading }] = useMutation(
    SEO_TAGSETS_UPDATE_META_FROM_XLSX_FILE
  );

  const onSubmit = async ({ file }: { file: File[] }) => {
    setIsLoading(true);

    try {
      await importFile({
        variables: {
          seoTagsetUploadInput: {
            file: file[0] || null,
          },
        },
      });
      enqueueSnackbar({
        message: "Zaimportowano pomyślnie",
        variant: "success",
      });

      close();
      reset();
      refetch();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        if (!loading) {
          reset();
          close();
        }
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>Import z pliku XLSX</ModalHeader>

        <ModalBody>
          <FormControl
            error={
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ((errors as any)["file"] && (errors as any)["file"].message) ||
              (error &&
                error.graphQLErrors[0]?.extensions?.code ===
                  "INPUT_VALIDATION_ERROR" &&
                error.graphQLErrors[0]?.extensions?.validationErrors
                  ?.find((vE: InputValidationError) => vE?.property === "file")
                  ?.errors.map((message: string) => (
                    <div
                      key="error"
                      className={css({
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      })}
                    >
                      {message}
                      <span
                        className={css({
                          color: "#999",
                          marginLeft: "auto",
                          marginRight: "5px",
                        })}
                      >
                        Walidacja serwera
                      </span>
                      <AlertOctagon color="#999" size={12} />
                    </div>
                  ))[0])
            }
            disabled={isLoading}
            caption="Nie należy zmieniać wartości w kolumnach identyfikator, typ obiektu, identyfikator obiektu, data usunięcia i nazwa obiektu."
          >
            <ControlledFilesPicker
              control={control}
              name="file"
              filesPickerType={FilesPickerType.SingleFile}
              loading={isLoading}
              accept={".xlsx, .csv, .xls"}
              rules={{
                required: FORM_VALIDATION_MESSAGES.required,
              }}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            type="button"
            kind={KIND.secondary}
            $style={{ marginRight: "10px" }}
            onClick={() => {
              close();
              reset();
            }}
            disabled={loading}
          >
            Anuluj
          </Button>

          <Button
            disabled={loading}
            isLoading={loading}
            type="submit"
            kind="primary"
          >
            Importuj
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
