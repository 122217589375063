import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { KIND } from "baseui/button";
import { AuthorType } from "components/author-selector/author-selector";
import { ConfirmDialog } from "components/confirm-dialog";
import { Content } from "components/content";
import { FormRenderer } from "components/form-renderer";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { Allergen } from "containers/Allergens/allergens";
import { Category } from "containers/Categories/categories";
import { Tag } from "containers/Tags/tags";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { DeviceFloppy, FileOff } from "tabler-icons-react";
import { generateUUID } from "utils/misc";
import { PERMISSIONS } from "utils/permissions";
import { scrollOnError } from "utils/scrollOnError";

import { Recipe, RecipeStep } from "../recipes";
import { RECIPES_FIELDS, RecipesFormInputs } from "../recipes.form";
import { RECIPES_SHOW, RECIPES_UPDATE } from "../recipes.gql";

export default function RecipesUpdate(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const { enqueueSnackbar } = useSnackbar();
  const {
    setIsPartialFetching,
    setIsFetching,
    isLoading,
    setIsLoading,
  } = useLoading();
  const { checkPermission } = useAuth();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();

  const {
    control,
    formState: { errors, isDirty, ...formState },
    handleSubmit,
    setValue,
    reset,
    unregister,
    watch,
    ...methods
  } = useForm<RecipesFormInputs>();

  const { refetch, data, error: queryError } = useQuery(RECIPES_SHOW, {
    variables: { id: id ? parseInt(id) : null },
  });
  const recipe: Recipe = data?.recipe;

  useEffect(() => {
    setIsFetching(true);
    if (data?.recipe) refetch();
  }, []);

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  useEffect(() => {
    async function setFile() {
      const response = await fetch(recipe?.imageUrl);
      const data = await response.blob();

      const extension = /\.(jpe?g|png|gif|svg)$/gi.exec(recipe?.imageUrl)?.[1];

      const metadata = {
        type: `image/${extension === "svg" ? "svg+xml" : "png"}`,
      };

      const file = new File([data], `${recipe?.slug}.${extension}`, metadata);

      setValue("image", file);
      setIsFetching(false);
    }

    if (data?.recipe) {
      setValue("name", recipe?.name);
      setValue("content", recipe?.content);
      setValue("isVisible", recipe?.isVisible);
      setValue("slug", recipe?.slug);
      setValue("difficulty", [{ id: recipe?.difficulty }]);
      setValue("preparationTime", recipe?.preparationTime);
      setValue("servingCount", recipe?.servingCount);
      setValue("videoUrl", recipe?.videoUrl);

      setValue("categories", recipe?.categories);
      setValue("tags", recipe?.tags);
      setValue("allergens", recipe?.allergens);

      !!recipe?.recipeSteps?.length &&
        setValue(
          "recipeSteps",
          recipe?.recipeSteps?.map((step: RecipeStep) => ({
            id: step?.id,
            position: step?.position,
            content: step?.content,
            additionalInfo:
              step.additionalInfo || `${step.id}-${generateUUID()}`,
          }))
        );

      !!recipe?.ingredientGroups?.length &&
        setValue("ingredientGroups", recipe?.ingredientGroups);

      if (recipe?.author?.user) {
        setValue("author", {
          type: AuthorType.User,
          value: recipe?.author?.user?.id,
          user: {
            firstName: recipe?.author?.user?.firstName,
            lastName: recipe?.author?.user?.lastName,
            username: recipe?.author?.user?.username,
            email: recipe?.author?.user?.email,
          },
        });
      }
      if (recipe?.author?.brand) {
        setValue("author", {
          type: AuthorType.Brand,
          value: recipe?.author?.brand?.id,
        });
      }
      if (recipe?.author?.name) {
        setValue("author", {
          type: AuthorType.AuthorName,
          value: recipe?.author?.name,
        });
      }
      setValue("preparationTime", recipe?.preparationTime);
      setValue("metaTitle", recipe?.seoTagset?.metaTitle);

      setValue("metaTitle", recipe?.seoTagset?.metaTitle);
      setValue("metaDescription", recipe?.seoTagset?.metaDescription);
      setValue("ogTitle", recipe?.seoTagset?.ogTitle);
      setValue("ogDescription", recipe?.seoTagset?.ogDescription);
      setValue("metaKeywords", recipe?.seoTagset?.metaKeywords);
      setValue("canonical", recipe?.seoTagset?.canonical);
      !!recipe?.seoTagset?.robots &&
        setValue("robots", [
          {
            id: recipe?.seoTagset?.robots,
            label: recipe?.seoTagset?.robots,
          },
        ]);

      setTimeout(() => setIsFetching(false), 250);

      setTimeout(() => {
        setIsPartialFetching(true);

        setFile().then(() => setIsPartialFetching(false));
      }, 500);
    }
  }, [data]);

  const [errorTimeStamp, setErrorTimeStamp] = useState<number>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onError = (errors: Record<string, unknown>, e: any) =>
    setErrorTimeStamp(e.timeStamp);

  useEffect(() => {
    if (errors) scrollOnError(errors, RECIPES_FIELDS, "update");
  }, [errorTimeStamp]);

  const [updateRecipe, { error }] = useMutation(RECIPES_UPDATE);

  const onSubmit = async ({
    categories,
    allergens,
    isVisible,
    tags,
    difficulty,
    metaTitle,
    metaDescription,
    metaKeywords,
    ogTitle,
    ogDescription,
    canonical,
    robots,
    author,
    preparationTime,
    servingCount,
    image,
    ...values
  }: RecipesFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await updateRecipe({
        variables: {
          recipeUpdateInput: {
            id: id ? parseInt(id) : null,
            isVisible: !!isVisible,
            categoryIds: categories?.map((category: Category) => category?.id),
            allergenIds: allergens?.map((allergen: Allergen) => allergen?.id),
            tagIds: tags?.map((tag: Tag) => tag?.id),
            difficulty: difficulty?.[0]?.id,
            preparationTime: parseInt(preparationTime.toString()),
            servingCount: parseInt(servingCount.toString()),
            image,
            ...values,
          },
          recipeAuthorAssignInput: {
            ...(author.type === AuthorType.User && {
              userId: author.value,
            }),
            ...(author.type === AuthorType.Brand && {
              brandId: author.value,
            }),
            ...(author.type === AuthorType.AuthorName && {
              authorName: author.value,
            }),
          },
          seoTagsetUpsertInput: {
            metaTitle,
            metaDescription,
            metaKeywords,
            ogTitle,
            ogDescription,
            canonical,
            robots: robots?.length ? robots?.[0]?.label : null,
          },
        },
      });
      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });
      history.push(`/recipes/${id}`);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!checkPermission(PERMISSIONS.recipe.update))
    return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title={recipe?.name}
        labels={["Przepisy", "Edytowanie"]}
        goBackOption
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit, onError),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "updateRecipe",
          },
        ]}
      />
      <Content>
        <FormProvider<RecipesFormInputs>
          control={control}
          formState={{ errors, isDirty, ...formState }}
          handleSubmit={handleSubmit}
          reset={reset}
          setValue={setValue}
          unregister={unregister}
          watch={watch}
          {...methods}
        >
          <FormRenderer<RecipesFormInputs>
            id="updateRecipe"
            onSubmit={handleSubmit(onSubmit)}
            type="update"
            isLoading={isLoading}
            error={error}
            fields={RECIPES_FIELDS}
          />
        </FormProvider>
        <ConfirmDialog
          isOpen={isCancelConfirmDialogOpen}
          label="Anulowanie edycji przepisu"
          close={() => setIsCancelConfirmDialogOpen(false)}
          confirm={() => history.goBack()}
        />
      </Content>
    </article>
  );
}
