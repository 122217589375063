import { BaseProvider } from "baseui";
import { ToasterContainer } from "baseui/toast";
import theme from "baseweb";
import { AutoScroll } from "components/auto-scroll";
import { Layout } from "components/layout";
import { Route } from "components/route";
import {
  SnackbarError,
  SnackbarInfo,
  SnackbarSuccess,
  SnackbarWarning,
} from "components/snackbar/snackbar";
import { AuthProvider } from "contexts/auth-context";
import { DictionariesProvider } from "contexts/dictionaries-context";
import { ImageLibraryProvider } from "contexts/image-library-context";
import { LoadingProvider } from "contexts/loading-context";
import { PagingProvider } from "contexts/paging-context";
import { SupportProvider } from "contexts/support-context";
import { SnackbarProvider } from "notistack";
import React, { HTMLProps } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import ROUTES from "routes";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { X } from "tabler-icons-react";

const engine = new Styletron({
  prefix: "mxu__",
});

export default function App(): React.ReactElement {
  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={theme}>
        <ToasterContainer
          placement="topRight"
          overrides={{
            Root: {
              style: {
                zIndex: 16,
              },
            },
            ToastBody: {
              style: {
                position: "relative",
                backgroundColor: theme.colors.white,
                color: theme.colors.black,
                borderTopLeftRadius: theme.borders.radius200,
                borderTopRightRadius: theme.borders.radius200,
                borderBottomRightRadius: theme.borders.radius200,
                borderBottomLeftRadius: theme.borders.radius200,
              },
            },
            ToastCloseIcon: (props: HTMLProps<HTMLElement>) => (
              <span
                style={{
                  position: "absolute",
                  top: "17px",
                  right: "16px",
                  cursor: "pointer",
                  display: "inline",
                  lineHeight: 0,
                }}
                {...props}
              >
                <X color={theme.colors.black} size={15} />
              </span>
            ),
          }}
        />

        <LoadingProvider>
          <AuthProvider>
            <SupportProvider>
              <DictionariesProvider>
                <SnackbarProvider
                  maxSnack={3}
                  autoHideDuration={3000}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  dense
                  Components={{
                    success: SnackbarSuccess,
                    error: SnackbarError,
                    warning: SnackbarWarning,
                    info: SnackbarInfo,
                  }}
                  style={{
                    boxShadow: "rgb(0 0 0 / 16%) 0px 4px 16px",
                    borderRadius: "4px",
                    paddingLeft: "12px",
                    paddingRight: "12px",
                    paddingTop: "12px",
                    paddingBottom: "12px",
                    fontSize: "14px",
                  }}
                >
                  <Router>
                    <PagingProvider>
                      <AutoScroll />
                      <Switch>
                        {Object.values(ROUTES.auth).map((route, index) => (
                          <Route
                            key={`auth-route` + index}
                            exact={route.exact}
                            path={route.path}
                            component={route.component}
                          />
                        ))}

                        {Object.values(ROUTES.shared).map((route, index) => (
                          <Route
                            $shared
                            key={`shared-route` + index}
                            path={route.path}
                            component={route.component}
                            strict={false}
                          />
                        ))}

                        <Layout>
                          <ImageLibraryProvider>
                            <Switch>
                              {Object.values(ROUTES.private.basic).map(
                                (route, index) => (
                                  <Route
                                    $private
                                    key={`private-route` + index}
                                    exact={route.exact}
                                    path={route.path}
                                    component={route.component}
                                  />
                                )
                              )}

                              {Object.values(ROUTES.private.models).map(
                                (route) => {
                                  return [
                                    route.index && (
                                      <Route
                                        $private
                                        key={route.name + `-route-index`}
                                        path={route.path + route?.index?.path}
                                        component={route?.index?.component}
                                        exact
                                      />
                                    ),
                                    route.update && (
                                      <Route
                                        $private
                                        key={route.name + `-route-update`}
                                        path={route.path + route?.update?.path}
                                        component={route?.update?.component}
                                        exact
                                      />
                                    ),
                                    route.create && (
                                      <Route
                                        $private
                                        key={route.name + `-route-create`}
                                        path={route.path + route?.create?.path}
                                        component={route?.create?.component}
                                        exact
                                      />
                                    ),
                                    route.show && (
                                      <Route
                                        $private
                                        key={route.name + `-route-show`}
                                        path={route.path + route?.show?.path}
                                        component={route?.show?.component}
                                        exact
                                      />
                                    ),
                                  ];
                                }
                              )}

                              {Object.values(ROUTES.fallback).map(
                                (route, index) => (
                                  <Route
                                    $private
                                    key={`private-route` + index}
                                    exact={route.exact}
                                    path={route.path}
                                    component={route.component}
                                  />
                                )
                              )}
                            </Switch>
                          </ImageLibraryProvider>
                        </Layout>
                      </Switch>
                    </PagingProvider>
                  </Router>
                </SnackbarProvider>
              </DictionariesProvider>
            </SupportProvider>
          </AuthProvider>
        </LoadingProvider>
      </BaseProvider>
    </StyletronProvider>
  );
}
