import { styled, useStyletron } from "baseui";
import { Avatar as BaseAvatar } from "baseui/avatar";
import { Block } from "baseui/block";
import { StyledLink } from "baseui/link";
import { PLACEMENT } from "baseui/popover";
import { Tag } from "baseui/tag";
import { HeadingSmall, LabelMedium, LabelSmall } from "baseui/typography";
import { SubmenuPopover } from "components/popover";
import { Tooltip } from "components/tooltip";
import { useAuth } from "contexts/auth-context";
import React, { Fragment, MouseEvent, useState } from "react";
import { useHistory, useLocation } from "react-router";
import {
  Article,
  Book,
  CakeOff,
  Carrot,
  Category,
  ChefHat,
  ChevronRight,
  ClipboardList,
  Direction,
  FaceId,
  Icon,
  Logout,
  News,
  Photo,
  Registered,
  Salad,
  Seo,
  Shield,
  Slideshow,
  Tags,
  User,
  Users,
} from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";
import { renderUserLabel } from "utils/render-user-label";

import { APP_NAME, ReactAppSystemVersion } from "../../constants";

const Container = styled("nav", {
  position: "relative",
  backgroundColor: "#f9f9f9",
  display: "flex",
  flexDirection: "column",
  overflowX: "auto",
  height: "100vh",
  paddingBottom: "15px",
  scrollbarWidth: "none",
  msOverflowStyle: "none",
  borderRight: "2px inset #eeeeee",
  "::-webkit-scrollbar": {
    display: "none",
  },
});

type NavCategory = {
  label?: string;
  items: NavItem[];
};

type NavItem = {
  label: string;
  icon: Icon;
  href: string;
  permission?: string;
  exact?: boolean;
  submenu?: NavItem[];
};

const USER_NAV: NavItem[] = [
  {
    label: "Moje konto",
    icon: User,
    href: "/my-account",
  },
  {
    label: "Wyloguj się",
    icon: Logout,
    href: "/logout",
  },
];

const NAV: NavCategory[] = [
  {
    label: "Treści",
    items: [
      {
        label: "Artykuły",
        icon: News,
        href: "/articles",
        permission: PERMISSIONS.article.read,
      },
      {
        label: "Marki",
        icon: Registered,
        href: "/brands",
        permission: PERMISSIONS.brand.read,
      },
      {
        label: "Produkty",
        icon: Carrot,
        href: "/products",
        permission: PERMISSIONS.product.read,
      },
      {
        label: "Składniki",
        icon: Salad,
        href: "/ingredients",
        permission: PERMISSIONS.ingredient.read,
      },
      {
        label: "Przepisy",
        icon: ChefHat,
        href: "/recipes",
        permission: PERMISSIONS.recipe.read,
      },
      {
        label: "Strony informacyjne",
        icon: Article,
        href: "/static-pages",
        permission: PERMISSIONS.staticPage.read,
      },
      {
        label: "Alergeny",
        icon: CakeOff,
        href: "/allergens",
        permission: PERMISSIONS.allergen.read,
      },
      {
        label: "Kategorie",
        icon: Category,
        href: "/categories",
        permission: PERMISSIONS.category.read,
      },
      {
        label: "Slajdy",
        icon: Slideshow,
        href: "/slides",
        permission: PERMISSIONS.slide.read,
      },
      {
        label: "Grafiki",
        icon: Photo,
        href: "/images",
        permission: PERMISSIONS.image.read,
      },
      {
        label: "Tagi",
        icon: Tags,
        href: "/tags",
        permission: PERMISSIONS.tag.read,
      },
      {
        label: "Tagi SEO",
        icon: Seo,
        href: "/seo-tagsets",
        permission: PERMISSIONS.seoTagset.read,
      },
    ],
  },
  {
    label: "Administracja",
    items: [
      {
        label: "Awatary",
        icon: FaceId,
        href: "/avatars",
        permission: PERMISSIONS.avatar.read,
      },
      {
        label: "Użytkownicy",
        icon: Users,
        href: "/users",
        permission: PERMISSIONS.user.read,
      },
      {
        label: "Role i uprawnienia",
        icon: Shield,
        href: "/roles",
        permission: PERMISSIONS.role.read,
      },
      {
        label: "Słowniki systemowe",
        icon: Book,
        href: "/dictionaries",
        permission: PERMISSIONS.dictionary.read,
      },
      {
        label: "Logi aktywności",
        icon: ClipboardList,
        href: "/activity-logs",
        permission: PERMISSIONS.activityLog.read,
      },
    ],
  },
];

function UserSummary({ width }: SideNavProps): React.ReactElement {
  const [css, theme] = useStyletron();
  const [isOpen, setIsOpen] = useState<boolean | null>(null);

  const location = useLocation();
  const history = useHistory();
  const { user } = useAuth();

  return (
    <div
      className={css({
        marginTop: theme.sizing.scale200,
        backgroundColor: "white",
        height: isOpen ? `${USER_NAV.length * 42 + 57}px` : "57px",
        overflow: "hidden",
        transition: "all 200ms ease",
        flexShrink: 0,
      })}
    >
      <div
        role="button"
        className={css({
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          paddingTop: theme.sizing.scale400,
          paddingBottom: theme.sizing.scale400,
          paddingLeft: theme.sizing.scale600,
          paddingRight: theme.sizing.scale600,
          ":hover": {
            color: theme.colors.primary,
            backgroundColor: "white",
          },
        })}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div
          className={css({
            position: "relative",
            flexShrink: 0,
            marginRight: theme.sizing.scale400,
          })}
        >
          <BaseAvatar
            name={renderUserLabel(user)}
            size={width < 100 ? "26px" : "scale900"}
            overrides={{
              Root: {
                style: {
                  backgroundColor: theme.colors.contentSecondary,
                },
              },
              Initials: {
                style: {
                  fontSize: width < 100 ? "11px" : "16px",
                },
              },
            }}
          />
        </div>

        <div
          className={css({ flexShrink: 1, flexGrow: 1, overflow: "hidden" })}
        >
          <LabelMedium
            $style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {renderUserLabel(user)}
          </LabelMedium>
          <LabelSmall
            $style={{
              color: "#aaa",
              marginTop: "0px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "block",
            }}
          >
            {`${user?.email}`}
          </LabelSmall>
        </div>
        <Direction
          size={18}
          className={css({ marginLeft: theme.sizing.scale400, flexShrink: 0 })}
        />
      </div>
      <ul
        className={css({
          marginTop: "0px",
          paddingLeft: 0,
          paddingRight: 0,
        })}
      >
        {USER_NAV &&
          USER_NAV.map((item, index) => {
            const isActive =
              item.href.substring(1).length > 0 &&
              location.pathname.includes(item.href.substring(1));

            if (isActive && isOpen === null) setIsOpen(true);

            return (
              <li
                key={`user-nav` + index}
                className={css({
                  display: "flex",
                  position: "relative",
                  height: "22px",
                  alignItems: "center",
                  marginTop: theme.sizing.scale400,
                  marginBottom: theme.sizing.scale400,
                  paddingLeft: theme.sizing.scale600,
                  paddingRight: theme.sizing.scale600,
                  fontWeight: 500,
                  color: isActive
                    ? theme.colors.primary
                    : theme.colors.contentSecondary,
                  ":hover": {
                    color: isActive
                      ? theme.colors.primary
                      : theme.colors.contentPrimary,
                    cursor: "pointer",
                  },
                })}
                onClick={() => history.push(item.href)}
              >
                {width < 100 ? (
                  <Tooltip
                    content={item.label}
                    placement="left"
                    $style={{
                      left: "198px",
                      right: "unset",
                      width: "200px",
                      display: "flex",
                      justifyContent: "flex-start",
                      zIndex: 40,
                    }}
                  >
                    <div
                      className={css({
                        marginRight: "10px",
                        height: "20px",
                        marginLeft: "2px",
                        position: "relative",
                      })}
                    >
                      <item.icon size={20} />
                    </div>
                  </Tooltip>
                ) : (
                  <>
                    <div
                      className={css({ marginRight: "10px", height: "20px" })}
                    >
                      <item.icon size={22} />
                    </div>

                    {item.label}
                  </>
                )}

                {isActive && (
                  <span
                    className={css({
                      position: "absolute",
                      zIndex: 2,
                      right: "0px",
                      height: "100%",
                      width: "2px",
                      backgroundColor: theme.colors.primary,
                    })}
                  ></span>
                )}
              </li>
            );
          })}
      </ul>
    </div>
  );
}

type SideNavElementProps = {
  children?: React.ReactChild;
  isActive?: boolean;
  width: number;
  label: string;
  isSubmenu?: boolean;
  hasSubmenu?: boolean;
};

function SideNavElement({
  children,
  isActive,
  width,
  label,
  isSubmenu,
  hasSubmenu,
}: SideNavElementProps): React.ReactElement {
  const [css, theme] = useStyletron();

  return (
    <li
      className={css({
        display: "flex",
        position: "relative",
        height: "22px",
        alignItems: "center",
        marginTop: theme.sizing.scale400,
        marginBottom: theme.sizing.scale400,
        paddingLeft: theme.sizing.scale600,
        paddingRight: theme.sizing.scale600,
        fontWeight: 500,
        color: isActive ? theme.colors.primary : theme.colors.contentSecondary,
        ":hover": {
          color: isActive ? theme.colors.primary : theme.colors.contentPrimary,
          cursor: "pointer",
        },
      })}
    >
      {width < 100 && !isSubmenu ? (
        <Tooltip
          content={label}
          placement="left"
          $style={{
            left: "198px",
            right: "unset",
            width: "200px",
            display: "flex",
            justifyContent: "flex-start",
            zIndex: 40,
          }}
        >
          <div
            className={css({
              marginRight: "10px",
              height: "20px",
              marginLeft: "2px",
            })}
          >
            {hasSubmenu ? (
              <Block
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                {children}
                <ChevronRight size={14} />
              </Block>
            ) : (
              children
            )}
          </div>
        </Tooltip>
      ) : (
        <Fragment>
          <div
            className={css({
              marginRight: "10px",
              height: "20px",
            })}
          >
            {children}
          </div>

          <div
            className={css({
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              width: "100%",
              lineHeight: "16px",
            })}
          >
            {hasSubmenu ? (
              <Block
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                {label}
                <ChevronRight size={12} />
              </Block>
            ) : (
              label
            )}
          </div>
        </Fragment>
      )}

      {isActive && !isSubmenu && (
        <span
          className={css({
            position: "absolute",
            zIndex: 2,
            right: "0px",
            height: "100%",
            width: "2px",
            backgroundColor: theme.colors.primary,
          })}
        ></span>
      )}
    </li>
  );
}

type SideNavProps = {
  width: number;
};

export default function SideNav({ width }: SideNavProps): React.ReactElement {
  const [css, theme] = useStyletron();

  const history = useHistory();
  const location = useLocation();
  const { user } = useAuth();
  const permissionsList = user?.permissions?.map(
    (permission) => permission.systemName
  );

  const systemVersion = process.env.REACT_APP_SYSTEM_VERSION;

  return (
    <Container>
      <StyledLink
        onClick={(event: MouseEvent) => {
          event.preventDefault();
          history.push("/");
        }}
        href={"/"}
        $style={{ textDecoration: "none" }}
      >
        <HeadingSmall
          paddingTop="scale600"
          paddingBottom="scale600"
          paddingLeft="scale600"
          paddingRight="scale600"
          marginTop="0px"
          marginBottom="0px"
          position="sticky"
          backgroundColor="#f9f9f9"
          top="0px"
          $style={{
            fontWeight: 700,
            display: "flex",
            alignItems: "center",
            zIndex: 1,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            ...(width < 100 && { fontSize: "14px" }),
          }}
        >
          {width > 100 ? APP_NAME : "ADM"}

          {systemVersion !== ReactAppSystemVersion.PROD &&
            (width > 100 ? (
              <Tag
                closeable={false}
                {...(systemVersion === ReactAppSystemVersion.TEST && {
                  kind: "negative",
                })}
                {...(!systemVersion && {
                  kind: "warning",
                })}
                variant="solid"
                overrides={{
                  Text: { style: { fontSize: "12px", fontWeight: 600 } },
                  Root: { style: { marginLeft: "8px" } },
                }}
              >
                {systemVersion}
                {!systemVersion && "DEV"}
              </Tag>
            ) : (
              <div
                className={css({
                  position: "absolute",
                  bottom: "12px",
                  borderRadius: "5px",

                  flexShrink: 0,
                  display: "flex",
                  fontSize: "9px",
                  paddingLeft: "3px",
                  paddingRight: "3px",
                  height: "12px",
                  lineHeight: "12px",
                  color: "white",
                  fontWeight: 700,
                  ...(systemVersion === ReactAppSystemVersion.TEST && {
                    backgroundColor: theme.colors.negative,
                  }),
                  ...(!systemVersion && {
                    backgroundColor: "rgb(255, 192, 67)",
                    color: "rgb(103, 77, 27)",
                  }),
                })}
              >
                {systemVersion}
                {!systemVersion && "DEV"}
              </div>
            ))}
        </HeadingSmall>
      </StyledLink>

      <UserSummary width={width} />

      {NAV &&
        NAV.map((item, index) => (
          <div
            key={`nav-category` + index}
            className={css({ marginTop: theme.sizing.scale600 })}
          >
            {item.label &&
              item.items.some(
                (item) =>
                  (item.permission &&
                    permissionsList?.includes(item.permission)) ||
                  !item.permission
              ) && (
                <LabelSmall
                  color="contentTertiary"
                  marginBottom="scale700"
                  $style={{
                    textTransform: "uppercase",
                    letterSpacing: "0.5px",
                    paddingLeft: width > 100 ? theme.sizing.scale600 : "23px",
                    paddingRight: theme.sizing.scale600,
                    whiteSpace: "nowrap",
                  }}
                >
                  {width > 100 ? item.label : "—"}
                </LabelSmall>
              )}
            <ul
              className={css({
                marginTop: "0px",
                paddingLeft: 0,
                paddingRight: 0,
              })}
            >
              {item.items
                .filter(
                  (item) =>
                    (item.permission &&
                      permissionsList?.includes(item.permission)) ||
                    !item.permission
                )
                .map((item, subindex) => {
                  const isItemActive =
                    (item.href.substring(1).length > 0 &&
                      ((item.exact && location.pathname === item.href) ||
                        (!item.exact &&
                          location.pathname.includes(item.href)))) ||
                    item?.submenu?.some(
                      (subitem) =>
                        subitem.href.substring(1).length > 0 &&
                        ((subitem.exact &&
                          location.pathname === subitem.href) ||
                          (!subitem.exact &&
                            location.pathname.includes(subitem.href)))
                    );
                  {
                    if (item.submenu?.length)
                      return (
                        <SubmenuPopover
                          key={`nav-category` + index + `nav-item` + subindex}
                          placement={PLACEMENT.left}
                          content={({ close }) =>
                            item.submenu?.map((subitem, subindex) => {
                              const isSubitemActive =
                                subitem.href.substring(1).length > 0 &&
                                ((subitem.exact &&
                                  location.pathname === subitem.href) ||
                                  (!subitem.exact &&
                                    location.pathname.includes(subitem.href)));
                              return (
                                <StyledLink
                                  key={
                                    `nav-category` +
                                    index +
                                    `nav-item` +
                                    subindex
                                  }
                                  onClick={(event: MouseEvent) => {
                                    event.preventDefault();
                                    close();
                                  }}
                                  href={subitem.href}
                                  $style={{ textDecoration: "none" }}
                                >
                                  <SideNavElement
                                    isActive={isSubitemActive}
                                    label={subitem.label}
                                    width={width}
                                    isSubmenu
                                  >
                                    <subitem.icon size={20} />
                                  </SideNavElement>
                                </StyledLink>
                              );
                            })
                          }
                        >
                          <div>
                            <SideNavElement
                              isActive={isItemActive}
                              label={item.label}
                              width={width}
                              hasSubmenu
                            >
                              <item.icon size={20} />
                            </SideNavElement>
                          </div>
                        </SubmenuPopover>
                      );

                    return (
                      <StyledLink
                        key={`nav-category` + index + `nav-item` + subindex}
                        href={item.href}
                        $style={{ textDecoration: "none" }}
                        onClick={(event: MouseEvent) => {
                          event.preventDefault();
                          history.push(item.href);
                        }}
                      >
                        <SideNavElement
                          isActive={isItemActive}
                          label={item.label}
                          width={width}
                        >
                          <item.icon size={20} />
                        </SideNavElement>
                      </StyledLink>
                    );
                  }
                })}
            </ul>
          </div>
        ))}

      {width > 100 && (
        <LabelSmall
          marginTop="auto"
          paddingTop={theme.sizing.scale600}
          paddingLeft={theme.sizing.scale600}
          paddingRight={theme.sizing.scale600}
          color="contentInverseTertiary"
        >
          © 2023 • {process.env.REACT_APP_VERSION}
        </LabelSmall>
      )}
    </Container>
  );
}
