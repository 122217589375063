import { ApolloError, useMutation } from "@apollo/client";
import { KIND } from "baseui/button";
import { ConfirmDialog } from "components/confirm-dialog";
import { Content } from "components/content";
import { FormRenderer } from "components/form-renderer";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { DeviceFloppy, FileOff, Note } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";
import { scrollOnError } from "utils/scrollOnError";

import {
  STATIC_PAGES_FIELDS,
  StaticPagesFormInputs,
} from "../static-pages.form";
import { STATIC_PAGES_CREATE } from "../static-pages.gql";

export default function StaticPagesCreate(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [isClearConfirmDialogOpen, setIsClearConfirmDialogOpen] = useState(
    false
  );
  const [errorTimeStamp, setErrorTimeStamp] = useState<number>();
  const { isLoading, setIsLoading } = useLoading();
  const { enqueueSnackbar } = useSnackbar();
  const { checkPermission } = useAuth();
  const history = useHistory();

  const {
    control,
    formState: { errors, isDirty, ...formState },
    handleSubmit,
    reset,
    setValue,
    watch,
    ...methods
  } = useForm<StaticPagesFormInputs>({
    defaultValues: {
      name: "",
      slug: "",
      redirectUrl: "",
      systemName: [],
      isVisible: false,
      metaTitle: "",
      metaDescription: "",
      metaKeywords: "",
      ogTitle: "",
      ogDescription: "",
      robots: [],
      canonical: "",
      showLinkInFooter: false,
      showTableOfContents: false,
      showInHeader: false,
      headerPosition: null,
    },
  });

  const [createStaticPage, { error }] = useMutation(STATIC_PAGES_CREATE);

  const onError = (errors: Record<string, unknown>, e: any) =>
    setErrorTimeStamp(e.timeStamp);

  useEffect(() => {
    if (errors) scrollOnError(errors, STATIC_PAGES_FIELDS, "create");
  }, [errorTimeStamp]);

  const onSubmit = async ({
    isVisible,
    systemName,
    metaTitle,
    metaDescription,
    metaKeywords,
    ogTitle,
    ogDescription,
    canonical,
    robots,
    showLinkInFooter,
    showTableOfContents,
    ...values
  }: StaticPagesFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      const { data } = await createStaticPage({
        variables: {
          staticPageCreateInput: {
            isVisible: !!isVisible,
            systemName: systemName?.length ? systemName?.[0]?.id : null,
            showLinkInFooter: !!showLinkInFooter,
            showTableOfContents: !!showTableOfContents,
            ...values,
          },
          seoTagsetUpsertInput: {
            metaTitle,
            metaDescription,
            metaKeywords,
            ogTitle,
            ogDescription,
            canonical,
            robots: robots?.length ? robots?.[0]?.label : null,
          },
        },
      });

      enqueueSnackbar({
        message: "Utworzono pomyślnie",
        variant: "success",
      });
      history.push(`/static-pages/${data?.staticPageCreate?.id}`);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!checkPermission(PERMISSIONS.staticPage.create))
    return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title="Nowa strona informacyjna"
        labels={["Strony informacyjne", "Tworzenie"]}
        goBackOption
        buttons={[
          {
            label: "Wyczyść",
            kind: KIND.secondary,
            startEnhancer: <Note size={18} />,
            disabled: isLoading,
            onClick: () => {
              setIsClearConfirmDialogOpen(true);
            },
          },
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Utwórz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit, onError),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "createStaticPage",
          },
        ]}
      />
      <Content>
        <FormProvider<StaticPagesFormInputs>
          control={control}
          formState={{ errors, isDirty, ...formState }}
          handleSubmit={handleSubmit}
          reset={reset}
          watch={watch}
          setValue={setValue}
          {...methods}
        >
          <FormRenderer<StaticPagesFormInputs>
            id="createStaticPage"
            onSubmit={handleSubmit(onSubmit)}
            type="create"
            isLoading={isLoading}
            error={error}
            fields={STATIC_PAGES_FIELDS}
          />
        </FormProvider>
        <ConfirmDialog
          isOpen={isCancelConfirmDialogOpen}
          label="Anulowanie tworzenia nowej strony informacyjnej"
          close={() => setIsCancelConfirmDialogOpen(false)}
          confirm={() => history.goBack()}
        />
        <ConfirmDialog
          isOpen={isClearConfirmDialogOpen}
          label="Wyczyszczenie formularza"
          close={() => setIsClearConfirmDialogOpen(false)}
          confirm={() => {
            reset();
            setIsClearConfirmDialogOpen(false);
          }}
        />
      </Content>
    </article>
  );
}
