import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { BottomPanel } from "components/bottom-panel";
import { Button } from "components/button";
import { Cell } from "components/cell";
import { Content } from "components/content";
import { Filters } from "components/filters";
import { DataType, FormattedValue } from "components/formatted-value";
import { Grid } from "components/grid";
import { Header } from "components/header";
import { ImportSeoTagsModal } from "components/modal";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { PagingControls } from "components/pagination";
import { Table } from "components/table";
import SortingTableHeader, {
  SortDirection,
} from "components/table/sorting-table-header";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { usePaging } from "contexts/paging-context";
import { FiltersState } from "filters";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Row } from "react-table";
import { Eye, FileExport, FileImport } from "tabler-icons-react";
import { translateFiltersState } from "utils/filters";
import { PERMISSIONS } from "utils/permissions";

import { Typename } from "../../../constants";
import { SeoTagset } from "../seo-tagsets";
import { SEO_TAGSETS_FILTERS } from "../seo-tagsets.filters";
import { SeoTagsetsField } from "../seo-tagsets.form";
import { SEO_TAGSETS_EXPORT, SEO_TAGSETS_INDEX } from "../seo-tagsets.gql";

export default function SeoTagsetsIndex(): React.ReactElement {
  const [isImportDialogOpen, setIsImportDialogOpen] = useState(false);
  const { pageSize, currentPage, setTotalCount } = usePaging();
  const [sortBy, setSortBy] = useState<SeoTagsetsField | null>(
    SeoTagsetsField.Id
  );
  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    SortDirection.DESC
  );
  const { enqueueSnackbar } = useSnackbar();
  const [css, theme] = useStyletron();
  const {
    isFetching,
    setIsFetching,
    setIsLoading,
    isPartialFetching,
    setIsPartialFetching,
  } = useLoading();
  const history = useHistory();
  const { checkPermission } = useAuth();
  const [filters, setFilters] = useState<FiltersState>();

  const handleSorting = (column: SeoTagsetsField) => {
    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  const { refetch, data, loading, error } = useQuery(SEO_TAGSETS_INDEX, {
    variables: {
      pageSize,
      offset: (currentPage - 1) * pageSize,
      sorting: {
        field: sortBy,
        direction: sortDirection,
      },
      ...(filters &&
        filters.length && { filter: translateFiltersState(filters) }),
    },
  });
  const seoTagsets: { totalCount: number; nodes: SeoTagset[] } =
    data?.seoTagsets;

  useEffect(() => {
    if (seoTagsets) setTimeout(() => refetch(), 100);
    setIsFetching(true);
  }, []);

  useEffect(() => {
    refetch();
    setIsPartialFetching(true);
  }, [currentPage, pageSize]);

  useEffect(() => {
    refetch();
    setIsPartialFetching(true);
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (data?.seoTagsets) setIsFetching(false);
    if (seoTagsets?.totalCount >= 0) setTotalCount(seoTagsets?.totalCount);
  }, [data]);

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  const [exportSeoTagsets] = useMutation(SEO_TAGSETS_EXPORT, {
    variables: {
      ...(filters &&
        filters.length && { filter: translateFiltersState(filters) }),
      sorting: {
        field: sortBy,
        direction: sortDirection,
      },
      baseUrl: process.env.REACT_APP_WEB_URL,
    },
  });

  const onSubmit = async () => {
    setIsLoading(true);

    try {
      const response = await exportSeoTagsets();

      enqueueSnackbar({
        message: "Rozpoczęto pobieranie pliku",
        variant: "success",
      });

      window.open(response?.data?.seoTagsetsExport, "_self");
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(SeoTagsetsField.Id)}
            sortDirection={sortBy === SeoTagsetsField.Id ? sortDirection : null}
          >
            Id
          </SortingTableHeader>
        ),
        accessor: SeoTagsetsField.Id,
        Cell: ({ row }: { row: Row<SeoTagset> }) => (
          <StyledLink
            onClick={(event: MouseEvent) => {
              event.preventDefault();
              history.push(`/seo-tagsets/${row.original.id}`);
            }}
            href={`/seo-tagsets/${row.original.id}`}
            data-test-id={`show-seo-tagset-${row.index + 1}`}
          >
            {row.original?.id}
          </StyledLink>
        ),
      },
      {
        Header: "Typ obiektu",
        accessor: SeoTagsetsField.SubjectType,
        Cell: ({ row }: { row: Row<SeoTagset> }) => (
          <FormattedValue dataType={DataType.Subject}>
            {row.original.subjectType}
          </FormattedValue>
        ),
      },
      {
        Header: "Obiekt",
        id: "subject.id",
        Cell: ({ row }: { row: Row<SeoTagset> }) => {
          const handleSubject = (typename: string) => {
            switch (typename) {
              case Typename.Article:
                return (
                  <FormattedValue
                    dataType={DataType.Articles}
                    data={row.original?.subject?.id}
                    deletedAt={row.original?.subject?.deletedAt}
                  >
                    {row.original?.subject?.name}
                  </FormattedValue>
                );
              case Typename.Brand:
                return (
                  <FormattedValue
                    dataType={DataType.Brands}
                    data={row.original?.subject?.id}
                    deletedAt={row.original?.subject?.deletedAt}
                  >
                    {row.original?.subject?.name}
                  </FormattedValue>
                );
              case Typename.Category:
                return (
                  <FormattedValue
                    dataType={DataType.Categories}
                    data={row.original?.subject?.id}
                    deletedAt={row.original?.subject?.deletedAt}
                  >
                    {row.original?.subject?.name}
                  </FormattedValue>
                );
              case Typename.Recipe:
                return (
                  <FormattedValue
                    dataType={DataType.Recipes}
                    data={row.original?.subject?.id}
                    deletedAt={row.original?.subject?.deletedAt}
                  >
                    {row.original?.subject?.name}
                  </FormattedValue>
                );
              case Typename.StaticPage:
                return (
                  <FormattedValue
                    dataType={DataType.StaticPages}
                    data={row.original?.subject?.id}
                    deletedAt={row.original?.subject?.deletedAt}
                  >
                    {row.original?.subject?.name}
                  </FormattedValue>
                );
              default:
                <FormattedValue />;
            }
          };

          return row.original.subject?.__typename ? (
            <>{handleSubject(row.original.subject?.__typename)}</>
          ) : (
            <FormattedValue />
          );
        },
      },
      {
        Header: "Slug",
        accessor: SeoTagsetsField.Slug,
        Cell: ({ row }: { row: Row<SeoTagset> }) => (
          <FormattedValue dataType={DataType.Pre} $style={{ fontSize: "12px" }}>
            {row.original?.subject?.slug}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(SeoTagsetsField.MetaTitle)}
            sortDirection={
              sortBy === SeoTagsetsField.MetaTitle ? sortDirection : null
            }
          >
            Tytuł meta
          </SortingTableHeader>
        ),
        accessor: SeoTagsetsField.MetaTitle,
        Cell: ({ row }: { row: Row<SeoTagset> }) => (
          <FormattedValue>{row.original?.metaTitle}</FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(SeoTagsetsField.MetaDescription)}
            sortDirection={
              sortBy === SeoTagsetsField.MetaDescription ? sortDirection : null
            }
          >
            Opis meta
          </SortingTableHeader>
        ),
        accessor: SeoTagsetsField.MetaDescription,
        Cell: ({ row }: { row: Row<SeoTagset> }) => (
          <FormattedValue>{row.original?.metaDescription}</FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(SeoTagsetsField.OgTitle)}
            sortDirection={
              sortBy === SeoTagsetsField.OgTitle ? sortDirection : null
            }
          >
            Tytuł OG
          </SortingTableHeader>
        ),
        accessor: SeoTagsetsField.OgTitle,
        Cell: ({ row }: { row: Row<SeoTagset> }) => (
          <FormattedValue>{row.original?.ogTitle}</FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(SeoTagsetsField.OgDescription)}
            sortDirection={
              sortBy === SeoTagsetsField.OgDescription ? sortDirection : null
            }
          >
            Opis OG
          </SortingTableHeader>
        ),
        accessor: SeoTagsetsField.OgDescription,
        Cell: ({ row }: { row: Row<SeoTagset> }) => (
          <FormattedValue>{row.original?.ogDescription}</FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(SeoTagsetsField.MetaKeywords)}
            sortDirection={
              sortBy === SeoTagsetsField.MetaKeywords ? sortDirection : null
            }
          >
            Słowa kluczowe meta
          </SortingTableHeader>
        ),
        accessor: SeoTagsetsField.MetaKeywords,
        Cell: ({ row }: { row: Row<SeoTagset> }) => (
          <FormattedValue>{row.original?.metaKeywords}</FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(SeoTagsetsField.Robots)}
            sortDirection={
              sortBy === SeoTagsetsField.Robots ? sortDirection : null
            }
          >
            Robots
          </SortingTableHeader>
        ),
        accessor: SeoTagsetsField.Robots,
        Cell: ({ row }: { row: Row<SeoTagset> }) => (
          <FormattedValue>{row.original?.robots}</FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(SeoTagsetsField.Canonical)}
            sortDirection={
              sortBy === SeoTagsetsField.Canonical ? sortDirection : null
            }
          >
            Link canonical
          </SortingTableHeader>
        ),
        accessor: SeoTagsetsField.Canonical,
        Cell: ({ row }: { row: Row<SeoTagset> }) => (
          <FormattedValue>{row.original?.canonical}</FormattedValue>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<SeoTagset> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $style={{ marginLeft: "6px" }}
              onClick={() => history.push(`/seo-tagsets/${row.original.id}`)}
              startEnhancer={<Eye size={14} />}
            />
          </div>
        ),
      },
    ],
    [sortBy, sortDirection]
  );

  if (!checkPermission(PERMISSIONS.seoTagset.read))
    return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title="Tagi SEO"
        recordsNum={seoTagsets?.totalCount}
        labels={["Lista"]}
        actions={[
          {
            label: "Import z pliku XLSX",
            icon: FileImport,
            color: theme.colors.primary,
            permission: checkPermission(PERMISSIONS.seoTagset.read),
            onClick: () => setIsImportDialogOpen(!isImportDialogOpen),
          },
          {
            label: "Eksport do pliku XLSX",
            icon: FileExport,
            color: theme.colors.primary,
            permission: checkPermission(PERMISSIONS.seoTagset.read),
            onClick: onSubmit,
          },
        ]}
      />
      <Filters
        filters={SEO_TAGSETS_FILTERS}
        state={filters}
        setState={setFilters}
      />
      <Content filtersOffset>
        <Grid>
          <Cell span={12}>
            <Table<SeoTagset>
              columns={columns}
              data={seoTagsets?.nodes}
              isLoading={isFetching || isPartialFetching || loading}
              stickLastColumn
            />
          </Cell>
          <Cell span={12}>
            <BottomPanel>
              <PagingControls />
            </BottomPanel>
          </Cell>
        </Grid>

        <ImportSeoTagsModal
          isOpen={isImportDialogOpen}
          close={() => setIsImportDialogOpen(!isImportDialogOpen)}
          refetch={refetch}
        />
      </Content>
    </article>
  );
}
