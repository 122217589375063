import { BasicFilter, FilterType } from "./../../filters.d";
import { TagsField } from "./tags.form";

export const TAGS_FILTERS: BasicFilter[] = [
  {
    id: TagsField.Name,
    label: "Nazwa",
    type: FilterType.Text,
  },
  {
    id: TagsField.CreatedAt,
    label: "Data utworzenia",
    type: FilterType.Date,
  },
  {
    id: TagsField.IsVisible,
    label: "Widoczność",
    type: FilterType.Boolean,
  },
];
