import { BasicFilter, FilterType } from "./../../filters.d";
import { AllergensField } from "./allergens.form";

export const ALLERGENS_FILTERS: BasicFilter[] = [
  {
    id: AllergensField.Name,
    label: "Nazwa",
    type: FilterType.Text,
  },
  {
    id: AllergensField.CreatedAt,
    label: "Utworzenie",
    type: FilterType.Date,
  },
  {
    id: AllergensField.CreatedBy,
    label: "Utworzono przez",
    type: FilterType.CreatedBy,
  },
  {
    id: AllergensField.UpdatedAt,
    label: "Ostatnia aktualizacja",
    type: FilterType.Date,
  },
  {
    id: AllergensField.UpdatedBy,
    label: "Zaktualizowano przez",
    type: FilterType.UpdatedBy,
  },
  {
    id: AllergensField.IsVisible,
    label: "Widoczność",
    type: FilterType.Boolean,
  },
];
