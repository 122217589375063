import { DataType } from "components/formatted-value";

import { FieldsGroup, FieldType } from "../../fields.d";

export type TagsFormInputs = {
  name: string;
  slug: string;
  isVisible?: boolean;
};

export enum TagsField {
  Name = "name",
  Slug = "slug",
  IsVisible = "isVisible",
  CreatedAt = "createdAt",
  UpdatedAt = "updatedAt",
}

export const TAGS_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: TagsField.Name,
        label: "Nazwa",
        type: FieldType.BigInput,
        span: 12,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
      {
        id: TagsField.Slug,
        label: "Slug",
        span: 12,
        type: FieldType.SlugInput,
        dataType: DataType.Pre,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
        caption:
          "Slug jest generowany automatycznie na podstawie nazwy. Możesz go zmienić, edytując powyższe pole.",
      },
      {
        id: TagsField.IsVisible,
        label: "Widoczność",
        span: 6,
        type: FieldType.Switch,
        dataType: DataType.VisibilityBoolean,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
    ],
  },
  {
    id: "system",
    label: "Dane systemowe",
    fields: [
      {
        id: TagsField.CreatedAt,
        label: "Utworzenie",
        span: 4,
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: TagsField.UpdatedAt,
        label: "Ostatnia aktualizacja",
        span: 4,
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
    ],
  },
];
