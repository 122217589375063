const defaultColumns: { [pathname: string]: string[] } = {
  "/users": ["firstName", "lastName", "email", "status", "roles", "actions"],
  "/categories": [
    "name",
    "color",
    "slug",
    "createdAt",
    "updatedAt",
    "description",
    "kind",
    "isVisible",
    "isFeatured",
    "showInShortcuts",
    "isAvailableInFilters",
    "actions",
  ],
  pathname: ["id"],
};

export function getDefaultColumns(pathname: string): string[] | undefined {
  return defaultColumns?.[pathname];
}
