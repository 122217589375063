import { gql } from "@apollo/client";

export const BRANDS_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $filter: BrandFilter
    $sorting: [BrandSort!]
  ) {
    brands(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        name
        slug
        websiteUrl
        createdAt
        createdBy {
          id
          firstName
          lastName
          username
          email
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
          username
          email
        }
        isVisible
        position
      }
    }
  }
`;

export const BRANDS_SHOW = gql`
  query BrandShow($id: Int!) {
    brand(id: $id) {
      id
      name
      slug
      websiteUrl
      createdAt
      createdBy {
        id
        firstName
        lastName
        username
        email
      }
      updatedAt
      updatedBy {
        id
        firstName
        lastName
        username
        email
      }
      isVisible
      lead
      content
      categories {
        id
        name
      }
      tags {
        id
        name
      }
      position
      imageUrl
      socialMediaUrls
      backgroundImageUrl
      logoImageUrl
      websiteImageUrl
      seoTagset {
        id
        metaTitle
        metaDescription
        metaKeywords
        ogTitle
        ogDescription
        canonical
        robots
      }
    }
  }
`;

export const BRANDS_CREATE = gql`
  mutation BrandCreate(
    $brandCreateInput: BrandCreateInput!
    $seoTagsetUpsertInput: SeoTagsetUpsertInput
  ) {
    brandCreate(
      brandCreateInput: $brandCreateInput
      seoTagsetUpsertInput: $seoTagsetUpsertInput
    ) {
      id
    }
  }
`;

export const BRANDS_UPDATE = gql`
  mutation BrandUpdate(
    $brandUpdateInput: BrandUpdateInput!
    $seoTagsetUpsertInput: SeoTagsetUpsertInput
  ) {
    brandUpdate(
      brandUpdateInput: $brandUpdateInput
      seoTagsetUpsertInput: $seoTagsetUpsertInput
    ) {
      id
    }
  }
`;

export const BRANDS_DELETE = gql`
  mutation BrandDelete($brandDeleteInput: BrandDeleteInput!) {
    brandDelete(brandDeleteInput: $brandDeleteInput) {
      id
    }
  }
`;
