/**
 * App Constants
 */

export const AUTH_TOKEN = "auth-token";
export const NOT_FOUND_ERROR = "NOT_FOUND_ERROR";
export const INPUT_VALIDATION_ERROR = "INPUT_VALIDATION_ERROR";
export const APP_NAME = "Uwielbiam.pl Admin";

export const SUPPORT_ADMIN_NAME = "Jan Kowalski";
export const SUPPORT_MAIL = "admin@uwielbiam.pl";

export const PAGE_SIZE = 25;

export enum ReactAppSystemVersion {
  TEST = "TEST",
  PROD = "PROD",
}

export enum Typename {
  Allergen = "Allergen",
  Article = "Article",
  Avatar = "Avatar",
  Brand = "Brand",
  Category = "Category",
  Dictionary = "Dictionary",
  DictionaryValue = "DictionaryValue",
  Ingredient = "Ingredient",
  Product = "Product",
  Recipe = "Recipe",
  Role = "Role",
  SeoTagset = "SeoTagset",
  Slide = "Slide",
  StaticPage = "StaticPage",
  Tag = "Tag",
  User = "User",
}

export const ACTIVITY_LOG_ACTIVITIES = [
  { id: "updated", label: "Aktualizacja" },
  { id: "exportedUsers", label: "Eksport użytkowników" },
  { id: "login", label: "Logowanie" },
  { id: "failedLogin", label: "Nieudane logowanie" },
  { id: "unblocked", label: "Odblokowanie" },
  { id: "revealed", label: "Odczytanie" },
  { id: "forgotPassword", label: "Prośba o reset hasła" },
  { id: "moved", label: "Przeniesienie" },
  { id: "resetPassword", label: "Reset hasła" },
  { id: "hidden", label: "Ukrycie" },
  { id: "setInitialPassword", label: "Ustawienie hasła" },
  { id: "deleted", label: "Usunięcie" },
  { id: "created", label: "Utworzenie" },
  { id: "logout", label: "Wylogowanie" },
  { id: "blocked", label: "Zablokowanie" },
  { id: "changedPassword", label: "Zmiana hasła" },
];

export const ACTIVITY_LOGS_SUBJECTS = [
  { id: "Allergen", label: "Alergen" },
  { id: "Article", label: "Artykuł" },
  { id: "Avatar", label: "Awatar" },
  { id: "Category", label: "Kategoria" },
  { id: "Brand", label: "Marka" },
  { id: "Product", label: "Produkt" },
  { id: "Recipe", label: "Przepis" },
  { id: "Role", label: "Rola" },
  { id: "Dictionary", label: "Słownik" },
  { id: "Slide", label: "Slajd" },
  { id: "StaticPage", label: "Strona informacyjna" },
  { id: "Tag", label: "Tag" },
  { id: "SeoTagset", label: "Tag SEO" },
  { id: "User", label: "Użytkownik" },
  { id: "DictionaryValue", label: "Wartość słownika" },
  { id: "Image", label: "Grafika" },
  { id: "Ingredient", label: "Składnik" },
];

export const SEO_TAGSETS_SUBJECTS = [
  { id: "Article", label: "Artykuł" },
  { id: "Brand", label: "Marka" },
  { id: "Category", label: "Kategoria" },
  { id: "Recipe", label: "Przepis" },
  { id: "StaticPage", label: "Strona informacyjna" },
  { id: "Category", label: "Kategoria" },
];

export enum TestEmails {
  AllPermissions = "test@allpermissions.com",
  NoPermissions = "test@nopermissions.com",
  PermissionsToShow = "test@permissionstoshow.com",
}

export const ALL_PERMISSIONS_USER = "Arek Testowy";

export const TEST_PASSWORD = "password";

export enum Difficulty {
  Easy = "Easy",
  Medium = "Medium",
  Hard = "Hard",
}

export const DIFFICULTY_LEVELS = [
  { id: Difficulty.Easy, label: "Łatwy" },
  { id: Difficulty.Medium, label: "Średnio-zaaw." },
  { id: Difficulty.Hard, label: "Wymagający" },
];

export enum DictionarySystemName {
  RobotsSeoTagsets = "robotsSeoTagsets",
  SocialMedia = "socialMedia",
}

export enum StaticPageSystemName {
  Articles = "Articles",
  Brands = "Brands",
  Planner = "Planner",
  PrivacyPolicy = "PrivacyPolicy",
  Recipes = "Recipes",
  TermsOfService = "TermsOfService",
}

export const STATIC_PAGE_SYSTEM_NAMES = [
  { id: StaticPageSystemName.Articles, label: "Artykuły" },
  { id: StaticPageSystemName.Brands, label: "Marki" },
  { id: StaticPageSystemName.Planner, label: "Planer" },
  { id: StaticPageSystemName.PrivacyPolicy, label: "Polityka prywatności" },
  { id: StaticPageSystemName.Recipes, label: "Przepisy" },
  { id: StaticPageSystemName.TermsOfService, label: "Regulamin" },
];

export const BANNED_STATIC_PAGES_SLUGS = [
  "artykul",
  "artykuly",
  "kontakt",
  "logowanie",
  "marki",
  "moje-konto",
  "nie-pamietam-hasla",
  "planer",
  "przepis",
  "przepisy",
  "rejestracja",
  "zmien-email",
];

export enum Unit {
  Glass = "szklanka",
  Pinch = "szczypta",
  Decagram = "dekagram",
  Kilogram = "kilogram",
  Liter = "litr",
  Teaspoon = "łyżeczka",
  Unit = "sztuka",
  Tablespoon = "łyżka",
  Gram = "gram",
  Cube = "kostka",
  Can = "puszka",
  Bunch = "pęczek",
  Package = "opakowanie",
  Slice = "plaster",
  Twig = "łodyga",
  Mililiter = "mililitr",
}

export const UNITS = [
  { id: Unit.Mililiter, label: "ml" },
  { id: Unit.Liter, label: "l" },
  { id: Unit.Gram, label: "g" },
  { id: Unit.Decagram, label: "dag." },
  { id: Unit.Kilogram, label: "kg" },
  { id: Unit.Glass, label: "szkl." },
  { id: Unit.Teaspoon, label: "łyżecz." },
  { id: Unit.Tablespoon, label: "łyżka" },
  { id: Unit.Pinch, label: "szczyp." },
  { id: Unit.Unit, label: "szt." },
  { id: Unit.Cube, label: "kostka" },
  { id: Unit.Can, label: "puszka" },
  { id: Unit.Bunch, label: "pęcz." },
  { id: Unit.Package, label: "opak." },
  { id: Unit.Slice, label: "plaster" },
  { id: Unit.Twig, label: "łodyga" },
];
