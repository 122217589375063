import { useLazyQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { User } from "containers/Users/users";
import { USERS_LIVE_SEARCH } from "containers/Users/users.gql";
import { useAuth } from "contexts/auth-context";
import React, { FormEvent, useEffect } from "react";
import { Controller, UseControllerProps } from "react-hook-form";
import { Ban } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";
import { renderUserLabel } from "utils/render-user-label";

import { LiveSearchSelect, SelectProps } from "./select";

export default function UsersSelect({
  disabled,
  ...rest
}: SelectProps): React.ReactElement {
  const { checkPermission } = useAuth();
  const [css] = useStyletron();
  const [fetchData, { data, loading }] = useLazyQuery(USERS_LIVE_SEARCH);

  useEffect(() => fetchData(), []);

  const hasPermission = checkPermission(PERMISSIONS.user.read);

  return (
    <LiveSearchSelect
      options={data?.users?.nodes.map((user: User) => ({
        label: renderUserLabel(user),
        ...user,
      }))}
      getValueLabel={({ option }) => (option?.label ? `${option?.label}` : "")}
      getOptionLabel={({ option }) => `${option?.label}`}
      isLoading={loading}
      onInputChange={(event: FormEvent) => {
        fetchData({
          variables: {
            filter: {
              or: [
                {
                  firstName: {
                    like: `%${(event.currentTarget as any).value}%`,
                  },
                },
                {
                  lastName: {
                    like: `%${(event.currentTarget as any).value}%`,
                  },
                },
                {
                  username: {
                    like: `%${(event.currentTarget as any).value}%`,
                  },
                },
                {
                  email: {
                    like: `%${(event.currentTarget as any).value}%`,
                  },
                },
              ],
            },
          },
        });
      }}
      placeholder={
        hasPermission ? (
          "Wybierz"
        ) : (
          <div
            className={css({
              display: "flex",
              alignItems: "center",
              gap: " 5px",
            })}
          >
            <Ban size={16} />
            Brak uprawnień do przeglądania użytkowników
          </div>
        )
      }
      disabled={disabled || !hasPermission}
      onClose={fetchData}
      {...rest}
    />
  );
}

export function ControlledUsersSelect({
  control,
  disabled,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & SelectProps): React.ReactElement {
  const { checkPermission } = useAuth();
  const [css] = useStyletron();
  const [fetchData, { data, loading }] = useLazyQuery(USERS_LIVE_SEARCH);

  useEffect(() => fetchData(), []);

  const hasPermission = checkPermission(PERMISSIONS.user.read);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <LiveSearchSelect
          id={name}
          options={data?.users?.nodes.map((user: User) => ({
            label: renderUserLabel(user),
            ...user,
          }))}
          getValueLabel={({ option }) => `${option?.label}`}
          getOptionLabel={({ option }) => `${option?.label}`}
          isLoading={loading}
          onInputChange={(event: FormEvent) => {
            fetchData({
              variables: {
                filter: {
                  or: [
                    {
                      firstName: {
                        like: `%${(event.currentTarget as any).value}%`,
                      },
                    },
                    {
                      lastName: {
                        like: `%${(event.currentTarget as any).value}%`,
                      },
                    },
                    {
                      username: {
                        like: `%${(event.currentTarget as any).value}%`,
                      },
                    },
                    {
                      email: {
                        like: `%${(event.currentTarget as any).value}%`,
                      },
                    },
                  ],
                },
              },
            });
          }}
          placeholder={
            hasPermission ? (
              "Wybierz"
            ) : (
              <div
                className={css({
                  display: "flex",
                  alignItems: "center",
                  gap: " 5px",
                })}
              >
                <Ban size={16} />
                Brak uprawnień do przeglądania użytkowników
              </div>
            )
          }
          disabled={disabled || !hasPermission}
          onClose={fetchData}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          {...(data && { value: value })}
          {...rest}
        />
      )}
    />
  );
}
