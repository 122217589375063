import { BasicFilter, FilterType } from "./../../filters.d";
import { RecipesField } from "./recipes.form";

export const RECIPES_FILTERS: BasicFilter[] = [
  {
    id: RecipesField.Name,
    label: "Nazwa",
    type: FilterType.Text,
  },
  {
    id: RecipesField.Slug,
    label: "Slug",
    type: FilterType.Text,
  },
  {
    id: RecipesField.CreatedAt,
    label: "Utworzenie",
    type: FilterType.Date,
  },
  {
    id: RecipesField.CreatedBy,
    label: "Utworzono przez",
    type: FilterType.CreatedBy,
  },
  {
    id: RecipesField.UpdatedAt,
    label: "Ostatnia aktualizacja",
    type: FilterType.Date,
  },
  {
    id: RecipesField.UpdatedBy,
    label: "Zaktualizowano przez",
    type: FilterType.UpdatedBy,
  },
  {
    id: RecipesField.IsVisible,
    label: "Widoczność",
    type: FilterType.Boolean,
  },
];
