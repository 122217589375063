import { gql } from "@apollo/client";

export const SLIDES_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $filter: SlideFilter
    $sorting: [SlideSort!]
  ) {
    slides(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        title
        url
        article {
          id
          name
        }
        recipe {
          id
          name
        }
        isVisible
        position
        createdAt
        updatedAt
        deletedAt
        createdBy {
          id
          firstName
          lastName
        }
        updatedBy {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const SLIDES_SHOW = gql`
  query SlideShow($id: Int!) {
    slide(id: $id) {
      id
      title
      lead
      imageUrl
      url
      buttonLabel
      article {
        id
        name
      }
      recipe {
        id
        name
      }
      isVisible
      position
      createdAt
      updatedAt
      deletedAt
      createdBy {
        id
        firstName
        lastName
      }
      updatedBy {
        id
        firstName
        lastName
      }
    }
  }
`;

export const SLIDES_CREATE = gql`
  mutation SlideCreate($slideCreateInput: SlideCreateInput!) {
    slideCreate(slideCreateInput: $slideCreateInput) {
      id
    }
  }
`;

export const SLIDES_UPDATE = gql`
  mutation SlideUpdate($slideUpdateInput: SlideUpdateInput!) {
    slideUpdate(slideUpdateInput: $slideUpdateInput) {
      id
    }
  }
`;

export const SLIDES_DELETE = gql`
  mutation SlideDelete($slideDeleteInput: SlideDeleteInput!) {
    slideDelete(slideDeleteInput: $slideDeleteInput) {
      id
    }
  }
`;
