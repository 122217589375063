import { gql } from "@apollo/client";

export const AVATARS_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $filter: AvatarFilter
    $sorting: [AvatarSort!]
  ) {
    avatars(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        name
        imageUrl
        createdAt
        createdBy {
          id
          firstName
          lastName
          username
          email
        }
      }
    }
  }
`;

export const AVATARS_SHOW = gql`
  query AvatarShow($id: Int!) {
    avatar(id: $id) {
      id
      name
      imageUrl
      createdAt
      createdBy {
        id
        firstName
        lastName
        username
        email
      }
    }
  }
`;

export const AVATARS_CREATE = gql`
  mutation AvatarCreate($avatarCreateInput: AvatarCreateInput!) {
    avatarCreate(avatarCreateInput: $avatarCreateInput) {
      id
    }
  }
`;

export const AVATARS_DELETE = gql`
  mutation AvatarDelete($avatarDeleteInput: AvatarDeleteInput!) {
    avatarDelete(avatarDeleteInput: $avatarDeleteInput) {
      id
    }
  }
`;
