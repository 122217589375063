export const PERMISSIONS = {
  activityLog: {
    read: "activityLog.read",
  },
  allergen: {
    read: "allergen.read",
    update: "allergen.update",
    create: "allergen.create",
    delete: "allergen.delete",
  },
  article: {
    read: "article.read",
    update: "article.update",
    create: "article.create",
    delete: "article.delete",
  },
  avatar: {
    read: "avatar.read",
    create: "avatar.create",
    delete: "avatar.delete",
  },
  brand: {
    read: "brand.read",
    update: "brand.update",
    create: "brand.create",
    delete: "brand.delete",
  },
  category: {
    read: "category.read",
    update: "category.update",
    create: "category.create",
    delete: "category.delete",
  },
  dictionary: {
    read: "dictionary.read",
    valueUpdate: "dictionaryValue.update",
    valueCreate: "dictionaryValue.create",
    valueDelete: "dictionaryValue.delete",
  },
  image: {
    read: "image.read",
    update: "image.update",
    upload: "image.upload",
    delete: "image.delete",
  },
  ingredient: {
    read: "ingredient.read",
    update: "ingredient.update",
  },
  product: {
    read: "product.read",
    update: "product.update",
    create: "product.create",
    delete: "product.delete",
  },
  recipe: {
    read: "recipe.read",
    update: "recipe.update",
    create: "recipe.create",
    delete: "recipe.delete",
  },
  role: {
    read: "role.read",
    update: "role.update",
    create: "role.create",
    delete: "role.delete",
  },
  slide: {
    read: "slide.read",
    update: "slide.update",
    create: "slide.create",
    delete: "slide.delete",
  },
  staticPage: {
    read: "staticPage.read",
    update: "staticPage.update",
    create: "staticPage.create",
    delete: "staticPage.delete",
  },
  tag: {
    read: "tag.read",
    update: "tag.update",
    create: "tag.create",
    delete: "tag.delete",
  },
  seoTagset: {
    read: "seoTagset.read",
    update: "seoTagset.update",
  },
  user: {
    block: "user.block",
    create: "user.create",
    delete: "user.delete",
    read: "user.read",
    unblock: "user.unblock",
    update: "user.update",
  },
};
