import { useDrag } from "@use-gesture/react";
import { useStyletron } from "baseui";
import { LoadingBar } from "components/loading-bar";
import React, { useEffect, useState } from "react";

import SideNav from "../side-nav/side-nav";

type LayoutProps = {
  children?: React.ReactNode;
};

export default function Layout({ children }: LayoutProps): React.ReactElement {
  const [css, theme] = useStyletron();

  const customSidebarWidth =
    localStorage.getItem("sidebarWidth") &&
    parseInt(localStorage.getItem("sidebarWidth") as string);

  const [sidebarWidth, setSidebarWidth] = useState<number>(
    customSidebarWidth || 225
  );

  const [initialSidebarWidth, setInitialSidebarWidth] = useState<number>(
    customSidebarWidth || 225
  );

  useEffect(() => localStorage.setItem("sidebarWidth", String(sidebarWidth)), [
    sidebarWidth,
  ]);

  const bind = useDrag(({ movement: [mx] }) => {
    if (mx === 0) setInitialSidebarWidth(sidebarWidth);
    else setSidebarWidth(initialSidebarWidth + mx);
  });

  return (
    <main className={css({ display: "flex", height: "100vh" })}>
      <aside
        className={css({
          ...(sidebarWidth > 100
            ? {
                maxWidth: "300px",
                minWidth: "200px",
                width: sidebarWidth ? `${sidebarWidth}px` : "30%",
              }
            : {
                width: "60px",
                ...(initialSidebarWidth > 100 && {
                  animationDuration: "200ms",
                  animationName: {
                    from: {
                      width: "100px",
                    },
                    to: {
                      width: "60px",
                    },
                  } as any,
                }),
              }),
          flexShrink: 0,
          position: "relative",
        })}
      >
        <SideNav width={sidebarWidth} />

        <div
          {...bind()}
          className={css({
            position: "absolute",
            right: "-3px",
            top: "0px",
            bottom: "0px",
            width: "8px",
            zIndex: 20,
            cursor: "col-resize",
            ":hover [data-ui=fill]": {
              transform: "scaleX(1)",
            },
          })}
        >
          <div
            data-ui="fill"
            className={css({
              width: "100%",
              height: "100%",
              backgroundColor: "#ddd",
              transform: "scaleX(0)",
              transition: "200ms ease",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            })}
          >
            <span
              className={css({
                width: "2px",
                height: "20px",
                borderRadius: "1px",
                backgroundColor: "#eee",
              })}
            />
          </div>
        </div>
      </aside>

      <section
        className={css({
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
          position: "relative",
          paddingBottom: theme.sizing.scale1600,
        })}
      >
        <LoadingBar />
        {children}
      </section>
    </main>
  );
}
