import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { BottomPanel } from "components/bottom-panel";
import { Button } from "components/button";
import { Cell } from "components/cell";
import { Content } from "components/content";
import { Filters } from "components/filters";
import FormattedValue, {
  DataType,
} from "components/formatted-value/formatted-value";
import { Grid } from "components/grid";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { PagingControls } from "components/pagination";
import { Table } from "components/table";
import SortingTableHeader, {
  SortDirection,
} from "components/table/sorting-table-header";
import { ListTooltip, Tooltip } from "components/tooltip";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { usePaging } from "contexts/paging-context";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Cell as TableCell, Row } from "react-table";
import { Eye, FileExport, Plus, UserOff } from "tabler-icons-react";
import { translateFiltersState } from "utils/filters";
import { PERMISSIONS } from "utils/permissions";

import { FiltersState } from "../../../filters";
import { User } from "../users";
import { USERS_FILTERS } from "../users.filters";
import { UsersField } from "../users.form";
import { USERS_EXPORT, USERS_INDEX } from "../users.gql";

export default function UsersIndex(): React.ReactElement {
  const { pageSize, currentPage, setTotalCount } = usePaging();
  const [sortBy, setSortBy] = useState<UsersField | null>(UsersField.LastName);
  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    SortDirection.ASC
  );
  const { enqueueSnackbar } = useSnackbar();
  const [css, theme] = useStyletron();
  const {
    isPartialFetching,
    setIsPartialFetching,
    isFetching,
    setIsFetching,
    setIsLoading,
  } = useLoading();
  const history = useHistory();
  const { checkPermission } = useAuth();

  const [filters, setFilters] = useState<FiltersState>();

  const handleSorting = (column: UsersField) => {
    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  const { refetch, data, error, loading } = useQuery(USERS_INDEX, {
    variables: {
      pageSize,
      offset: (currentPage - 1) * pageSize,
      ...(filters &&
        filters.length && { filter: translateFiltersState(filters) }),
      sorting: {
        field: sortBy,
        direction: sortDirection,
      },
    },
  });

  const users: { totalCount: number; nodes: User[] } = data?.users;

  useEffect(() => {
    refetch();
    setIsPartialFetching(true);
  }, [currentPage, pageSize]);

  useEffect(() => {
    if (data?.users) setTimeout(() => refetch(), 400);
    setIsFetching(true);
  }, []);

  useEffect(() => {
    refetch();
    setIsPartialFetching(true);
  }, [sortBy, sortDirection, data?.users]);

  useEffect(() => {
    if (data?.users) setIsFetching(false);
    if (users?.totalCount >= 0) setTotalCount(users?.totalCount);
  }, [data]);

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  const [exportUsers] = useMutation(USERS_EXPORT, {
    variables: {
      ...(filters &&
        filters.length && { filter: translateFiltersState(filters) }),
      sorting: {
        field: sortBy,
        direction: sortDirection,
      },
    },
  });

  const onSubmit = async () => {
    setIsLoading(true);

    try {
      const response = await exportUsers();

      enqueueSnackbar({
        message: "Rozpoczęto pobieranie pliku",
        variant: "success",
      });

      window.open(response?.data?.usersExport, "_self");
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(UsersField.Username)}
            sortDirection={
              sortBy === UsersField.Username ? sortDirection : null
            }
          >
            Nick
          </SortingTableHeader>
        ),
        accessor: UsersField.Username,
        Cell: ({ row }: { row: Row<User> }) => (
          <StyledLink
            onClick={(event: MouseEvent) => {
              event.preventDefault();
              history.push(`/users/${row.original.id}`);
            }}
            href={`/users/${row.original.id}`}
          >
            <FormattedValue>{row.original.username}</FormattedValue>
            {!!row.original.blockedAt && (
              <Tooltip content="Użytkownik zablokowany">
                <span>
                  <UserOff
                    size={14}
                    className={css({
                      verticalAlign: "middle",
                      marginLeft: "6px",
                      display: "inline",
                      color: theme.colors.negative,
                    })}
                  />
                </span>
              </Tooltip>
            )}
          </StyledLink>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(UsersField.Email)}
            sortDirection={sortBy === UsersField.Email ? sortDirection : null}
          >
            Adres e-mail
          </SortingTableHeader>
        ),
        accessor: UsersField.Email,
        Cell: ({ row }: { row: Row<User> }) => (
          <>
            <FormattedValue dataType={DataType.Email}>
              {row.original.email}
            </FormattedValue>
            {!!row.original.blockedAt && (
              <Tooltip content="Użytkownik zablokowany">
                <span>
                  <UserOff
                    size={14}
                    className={css({
                      verticalAlign: "middle",
                      marginLeft: "6px",
                      display: "inline",
                      color: theme.colors.negative,
                    })}
                  />
                </span>
              </Tooltip>
            )}
          </>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(UsersField.FirstName)}
            sortDirection={
              sortBy === UsersField.FirstName ? sortDirection : null
            }
          >
            Imię
          </SortingTableHeader>
        ),
        id: UsersField.FirstName,
        Cell: ({ row }: { row: Row<User> }) => (
          <>
            <FormattedValue>{row.original.firstName}</FormattedValue>
            {!!row.original.blockedAt && (
              <Tooltip content="Użytkownik zablokowany">
                <span>
                  <UserOff
                    size={14}
                    className={css({
                      verticalAlign: "middle",
                      marginLeft: "6px",
                      display: "inline",
                      color: theme.colors.negative,
                    })}
                  />
                </span>
              </Tooltip>
            )}
          </>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(UsersField.LastName)}
            sortDirection={
              sortBy === UsersField.LastName ? sortDirection : null
            }
          >
            Nazwisko
          </SortingTableHeader>
        ),
        id: UsersField.LastName,
        Cell: ({ row }: { row: Row<User> }) => (
          <>
            <FormattedValue>{row.original.lastName}</FormattedValue>
            {!!row.original.blockedAt && (
              <Tooltip content="Użytkownik zablokowany">
                <span>
                  <UserOff
                    size={14}
                    className={css({
                      verticalAlign: "middle",
                      marginLeft: "6px",
                      display: "inline",
                      color: theme.colors.negative,
                    })}
                  />
                </span>
              </Tooltip>
            )}
          </>
        ),
      },
      {
        Header: "Status",
        id: UsersField.Status,
        Cell: ({ row }: { row: Row<User> }) => {
          const handleUserStatus = () => {
            if (
              row?.original?.createdAt &&
              row?.original?.invitedAt &&
              !row?.original?.activatedAt &&
              !row?.original?.blockedAt &&
              !row?.original.deletedAt
            )
              return "Wysłano zaproszenie";
            else if (
              row?.original?.createdAt &&
              row?.original?.activatedAt &&
              !row?.original?.blockedAt &&
              !row?.original.deletedAt
            )
              return "Aktywny";
            else if (row?.original?.blockedAt && !row?.original?.deletedAt)
              return "Zablokowany";
          };
          return (
            <FormattedValue dataType={DataType.UserStatus}>
              {handleUserStatus()}
            </FormattedValue>
          );
        },
      },
      {
        Header: "Role",
        accessor: UsersField.Roles,
        Cell: ({ row }: { row: Row<User> }) => {
          const isMoreThanOneRole =
            !!row.original.roles?.length && row.original.roles?.length > 1;

          return (
            <div
              {...(isMoreThanOneRole && {
                className: css({ display: "flex", gap: "12px" }),
              })}
            >
              {!row.original.roles?.length ? (
                <FormattedValue />
              ) : (
                <FormattedValue
                  dataType={DataType.Roles}
                  data={row.original?.roles?.[0]?.id}
                >
                  {row.original?.roles?.[0]?.name}
                </FormattedValue>
              )}

              {isMoreThanOneRole && (
                <ListTooltip
                  items={row.original?.roles}
                  dataType={DataType.Roles}
                />
              )}
            </div>
          );
        },
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(UsersField.CreatedAt)}
            sortDirection={
              sortBy === UsersField.CreatedAt ? sortDirection : null
            }
          >
            Utworzenie
          </SortingTableHeader>
        ),
        accessor: UsersField.CreatedAt,
        Cell: ({ cell }: { cell: TableCell }) => (
          <FormattedValue dataType={DataType.DateTime}>
            {cell.value}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(UsersField.InvitedAt)}
            sortDirection={
              sortBy === UsersField.InvitedAt ? sortDirection : null
            }
          >
            Wysłanie zaproszenia
          </SortingTableHeader>
        ),
        accessor: UsersField.InvitedAt,
        Cell: ({ cell }: { cell: TableCell }) => (
          <FormattedValue dataType={DataType.DateTime}>
            {cell.value}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(UsersField.ActivatedAt)}
            sortDirection={
              sortBy === UsersField.ActivatedAt ? sortDirection : null
            }
          >
            Aktywacje konta
          </SortingTableHeader>
        ),
        accessor: UsersField.ActivatedAt,
        Cell: ({ cell }: { cell: TableCell }) => (
          <FormattedValue dataType={DataType.DateTime}>
            {cell.value}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(UsersField.BlockedAt)}
            sortDirection={
              sortBy === UsersField.BlockedAt ? sortDirection : null
            }
          >
            Zablokowanie
          </SortingTableHeader>
        ),
        accessor: UsersField.BlockedAt,
        Cell: ({ cell }: { cell: TableCell }) => (
          <FormattedValue dataType={DataType.DateTime}>
            {cell.value}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(UsersField.LastLoginAt)}
            sortDirection={
              sortBy === UsersField.LastLoginAt ? sortDirection : null
            }
          >
            Ostatnie logowanie
          </SortingTableHeader>
        ),
        accessor: UsersField.LastLoginAt,
        Cell: ({ cell }: { cell: TableCell }) => (
          <FormattedValue dataType={DataType.DateTime}>
            {cell.value}
          </FormattedValue>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<User> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $style={{ marginLeft: "6px" }}
              onClick={() => history.push(`/users/${row?.original?.id}`)}
              startEnhancer={<Eye size={14} />}
            />
          </div>
        ),
      },
    ],
    [users, sortBy, sortDirection]
  );

  if (!checkPermission(PERMISSIONS.user.read)) return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title="Użytkownicy"
        recordsNum={users?.totalCount}
        labels={["Lista"]}
        buttons={[
          {
            label: "Dodaj użytkownika",
            kind: KIND.primary,
            startEnhancer: <Plus size={18} />,
            permission: checkPermission(PERMISSIONS.user.create),
            onClick: () => history.push("/users/create"),
          },
        ]}
        actions={[
          {
            label: "Eksport do pliku XLSX",
            icon: FileExport,
            color: theme.colors.primary,
            permission: checkPermission(PERMISSIONS.user.read),
            onClick: onSubmit,
          },
        ]}
      />

      <Filters filters={USERS_FILTERS} state={filters} setState={setFilters} />

      <Content filtersOffset>
        <Grid>
          <Cell span={12} $style={{ position: "relative" }}>
            <Table<User>
              columns={columns}
              data={users?.nodes}
              isLoading={isFetching || isPartialFetching || loading}
              stickLastColumn
            />
          </Cell>

          <Cell span={12}>
            <BottomPanel>
              <PagingControls />
            </BottomPanel>
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
