import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { KIND } from "baseui/button";
import { ConfirmDialog } from "components/confirm-dialog";
import { Content } from "components/content";
import { FormRenderer } from "components/form-renderer";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { DeviceFloppy, FileOff } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";

import { Category, CategoryKind } from "../categories.d";
import { CATEGORIES_FIELDS, CategoriesFormInputs } from "../categories.form";
import { CATEGORIES_SHOW, CATEGORIES_UPDATE } from "../categories.gql";

export default function CategoriesUpdate(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const { enqueueSnackbar } = useSnackbar();
  const {
    setIsFetching,
    isLoading,
    setIsLoading,
    setIsPartialFetching,
  } = useLoading();
  const { checkPermission } = useAuth();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();

  const {
    control,
    formState: { errors, isDirty, dirtyFields, ...formState },
    handleSubmit,
    setValue,
    reset,
    watch,
    ...methods
  } = useForm<CategoriesFormInputs>();

  const { refetch, data, error: queryError } = useQuery(CATEGORIES_SHOW, {
    variables: { id: id ? parseInt(id) : null },
  });
  const category: Category = data?.category;
  const isBestRecipesCategory =
    data?.category?.kind === CategoryKind.BestRecipes;

  useEffect(() => {
    setIsFetching(true);
    if (data?.category) refetch();
  }, []);

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  useEffect(() => {
    async function setFile() {
      const response = await fetch(category?.imageUrl);
      const data = await response.blob();

      const extension = /\.(jpe?g|png|gif|svg)$/gi.exec(
        category?.imageUrl
      )?.[1];

      const metadata = {
        type: `image/${extension === "svg" ? "svg+xml" : "png"}`,
      };

      const file = new File([data], `${category?.slug}.${extension}`, metadata);

      setValue("image", file);
      setIsPartialFetching(false);
      setIsFetching(false);
    }

    if (category) {
      setValue("name", category?.name);
      !!category?.description?.length &&
        setValue("description", category?.description);
      setValue("isVisible", category?.isVisible);
      setValue("isFeatured", category?.isFeatured);
      setValue("position", category?.position);
      setValue("slug", category?.slug);
      setValue("showInShortcuts", category?.showInShortcuts);
      setValue("shortcutPosition", category?.shortcutPosition);
      setValue("isAvailableInFilters", category?.isAvailableInFilters);
      setValue("parent", category?.parent ? [category?.parent] : []);
      setValue("color", category?.color);
      setValue("kind", category?.kind ? [{ id: category?.kind }] : []);
      setValue("metaTitle", category?.seoTagset?.metaTitle);
      setValue("metaDescription", category?.seoTagset?.metaDescription);
      setValue("ogTitle", category?.seoTagset?.ogTitle);
      setValue("ogDescription", category?.seoTagset?.ogDescription);
      setValue("metaKeywords", category?.seoTagset?.metaKeywords);
      setValue("canonical", category?.seoTagset?.canonical);
      !!category?.seoTagset?.robots &&
        setValue("robots", [
          {
            id: category?.seoTagset?.robots,
            label: category?.seoTagset?.robots,
          },
        ]);

      if (category?.imageUrl) {
        setTimeout(() => setFile(), 500);
      } else {
        setTimeout(() => setIsFetching(false), 250);
      }
    }
  }, [data]);

  const [updateCategory, { error }] = useMutation(CATEGORIES_UPDATE);

  const onSubmit = async ({
    isVisible,
    isFeatured,
    showInShortcuts,
    isAvailableInFilters,
    parent,
    kind,
    metaTitle,
    metaDescription,
    metaKeywords,
    ogTitle,
    ogDescription,
    canonical,
    robots,
    image,
    ...values
  }: CategoriesFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await updateCategory({
        variables: {
          categoryUpdateInput: {
            id: id ? parseInt(id) : null,
            isVisible: !!isVisible,
            isFeatured: !!isFeatured,
            showInShortcuts: !!showInShortcuts,
            isAvailableInFilters: !!isAvailableInFilters,
            kind: kind?.length ? kind?.[0]?.id : null,
            ...(dirtyFields?.image && { image: image || null }),
            ...(parent?.length
              ? {
                  parentId: parent?.[0]?.id,
                }
              : {
                  parentId: null,
                }),
            ...values,
          },
          seoTagsetUpsertInput: {
            metaTitle,
            metaDescription,
            metaKeywords,
            ogTitle,
            ogDescription,
            canonical,
            robots: robots?.length ? robots?.[0]?.label : null,
          },
        },
      });

      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });
      history.push(`/categories/${id}`);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!checkPermission(PERMISSIONS.category.update))
    return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title={category?.name}
        labels={["Kategorie", "Edytowanie"]}
        goBackOption
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "updateCategory",
          },
        ]}
      />
      <Content>
        <FormProvider<CategoriesFormInputs>
          control={control}
          formState={{ errors, isDirty, dirtyFields, ...formState }}
          handleSubmit={handleSubmit}
          reset={reset}
          setValue={setValue}
          watch={watch}
          {...methods}
        >
          <FormRenderer<CategoriesFormInputs>
            id="updateCategory"
            onSubmit={handleSubmit(onSubmit)}
            type="update"
            isLoading={isLoading}
            error={error}
            fields={CATEGORIES_FIELDS}
            shouldHideFields={isBestRecipesCategory}
          />
        </FormProvider>
        <ConfirmDialog
          isOpen={isCancelConfirmDialogOpen}
          label="Anulowanie edycji kategorii"
          close={() => setIsCancelConfirmDialogOpen(false)}
          confirm={() => history.goBack()}
        />
      </Content>
    </article>
  );
}
