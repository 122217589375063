import {
  API,
  BlockTool,
  BlockToolConstructorOptions,
  BlockToolData,
  ToolboxConfig,
} from "@editorjs/editorjs";
import { Recipe } from "containers/Recipes/recipes";
import { useEditor } from "contexts/editor-context";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

type RecipeBlockEditorProps = {
  initialData: RecipeBlockData;
  onChange(data: RecipeBlockData): void;
  isReadOnly: boolean;
  config?: RecipeBlockConfig;
  api: API;
};

function RecipeBlockEditor({
  initialData,
  onChange,
  isReadOnly,
  config,
  api,
}: RecipeBlockEditorProps) {
  const [data, setData] = useState<RecipeBlockData>(initialData);

  useEffect(() => {
    if (!isReadOnly) {
      onChange(data);
    }
  }, [data]);

  const handleRecipeSelect = () =>
    config?.editorContext.openRecipesDialog((recipe) => {
      setData((data) => ({
        ...data,
        recipeId: recipe.id,
        recipe: {
          slug: recipe.slug,
          id: recipe.id,
          name: recipe.name,
          imageUrl: recipe.imageUrl,
        },
      }));
    });

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "white",
        padding: "12px",
        borderRadius: "8px",
      }}
    >
      {data.recipe ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "12px",
          }}
        >
          <img
            src={data.recipe.imageUrl}
            alt={data.recipe.name}
            style={{
              width: "80px",
              height: "50px",
              objectFit: "cover",
            }}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "8px",
            }}
          >
            <strong>{data.recipe.name}</strong>

            {!isReadOnly && (
              <button
                type="button"
                className={api.styles.button}
                style={{
                  padding: "6px 10px",
                  fontSize: "12px",
                }}
                onClick={handleRecipeSelect}
              >
                Zmień przepis
              </button>
            )}
          </div>
        </div>
      ) : !isReadOnly ? (
        <button
          type="button"
          className={api.styles.button}
          style={{
            margin: "auto",
            display: "block",
          }}
          onClick={handleRecipeSelect}
        >
          Wybierz przepis
        </button>
      ) : (
        "Brak przepisu"
      )}
    </div>
  );
}

interface RecipeBlockData extends BlockToolData {
  recipeId?: string;
  recipe?: Pick<Recipe, "id" | "slug" | "name" | "imageUrl">;
}

interface RecipeBlockConfig {
  editorContext: ReturnType<typeof useEditor>;
}

export default class RecipeBlock implements BlockTool {
  public static isReadOnlySupported = true;

  private data: RecipeBlockData;
  private isReadOnly: boolean;
  private api: API;
  private config?: RecipeBlockConfig;

  static get toolbox(): ToolboxConfig {
    return {
      title: "Przepis",
      icon: `
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-cookie-man" width="14" height="14" viewBox="0 0 24 24" stroke="black" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><path d="M12 2a5 5 0 0 1 2.845 9.112l.147 .369l1.755 -.803c.969 -.443 2.12 -.032 2.571 .918a1.88 1.88 0 0 1 -.787 2.447l-.148 .076l-2.383 1.089v2.02l1.426 1.425l.114 .125a1.96 1.96 0 0 1 -2.762 2.762l-.125 -.114l-2.079 -2.08l-.114 -.124a1.957 1.957 0 0 1 -.161 -.22h-.599c-.047 .075 -.101 .15 -.16 .22l-.115 .125l-2.08 2.079a1.96 1.96 0 0 1 -2.886 -2.648l.114 -.125l1.427 -1.426v-2.019l-2.383 -1.09l-.148 -.075a1.88 1.88 0 0 1 -.787 -2.447c.429 -.902 1.489 -1.318 2.424 -.978l.147 .06l1.755 .803l.147 -.369a4.995 4.995 0 0 1 -2.15 -3.895l-.005 -.217a5 5 0 0 1 5 -5z"></path><path d="M12 16h.01"></path><path d="M12 13h.01"></path><path d="M10 7h.01"></path><path d="M14 7h.01"></path><path d="M12 9h.01"></path></svg>
      `,
    };
  }

  constructor({
    data,
    readOnly,
    api,
    config,
  }: BlockToolConstructorOptions<RecipeBlockData>) {
    this.data = {
      ...data,
    };
    this.isReadOnly = readOnly;
    this.api = api;
    this.config = config;
  }

  render(): HTMLElement {
    const root = document.createElement("div");
    root.classList.add(this.api.styles.block);

    ReactDOM.render(
      <RecipeBlockEditor
        initialData={this.data}
        isReadOnly={this.isReadOnly}
        config={this.config}
        api={this.api}
        onChange={(data) => {
          this.data = data;
          this.update();
        }}
      />,
      root
    );

    return root;
  }

  private update(): void {
    const block = this.api.blocks.insert(
      undefined,
      undefined,
      undefined,
      Infinity
    );

    this.api.blocks.delete(this.api.blocks.getBlockIndex(block.id));
  }

  validate(data: RecipeBlockData): boolean {
    return !!data.recipeId;
  }

  save(): RecipeBlockData {
    return this.data;
  }
}
