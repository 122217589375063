import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { KIND } from "baseui/button";
import { ConfirmDialog } from "components/confirm-dialog";
import { Content } from "components/content";
import { FormRenderer } from "components/form-renderer";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { Category } from "containers/Categories/categories";
import { Tag } from "containers/Tags/tags";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { DeviceFloppy, FileOff } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";
import { scrollOnError } from "utils/scrollOnError";

import { Brand } from "../brands";
import { BRANDS_FIELDS, BrandsFormInputs } from "../brands.form";
import { BRANDS_SHOW, BRANDS_UPDATE } from "../brands.gql";

export default function BrandsUpdate(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const { enqueueSnackbar } = useSnackbar();
  const {
    setIsPartialFetching,
    setIsFetching,
    isLoading,
    setIsLoading,
  } = useLoading();
  const { checkPermission } = useAuth();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();

  const {
    control,
    formState: { errors, isDirty, dirtyFields, ...formState },
    handleSubmit,
    setValue,
    reset,
    watch,
    ...methods
  } = useForm<BrandsFormInputs>();

  const { refetch, data, error: queryError } = useQuery(BRANDS_SHOW, {
    variables: { id: id ? parseInt(id) : null },
  });
  const brand: Brand = data?.brand;

  useEffect(() => {
    setIsFetching(true);
    if (data?.brand) refetch();
  }, []);

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  useEffect(() => {
    async function setFile(fieldName: string, url: string) {
      const response = await fetch(url);
      const data = await response.blob();
      const extension = /\.(jpe?g|png|gif|svg)$/gi.exec(url)?.[1];
      const metadata = {
        type: `image/${extension === "svg" ? "svg+xml" : "png"}`,
      };

      const file = new File([data], `${brand?.slug}.${extension}`, metadata);

      if (url) setValue(fieldName as keyof BrandsFormInputs, file);
    }

    if (brand) {
      setValue("name", brand?.name);
      setValue("websiteUrl", brand?.websiteUrl);
      setValue("lead", brand?.lead);
      setValue("isVisible", brand?.isVisible);
      setValue("slug", brand?.slug);
      setValue("content", brand?.content);
      setValue("categories", brand?.categories);
      setValue("tags", brand?.tags);
      setValue("metaTitle", brand?.seoTagset?.metaTitle);
      setValue("metaDescription", brand?.seoTagset?.metaDescription);
      setValue("ogTitle", brand?.seoTagset?.ogTitle);
      setValue("ogDescription", brand?.seoTagset?.ogDescription);
      setValue("metaKeywords", brand?.seoTagset?.metaKeywords);
      setValue("canonical", brand?.seoTagset?.canonical);
      !!brand?.seoTagset?.robots &&
        setValue("robots", [
          {
            id: brand?.seoTagset?.robots,
            label: brand?.seoTagset?.robots,
          },
        ]);
      setValue("socialMediaUrls", brand?.socialMediaUrls);
      setValue("position", brand?.position);

      setTimeout(() => setIsFetching(false), 250);

      setTimeout(() => {
        setIsPartialFetching(true);

        Promise.all([
          setFile("logoImage", brand?.logoImageUrl),
          setFile("image", brand?.imageUrl),
          setFile("websiteImage", brand?.websiteImageUrl),
          setFile("backgroundImage", brand?.backgroundImageUrl),
        ]).then(() => setIsPartialFetching(false));
      }, 500);
    }
  }, [data]);

  const [errorTimeStamp, setErrorTimeStamp] = useState<number>();

  const onError = (errors: Record<string, unknown>, e: any) =>
    setErrorTimeStamp(e.timeStamp);

  useEffect(() => {
    if (errors) scrollOnError(errors, BRANDS_FIELDS, "update");
  }, [errorTimeStamp]);

  const [updateBrand, { error }] = useMutation(BRANDS_UPDATE);

  const onSubmit = async ({
    categories,
    isVisible,
    tags,
    metaTitle,
    metaDescription,
    metaKeywords,
    ogTitle,
    ogDescription,
    canonical,
    robots,
    socialMediaUrls,
    position,
    logoImage,
    image,
    websiteImage,
    backgroundImage,
    websiteUrl,
    ...values
  }: BrandsFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await updateBrand({
        variables: {
          brandUpdateInput: {
            id: id ? parseInt(id) : null,
            isVisible: !!isVisible,
            categoryIds: categories?.map((category: Category) => category?.id),
            tagIds: tags?.map((tag: Tag) => tag?.id),
            socialMediaUrls: JSON.stringify(socialMediaUrls),
            position: parseInt(position as string),
            ...(dirtyFields?.websiteUrl && { websiteUrl: websiteUrl || null }),
            ...(dirtyFields?.logoImage && { logoImage: logoImage || null }),
            ...(dirtyFields?.image && { image: image || null }),
            ...(dirtyFields?.websiteImage && {
              websiteImage: websiteImage || null,
            }),
            ...(dirtyFields?.backgroundImage && {
              backgroundImage: backgroundImage || null,
            }),
            ...values,
          },
          seoTagsetUpsertInput: {
            metaTitle,
            metaDescription,
            metaKeywords,
            ogTitle,
            ogDescription,
            canonical,
            robots: robots?.length ? robots?.[0]?.label : null,
          },
        },
      });
      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });
      history.push(`/brands/${id}`);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!checkPermission(PERMISSIONS.brand.update))
    return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title={brand?.name}
        labels={["Marki", "Edytowanie"]}
        goBackOption
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit, onError),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "updateBrand",
          },
        ]}
      />
      <Content>
        <FormProvider<BrandsFormInputs>
          control={control}
          formState={{ errors, isDirty, dirtyFields, ...formState }}
          handleSubmit={handleSubmit}
          reset={reset}
          setValue={setValue}
          watch={watch}
          {...methods}
        >
          <FormRenderer<BrandsFormInputs>
            id="updateBrand"
            onSubmit={handleSubmit(onSubmit, onError)}
            type="update"
            isLoading={isLoading}
            error={error}
            fields={BRANDS_FIELDS}
          />
        </FormProvider>
        <ConfirmDialog
          isOpen={isCancelConfirmDialogOpen}
          label="Anulowanie edycji marki"
          close={() => setIsCancelConfirmDialogOpen(false)}
          confirm={() => history.goBack()}
        />
      </Content>
    </article>
  );
}
