import { ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import { Modal } from "components/modal";
import RecipesSelect from "components/select/recipes-select";
import { Recipe } from "containers/Recipes/recipes";
import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";

import { Button } from "../components/button";

type EditorOpenCallback = (recipe: Recipe) => void;

type EditorContextProps = {
  openRecipesDialog: (callback: EditorOpenCallback) => void;
};

export const EditorContext = createContext<EditorContextProps>(
  {} as EditorContextProps
);

export function EditorProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const [isRecipesDialogOpen, setIsRecipesDialogOpen] = useState(false);
  const [selectedRecipe, setSelectedRecipe] = useState<Recipe | null>(null);

  const openCallback = useRef<EditorOpenCallback | null>(null);

  const openRecipesDialog = useCallback((callback: EditorOpenCallback) => {
    openCallback.current = callback;
    setIsRecipesDialogOpen(true);
  }, []);

  const selectRecipe = useCallback(() => {
    selectedRecipe &&
      openCallback.current &&
      openCallback.current(selectedRecipe);

    closeRecipesDialog();
  }, [selectedRecipe, openCallback.current]);

  const closeRecipesDialog = useCallback(() => {
    openCallback.current = null;
    setIsRecipesDialogOpen(false);
    setTimeout(() => setSelectedRecipe(null), 500);
  }, []);

  return (
    <EditorContext.Provider
      value={{
        openRecipesDialog,
      }}
    >
      {children}

      <Modal onClose={closeRecipesDialog} isOpen={isRecipesDialogOpen}>
        <ModalHeader>Wybierz przepis</ModalHeader>
        <ModalBody>
          <RecipesSelect
            value={selectedRecipe ? [selectedRecipe] : []}
            onChange={({ value }) => {
              value && setSelectedRecipe(value[0] as Recipe);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            kind="tertiary"
            onClick={closeRecipesDialog}
            $style={{ marginRight: "8px" }}
          >
            Anuluj
          </Button>
          <Button onClick={selectRecipe} disabled={!selectedRecipe}>
            Wybierz
          </Button>
        </ModalFooter>
      </Modal>
    </EditorContext.Provider>
  );
}

export const useEditor = (): EditorContextProps => useContext(EditorContext);
