import { BasicFilter, FilterType } from "./../../filters.d";
import { ArticlesField } from "./articles.form";

export const ARTICLES_FILTERS: BasicFilter[] = [
  {
    id: ArticlesField.Name,
    label: "Nazwa",
    type: FilterType.Text,
  },
  {
    id: ArticlesField.Slug,
    label: "Slug",
    type: FilterType.Text,
  },
  {
    id: ArticlesField.CreatedAt,
    label: "Utworzenie",
    type: FilterType.Date,
  },
  {
    id: ArticlesField.CreatedBy,
    label: "Utworzono przez",
    type: FilterType.CreatedBy,
  },
  {
    id: ArticlesField.UpdatedAt,
    label: "Ostatnia aktualizacja",
    type: FilterType.Date,
  },
  {
    id: ArticlesField.UpdatedBy,
    label: "Zaktualizowano przez",
    type: FilterType.UpdatedBy,
  },
  {
    id: ArticlesField.IsVisible,
    label: "Widoczność",
    type: FilterType.Boolean,
  },
];
