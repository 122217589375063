import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND } from "baseui/button";
import { ConfirmDialog } from "components/confirm-dialog";
import { Content } from "components/content";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { ObjectViewRenderer } from "components/object-view-renderer";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Lock, LockOpen, Pencil, Trash } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";

import { Role } from "../roles";
import { ROLES_FIELDS } from "../roles.form";
import { ROLES_DELETE, ROLES_SHOW } from "../roles.gql";

export default function RolesShow(): React.ReactElement {
  const [, theme] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const { checkPermission } = useAuth();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { setIsLoading, setIsFetching } = useLoading();

  const { refetch, data, error } = useQuery(ROLES_SHOW, {
    variables: { id: id ? parseInt(id) : null },
    notifyOnNetworkStatusChange: true,
  });
  const role: Role = data?.role;

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (data?.role) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => data?.role && setIsFetching(false), [data]);

  const [deleteRole] = useMutation(ROLES_DELETE);

  const submit = async () => {
    setIsLoading(true);

    try {
      await deleteRole({
        variables: {
          roleDeleteInput: { id: id ? parseInt(id) : null },
        },
      });

      enqueueSnackbar({
        message: "Usunięto pomyślnie",
        variant: "success",
      });

      setIsConfirmDialogOpen(false);

      history.push("/roles");
    } catch (error: unknown) {
      setIsConfirmDialogOpen(false);

      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!checkPermission(PERMISSIONS.role.read)) return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title={
          <Block display="flex" alignItems="center">
            <Block marginRight="5px">{role?.name}</Block>
            {role?.isSystem ? (
              <Lock color={theme.colors.negative} size={18} />
            ) : (
              <LockOpen color={theme.colors.positive} size={18} />
            )}
          </Block>
        }
        labels={["Role", "Wyświetlanie"]}
        goBackOption
        buttons={[
          {
            label: "Edytuj",
            kind: KIND.secondary,
            startEnhancer: <Pencil size={18} />,
            permission:
              checkPermission(PERMISSIONS.role.update) && !role?.isSystem,
            onClick: () => history.push(`/roles/${id}/update`),
          },
        ]}
        actions={[
          {
            label: "Usuń rolę",
            icon: Trash,
            color: theme.colors.negative,
            onClick: () => setIsConfirmDialogOpen(true),
            permission:
              checkPermission(PERMISSIONS.role.delete) && !role?.isSystem,
          },
        ]}
      />
      <Content>
        {data && <ObjectViewRenderer<Role> fields={ROLES_FIELDS} data={role} />}
        <ConfirmDialog
          isOpen={isConfirmDialogOpen}
          label={`Usunięcie roli ${role?.name}`}
          close={() => setIsConfirmDialogOpen(false)}
          confirm={submit}
        />
      </Content>
    </article>
  );
}
