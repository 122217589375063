import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { BottomPanel } from "components/bottom-panel";
import { Button } from "components/button";
import { Cell } from "components/cell";
import { Content } from "components/content";
import { Filters } from "components/filters";
import { DataType, FormattedValue } from "components/formatted-value";
import { Grid } from "components/grid";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { PagingControls } from "components/pagination";
import { Table } from "components/table";
import SortingTableHeader, {
  SortDirection,
} from "components/table/sorting-table-header";
import { Tooltip } from "components/tooltip";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { usePaging } from "contexts/paging-context";
import { FiltersState } from "filters";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Row } from "react-table";
import { Eye, Plus } from "tabler-icons-react";
import { translateFiltersState } from "utils/filters";
import { PERMISSIONS } from "utils/permissions";
import { renderUserLabel } from "utils/render-user-label";

import { Allergen } from "../allergens";
import { ALLERGENS_FILTERS } from "../allergens.filters";
import { AllergensField } from "../allergens.form";
import { ALLERGENS_INDEX, ALLERGENS_UPDATE } from "../allergens.gql";

export default function AllergensIndex(): React.ReactElement {
  const { pageSize, currentPage, setTotalCount } = usePaging();
  const [sortBy, setSortBy] = useState<AllergensField | null>(
    AllergensField.Name
  );
  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    SortDirection.ASC
  );
  const { enqueueSnackbar } = useSnackbar();
  const [css] = useStyletron();
  const {
    isFetching,
    setIsFetching,
    setIsLoading,
    isPartialFetching,
    setIsPartialFetching,
  } = useLoading();
  const history = useHistory();
  const { checkPermission } = useAuth();
  const [filters, setFilters] = useState<FiltersState>();

  const handleSorting = (column: AllergensField) => {
    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  const { refetch, data, loading, error } = useQuery(ALLERGENS_INDEX, {
    variables: {
      pageSize,
      offset: (currentPage - 1) * pageSize,
      sorting: {
        field: sortBy,
        direction: sortDirection,
      },
      ...(filters &&
        filters.length && { filter: translateFiltersState(filters) }),
    },
  });
  const allergens: { totalCount: number; nodes: Allergen[] } = data?.allergens;

  useEffect(() => {
    if (data?.allergens) setTimeout(() => refetch(), 100);
    setIsFetching(true);
  }, []);

  useEffect(() => {
    refetch();
    setIsPartialFetching(true);
  }, [currentPage, pageSize]);

  useEffect(() => {
    refetch();
    setIsPartialFetching(true);
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (data?.allergens) setIsFetching(false);
    if (allergens?.totalCount >= 0) setTotalCount(allergens?.totalCount);
  }, [data]);

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  const [updateAllergen] = useMutation(ALLERGENS_UPDATE);

  const onSubmit = async (allergen: Allergen): Promise<void> => {
    setIsLoading(true);

    try {
      await updateAllergen({
        variables: {
          allergenUpdateInput: {
            id: allergen.id,
            isVisible: !allergen.isVisible,
          },
        },
      });
      enqueueSnackbar({
        message: `Alergen "${allergen.name}" jest ${
          allergen.isVisible ? "niewidoczny" : "widoczny"
        }`,
        variant: "success",
      });
      refetch();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(AllergensField.Name)}
            sortDirection={
              sortBy === AllergensField.Name ? sortDirection : null
            }
          >
            Nazwa
          </SortingTableHeader>
        ),
        accessor: AllergensField.Name,
        Cell: ({ row }: { row: Row<Allergen> }) => (
          <StyledLink
            onClick={(event: MouseEvent) => {
              event.preventDefault();
              history.push(`/allergens/${row.original.id}`);
            }}
            href={`/allergens/${row.original.id}`}
          >
            {row.original.name}
          </StyledLink>
        ),
      },
      {
        Header: (
          <Block display="flex" justifyContent="flex-end">
            <SortingTableHeader
              onClick={() => handleSorting(AllergensField.CreatedAt)}
              sortDirection={
                sortBy === AllergensField.CreatedAt ? sortDirection : null
              }
            >
              Utworzenie
            </SortingTableHeader>
          </Block>
        ),
        accessor: AllergensField.CreatedAt,
        Cell: ({ row }: { row: Row<Allergen> }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue dataType={DataType.Date}>
              {row.original.createdAt}
            </FormattedValue>
          </Block>
        ),
      },
      {
        Header: "Utworzono przez",
        accessor: AllergensField.CreatedBy,
        Cell: ({ row }: { row: Row<Allergen> }) => (
          <FormattedValue
            dataType={DataType.Users}
            data={row.original?.createdBy?.id}
          >
            {row.original?.createdBy
              ? renderUserLabel(row.original.createdBy)
              : undefined}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <Block display="flex" justifyContent="flex-end">
            <SortingTableHeader
              onClick={() => handleSorting(AllergensField.UpdatedAt)}
              sortDirection={
                sortBy === AllergensField.UpdatedAt ? sortDirection : null
              }
            >
              Ostatnia aktualizacja
            </SortingTableHeader>
          </Block>
        ),
        accessor: AllergensField.UpdatedAt,
        Cell: ({ row }: { row: Row<Allergen> }) => (
          <Block display="flex" justifyContent="flex-end">
            <FormattedValue dataType={DataType.Date}>
              {row.original.updatedAt}
            </FormattedValue>
          </Block>
        ),
      },
      {
        Header: "Zaktualizowano przez",
        accessor: AllergensField.UpdatedBy,
        Cell: ({ row }: { row: Row<Allergen> }) => (
          <FormattedValue
            dataType={DataType.Users}
            data={row.original?.updatedBy?.id}
          >
            {row.original?.updatedBy
              ? renderUserLabel(row.original.updatedBy)
              : undefined}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(AllergensField.IsVisible)}
            sortDirection={
              sortBy === AllergensField.IsVisible ? sortDirection : null
            }
          >
            Widoczność
          </SortingTableHeader>
        ),
        accessor: AllergensField.IsVisible,
        Cell: ({ row }: { row: Row<Allergen> }) => (
          <Tooltip
            content={`Ustaw jako ${
              row.original.isVisible ? "niewidoczny" : "widoczny"
            }`}
            placement="right"
          >
            <span
              onClick={() => onSubmit(row.original)}
              className={css({
                cursor: "pointer",
              })}
            >
              <FormattedValue dataType={DataType.VisibilityBoolean}>
                {row.original.isVisible}
              </FormattedValue>
            </span>
          </Tooltip>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: Row<Allergen> }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $style={{ marginLeft: "6px" }}
              onClick={() => history.push(`/allergens/${row.original.id}`)}
              startEnhancer={<Eye size={14} />}
            />
          </div>
        ),
      },
    ],
    [sortBy, sortDirection]
  );

  if (!checkPermission(PERMISSIONS.allergen.read))
    return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title="Alergeny"
        recordsNum={allergens?.totalCount}
        labels={["Lista"]}
        buttons={[
          {
            label: "Dodaj alergen",
            kind: KIND.primary,
            startEnhancer: <Plus size={18} />,
            permission: checkPermission(PERMISSIONS.allergen.create),
            onClick: () => history.push("/allergens/create"),
          },
        ]}
      />
      <Filters
        filters={ALLERGENS_FILTERS}
        state={filters}
        setState={setFilters}
      />
      <Content filtersOffset>
        <Grid>
          <Cell span={12}>
            <Table<Allergen>
              columns={columns}
              data={allergens?.nodes}
              isLoading={isFetching || isPartialFetching || loading}
              stickLastColumn
            />
          </Cell>
          <Cell span={12}>
            <BottomPanel>
              <PagingControls />
            </BottomPanel>
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
