import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { Button } from "components/button";
import { Input } from "components/input";
import { Tooltip } from "components/tooltip";
import { useSnackbar } from "notistack";
import { ReactElement, useEffect, useState } from "react";
import React from "react";
import { useLocation } from "react-router-dom";
import { Copy, ExternalLink } from "tabler-icons-react";

type LinkRendererProps = {
  slug?: string;
};

export default function LinkRenderer({
  slug,
}: LinkRendererProps): ReactElement {
  const [css, theme] = useStyletron();
  const location = useLocation();

  const [inputValue, setInputValue] = useState(slug);
  const { enqueueSnackbar } = useSnackbar();

  const computePath = () => {
    if (location.pathname.startsWith("/articles")) {
      return "/artykul/";
    } else if (location.pathname.startsWith("/recipes")) {
      return "/przepis/";
    } else if (location.pathname.startsWith("/brands")) {
      return "/marki/";
    } else if (location.pathname.startsWith("/static-pages")) {
      return "/";
    }
    return "";
  };

  useEffect(() => {
    const path = computePath();
    setInputValue(
      `${process.env.REACT_APP_WEB_URL}${
        path.startsWith("/") ? "" : "/"
      }${path}${slug}`
    );
  }, [slug, location.pathname]);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(inputValue as string);

    enqueueSnackbar({
      variant: "success",
      message: "Skopiowano do schowka",
    });
  };

  return (
    <div
      className={css({
        display: "flex",
        gap: "12px",
        justifyContent: "space-between",
        alignItems: "center",
      })}
    >
      <Input
        id="link"
        $style={{
          flexGrow: 1,
        }}
        value={inputValue}
        size="mini"
        name="link"
        endEnhancer={
          <Tooltip content="Kopiuj" placement="auto">
            <span
              onClick={handleCopyToClipboard}
              className={css({
                cursor: "pointer",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: theme.colors.borderFocus,
                transition: ".1s",
                ":hover": {
                  color: theme.colors.inputPlaceholder,
                },
              })}
            >
              <Copy size={18} />
            </span>
          </Tooltip>
        }
      />
      <Button
        kind={KIND.secondary}
        $as="a"
        size="mini"
        startEnhancer={<ExternalLink size={18} />}
        target="_blank"
        href={inputValue}
        $style={{
          display: "flex",
          alignItems: "center",
          whiteSpace: "nowrap",
        }}
      >
        Otwórz w nowej karcie
      </Button>
    </div>
  );
}
