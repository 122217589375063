import { OutputData } from "@editorjs/editorjs";
import { DataType } from "components/formatted-value";
import { SelectValue } from "components/select/select";
import { Category } from "containers/Categories/categories";
import { SeoTagsetsField } from "containers/SeoTagsets/seo-tagsets.form";
import { Tag } from "containers/Tags/tags";

import { DictionarySystemName } from "../../constants";
import { FieldsGroup, FieldType } from "../../fields.d";

export type BrandsFormInputs = {
  name: string;
  slug: string;
  lead: string;
  websiteUrl: string;
  isVisible: boolean;
  categories: Category[];
  tags: Tag[];
  image: File;
  backgroundImage: File;
  logoImage: File;
  websiteImage: File;
  metaTitle: string;
  metaDescription: string;
  metaKeywords: string;
  ogTitle: string;
  ogDescription: string;
  canonical: string;
  robots: SelectValue;
  content: OutputData[];
  socialMediaUrls: JSON;
  position: number | string;
};

export enum BrandsField {
  Name = "name",
  Slug = "slug",
  WebsiteUrl = "websiteUrl",
  Lead = "lead",
  CreatedAt = "createdAt",
  UpdatedAt = "updatedAt",
  CreatedBy = "createdBy",
  UpdatedBy = "updatedBy",
  IsVisible = "isVisible",
  Categories = "categories",
  Tags = "tags",
  SeoTagset = "seoTagset",
  Content = "content",
  Image = "image",
  ImageUrl = "imageUrl",
  SocialMediaUrls = "socialMediaUrls",
  BackgroundImage = "backgroundImage",
  BackgroundImageUrl = "backgroundImageUrl",
  LogoImage = "logoImage",
  LogoImageUrl = "logoImageUrl",
  WebsiteImage = "websiteImage",
  WebsiteImageUrl = "websiteImageUrl",
  Position = "position",
}

export const BRANDS_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: BrandsField.Name,
        label: "Nazwa",
        type: FieldType.BigInput,
        span: 12,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
      {
        id: BrandsField.Slug,
        label: "Slug",
        type: FieldType.SlugInput,
        dataType: DataType.Pre,
        span: 12,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
        caption:
          "Slug jest generowany automatycznie na podstawie nazwy. Możesz go zmienić, edytując powyższe pole.",
      },
      {
        id: BrandsField.Slug,
        label: "Link publiczny",
        type: FieldType.Link,
        span: 12,
        show: { visible: true },
        caption:
          "Link do udostępnienia. Uwaga: Jeśli widoczność jest ustawiona na „nie”, pozycja nie jest dostępna publicznie",
        create: { visible: false, required: false },
        update: { visible: false, required: false },
      },
      {
        id: BrandsField.WebsiteUrl,
        label: "Adres strony WWW",
        type: FieldType.Input,
        dataType: DataType.Link,
        span: 12,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: BrandsField.IsVisible,
        label: "Widoczność",
        type: FieldType.Switch,
        dataType: DataType.VisibilityBoolean,
        readonly: true,
        span: 6,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: BrandsField.Position,
        label: "Pozycja",
        type: FieldType.NumberInput,
        span: 6,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
    ],
  },
  {
    id: "Images",
    label: "Media",
    fields: [
      {
        id: BrandsField.LogoImageUrl,
        label: "Logo",
        type: FieldType.Image,
        span: 6,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: BrandsField.LogoImage,
        label: "Logo",
        type: FieldType.ImageCropper,
        span: 6,
        imageRequirements: { allowVectors: true },
        show: { visible: false },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: BrandsField.ImageUrl,
        label: "Produkt",
        type: FieldType.Image,
        span: 6,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: BrandsField.Image,
        label: "Produkt",
        type: FieldType.ImageCropper,
        span: 6,
        imageRequirements: { allowVectors: true },
        show: { visible: false },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: BrandsField.WebsiteImageUrl,
        label: "Strona WWW",
        type: FieldType.Image,
        span: 6,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: BrandsField.WebsiteImage,
        label: "Strona WWW",
        type: FieldType.ImageCropper,
        span: 6,
        show: { visible: false },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: BrandsField.BackgroundImageUrl,
        label: "Tło",
        type: FieldType.Image,
        span: 6,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: BrandsField.BackgroundImage,
        label: "Tło",
        type: FieldType.ImageCropper,
        span: 6,
        show: { visible: false },
        create: { visible: true },
        update: { visible: true },
      },
    ],
  },
  {
    id: "content",
    label: "Treści",
    fields: [
      {
        id: BrandsField.Lead,
        label: "Lead",
        span: 12,
        type: FieldType.TextArea,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: BrandsField.Content,
        label: "Treść",
        span: 12,
        type: FieldType.Editor,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
    ],
  },
  {
    id: BrandsField.Categories,
    label: "Relacje",
    fields: [
      {
        id: BrandsField.Categories,
        label: "Kategorie",
        span: 12,
        type: FieldType.CategoriesSelect,
        dataType: DataType.Categories,
        show: { visible: false },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: BrandsField.Categories,
        label: "Kategorie",
        span: 12,
        type: FieldType.Multi,
        dataType: DataType.Categories,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: BrandsField.Tags,
        label: "Tagi",
        span: 12,
        type: FieldType.TagsSelect,
        dataType: DataType.Tags,
        show: { visible: false },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: BrandsField.Tags,
        label: "Tagi",
        span: 12,
        type: FieldType.Multi,
        dataType: DataType.Tags,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
    ],
  },
  {
    id: "sociaMedia",
    label: "Media społecznościowe",
    fields: [
      {
        id: BrandsField.SocialMediaUrls,
        label: "",
        span: 12,
        type: FieldType.SocialMedia,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
    ],
  },
  {
    id: "seo",
    label: "Tagi SEO",
    fields: [
      {
        id: SeoTagsetsField.MetaTitle,
        label: "Tytuł meta",
        caption: "Maks. 70 znaków ze spacjami",
        maxLength: 70,
        span: 6,
        type: FieldType.Input,
        show: {
          visible: true,
          accessor: [BrandsField.SeoTagset, SeoTagsetsField.MetaTitle],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.OgTitle,
        label: "Tytuł OG",
        caption: "Maks. 70 znaków ze spacjami",
        maxLength: 70,
        span: 6,
        type: FieldType.Input,
        show: {
          visible: true,
          accessor: [BrandsField.SeoTagset, SeoTagsetsField.OgTitle],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.MetaDescription,
        label: "Opis meta",
        caption: "Maks. 256 znaków ze spacjami",
        maxLength: 256,
        span: 6,
        type: FieldType.TextArea,
        show: {
          visible: true,
          accessor: [BrandsField.SeoTagset, SeoTagsetsField.MetaDescription],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.OgDescription,
        label: "Opis OG",
        caption: "Maks. 256 znaków ze spacjami",
        maxLength: 256,
        span: 6,
        type: FieldType.TextArea,
        show: {
          visible: true,
          accessor: [BrandsField.SeoTagset, SeoTagsetsField.OgDescription],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.MetaKeywords,
        label: "Słowa kluczowe meta",
        caption: "Wpisz po przecinku",
        span: 12,
        type: FieldType.Input,
        show: {
          visible: true,
          accessor: [BrandsField.SeoTagset, SeoTagsetsField.MetaKeywords],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.Robots,
        label: "Robots",
        span: 4,
        type: FieldType.DictionaryValuesSelect,
        dictionarySystemName: DictionarySystemName.RobotsSeoTagsets,
        show: {
          visible: true,
          accessor: [BrandsField.SeoTagset, SeoTagsetsField.Robots],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.Canonical,
        label: "Link canonical",
        span: 8,
        type: FieldType.Input,
        show: {
          visible: true,
          accessor: [BrandsField.SeoTagset, SeoTagsetsField.Canonical],
        },
        create: { visible: true },
        update: { visible: true },
      },
    ],
  },
  {
    id: "system",
    label: "Dane systemowe",
    fields: [
      {
        id: BrandsField.CreatedAt,
        label: "Utworzenie",
        span: 3,
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: BrandsField.CreatedBy,
        label: "Utworzono przez",
        span: 3,
        type: FieldType.UserFullName,
        dataType: DataType.Users,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: BrandsField.UpdatedAt,
        label: "Ostatnia aktualizacja",
        span: 3,
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: BrandsField.UpdatedBy,
        label: "Zaktualizowano przez",
        span: 3,
        type: FieldType.UserFullName,
        dataType: DataType.Users,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
    ],
  },
];
