import { AuthorSelectorValue } from "components/author-selector/author-selector";
import { DataType } from "components/formatted-value";
import { SelectValue } from "components/select/select";
import { Allergen } from "containers/Allergens/allergens";
import { Category } from "containers/Categories/categories";
import { RecipeIngredientsGroup, RecipeStep } from "containers/Recipes/recipes";
import { SeoTagsetsField } from "containers/SeoTagsets/seo-tagsets.form";
import { Tag } from "containers/Tags/tags";

import { DictionarySystemName } from "../../constants";
import { FieldsGroup, FieldType } from "../../fields.d";

export type RecipesFormInputs = {
  name: string;
  slug: string;
  content: string;
  isVisible: boolean;
  categories: Category[];
  tags: Tag[];
  allergens: Allergen[];
  recipeSteps: RecipeStep[];
  ingredientGroups: RecipeIngredientsGroup[];
  metaTitle: string;
  metaDescription: string;
  metaKeywords: string;
  ogTitle: string;
  ogDescription: string;
  canonical: string;
  robots: SelectValue;
  author: AuthorSelectorValue;
  difficulty: SelectValue;
  preparationTime: number;
  videoUrl: string;
  servingCount: number;
  image: File;
};

export enum RecipesField {
  Name = "name",
  Slug = "slug",
  Content = "content",
  CreatedAt = "createdAt",
  UpdatedAt = "updatedAt",
  CreatedBy = "createdBy",
  UpdatedBy = "updatedBy",
  IsVisible = "isVisible",
  Categories = "categories",
  Tags = "tags",
  Author = "author",
  SeoTagset = "seoTagset",
  ImageUrl = "imageUrl",
  Image = "image",
  VideoUrl = "videoUrl",
  Difficulty = "difficulty",
  PreparationTime = "preparationTime",
  ServingCount = "servingCount",
  CalorieAmount = "calorieAmount",
  ProteinAmount = "proteinAmount",
  CarbohydrateAmount = "carbohydrateAmount",
  FatAmount = "fatAmount",
  Allergens = "allergens",
  RecipeSteps = "recipeSteps",
  IngredientGroups = "ingredientGroups",
}

export const RECIPES_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: RecipesField.Name,
        label: "Nazwa",
        type: FieldType.BigInput,
        span: 12,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
      {
        id: RecipesField.Slug,
        label: "Slug",
        type: FieldType.SlugInput,
        dataType: DataType.Pre,
        span: 12,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
        caption:
          "Slug jest generowany automatycznie na podstawie nazwy. Możesz go zmienić, edytując powyższe pole.",
      },
      {
        id: RecipesField.Slug,
        label: "Link publiczny",
        type: FieldType.Link,
        span: 12,
        show: { visible: true },
        caption:
          "Link do udostępnienia. Uwaga: Jeśli widoczność jest ustawiona na „nie”, pozycja nie jest dostępna publicznie",
        create: { visible: false, required: false },
        update: { visible: false, required: false },
      },
      {
        id: RecipesField.Author,
        label: "Autor",
        span: 12,
        type: FieldType.Author,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: RecipesField.IsVisible,
        label: "Widoczność",
        type: FieldType.Switch,
        dataType: DataType.VisibilityBoolean,
        readonly: true,
        span: 6,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: RecipesField.Difficulty,
        label: "Trudność",
        type: FieldType.DifficultySelect,
        dataType: DataType.Difficulty,
        span: 6,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
      {
        id: RecipesField.PreparationTime,
        label: "Czas przygotowania",
        type: FieldType.Input,
        dataType: DataType.Minutes,
        span: 6,
        endEnhancer: "min",
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
      {
        id: RecipesField.ServingCount,
        label: "Liczba porcji",
        type: FieldType.Input,
        span: 6,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
      {
        id: RecipesField.VideoUrl,
        label: "Link do wideo",
        type: FieldType.Input,
        dataType: DataType.Link,
        span: 12,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: RecipesField.Content,
        label: "Opis",
        span: 12,
        type: FieldType.TextArea,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
    ],
  },
  {
    id: RecipesField.Image,
    label: "Media",
    fields: [
      {
        id: RecipesField.ImageUrl,
        label: "Zdjęcie przepisu",
        span: 12,
        type: FieldType.Image,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: RecipesField.Image,
        label: "Zdjęcie przepisu",
        span: 12,
        type: FieldType.ImageCropper,
        imageRequirements: { aspect: 1.5 },
        show: { visible: false },
        create: { visible: true },
        update: { visible: true },
      },
    ],
  },
  {
    id: "NutritionFields",
    label: "Wartości odżywcze",
    fields: [
      {
        id: RecipesField.CalorieAmount,
        label: "Kalorie",
        span: 3,
        type: FieldType.Input,
        dataType: DataType.Calories,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: RecipesField.ProteinAmount,
        label: "Białko",
        span: 3,
        type: FieldType.Input,
        dataType: DataType.Gram,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: RecipesField.CarbohydrateAmount,
        label: "Węglowodany",
        span: 3,
        type: FieldType.Input,
        dataType: DataType.Gram,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: RecipesField.FatAmount,
        label: "Tłuszcz",
        span: 3,
        type: FieldType.Input,
        dataType: DataType.Gram,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
    ],
  },
  {
    id: "ingredients",
    label: "Składniki",
    fields: [
      {
        id: RecipesField.IngredientGroups,
        label: "",
        span: 12,
        type: FieldType.RecipeIngredients,
        show: { visible: true },
        create: { visible: false },
        update: { visible: true },
      },
    ],
  },
  {
    id: "preparation",
    label: "Przygotowanie",
    fields: [
      {
        id: RecipesField.RecipeSteps,
        label: "",
        span: 12,
        type: FieldType.RecipeSteps,
        show: { visible: true },
        create: { visible: false },
        update: { visible: true },
      },
    ],
  },
  {
    id: RecipesField.Categories,
    label: "Relacje",
    fields: [
      {
        id: RecipesField.Categories,
        label: "Kategorie",
        span: 12,
        type: FieldType.CategoriesSelect,
        dataType: DataType.Categories,
        show: { visible: false },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: RecipesField.Categories,
        label: "Kategorie",
        span: 12,
        type: FieldType.Multi,
        dataType: DataType.Categories,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: RecipesField.Allergens,
        label: "Alergeny",
        span: 12,
        type: FieldType.AllergensSelect,
        dataType: DataType.Allergens,
        show: { visible: false },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: RecipesField.Allergens,
        label: "Alergeny",
        span: 12,
        type: FieldType.Multi,
        dataType: DataType.Allergens,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
    ],
  },
  {
    id: "seo",
    label: "Tagi SEO",
    fields: [
      {
        id: SeoTagsetsField.MetaTitle,
        label: "Tytuł meta",
        caption: "Maks. 70 znaków ze spacjami",
        maxLength: 70,
        span: 6,
        type: FieldType.Input,
        show: {
          visible: true,
          accessor: [RecipesField.SeoTagset, SeoTagsetsField.MetaTitle],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.OgTitle,
        label: "Tytuł OG",
        caption: "Maks. 70 znaków ze spacjami",
        maxLength: 70,
        span: 6,
        type: FieldType.Input,
        show: {
          visible: true,
          accessor: [RecipesField.SeoTagset, SeoTagsetsField.OgTitle],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.MetaDescription,
        label: "Opis meta",
        caption: "Maks. 256 znaków ze spacjami",
        maxLength: 256,
        span: 6,
        type: FieldType.TextArea,
        show: {
          visible: true,
          accessor: [RecipesField.SeoTagset, SeoTagsetsField.MetaDescription],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.OgDescription,
        label: "Opis OG",
        caption: "Maks. 256 znaków ze spacjami",
        maxLength: 256,
        span: 6,
        type: FieldType.TextArea,
        show: {
          visible: true,
          accessor: [RecipesField.SeoTagset, SeoTagsetsField.OgDescription],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.MetaKeywords,
        label: "Słowa kluczowe meta",
        caption: "Wpisz po przecinku",
        span: 12,
        type: FieldType.Input,
        show: {
          visible: true,
          accessor: [RecipesField.SeoTagset, SeoTagsetsField.MetaKeywords],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.Robots,
        label: "Robots",
        span: 4,
        type: FieldType.DictionaryValuesSelect,
        dictionarySystemName: DictionarySystemName.RobotsSeoTagsets,
        show: {
          visible: true,
          accessor: [RecipesField.SeoTagset, SeoTagsetsField.Robots],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.Canonical,
        label: "Link canonical",
        span: 8,
        type: FieldType.Input,
        show: {
          visible: true,
          accessor: [RecipesField.SeoTagset, SeoTagsetsField.Canonical],
        },
        create: { visible: true },
        update: { visible: true },
      },
    ],
  },
  {
    id: "system",
    label: "Dane systemowe",
    fields: [
      {
        id: RecipesField.CreatedAt,
        label: "Utworzenie",
        span: 3,
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: RecipesField.CreatedBy,
        label: "Utworzono przez",
        span: 3,
        type: FieldType.UserFullName,
        dataType: DataType.Users,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: RecipesField.UpdatedAt,
        label: "Ostatnia aktualizacja",
        span: 3,
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: RecipesField.UpdatedBy,
        label: "Zaktualizowano przez",
        span: 3,
        type: FieldType.UserFullName,
        dataType: DataType.Users,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
    ],
  },
];
