import { BasicFilter, FilterType } from "../../filters.d";
import { IngredientsField } from "./ingredients.form";

export const INGREDIENTS_FILTERS: BasicFilter[] = [
  {
    id: IngredientsField.Name,
    label: "Nazwa",
    type: FilterType.Text,
  },
  {
    id: IngredientsField.CalorieAmount,
    label: "Liczba kalorii",
    type: FilterType.Integer,
  },
  {
    id: IngredientsField.ProteinAmount,
    label: "Białko",
    type: FilterType.Integer,
  },
  {
    id: IngredientsField.CarbohydrateAmount,
    label: "Węglowodany",
    type: FilterType.Integer,
  },
  {
    id: IngredientsField.FatAmount,
    label: "Tłuszcz",
    type: FilterType.Integer,
  },
  {
    id: IngredientsField.IsVisible,
    label: "Widoczność",
    type: FilterType.Boolean,
  },
];
