import { gql } from "@apollo/client";

export const DICTIONARIES_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $sorting: [DictionarySort!]
    $filter: DictionaryFilter
  ) {
    dictionaries(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        name
        systemName
        isSystem
      }
    }
  }
`;

export const DICTIONARIES_SHOW = gql`
  query DictionaryShow($id: Int, $systemName: String, $showHidden: Boolean) {
    dictionary(id: $id, systemName: $systemName) {
      id
      name
      systemName
      values(showHidden: $showHidden) {
        id
        name
        value
        isSystem
        isHidden
      }
    }
  }
`;

export const DICTIONARY_VALUES = gql`
  query DictionaryValues {
    dictionaryValues {
      nodes {
        id
        name
        isSystem
        value
        isHidden
        dictionaryId
      }
    }
  }
`;

export const DICTIONARY_VALUE_SHOW = gql`
  query DictionaryValueShow($id: Int!) {
    dictionaryValue(id: $id) {
      name
      value
      id
      isHidden
      isSystem
    }
  }
`;

export const DICTIONARY_VALUE_CREATE = gql`
  mutation DictionaryValueCreate(
    $dictionaryValueCreateInput: DictionaryValueCreateInput!
  ) {
    dictionaryValueCreate(
      dictionaryValueCreateInput: $dictionaryValueCreateInput
    ) {
      id
    }
  }
`;

export const DICTIONARY_VALUE_UPDATE = gql`
  mutation DictionaryValueUpdate(
    $dictionaryValueUpdateInput: DictionaryValueUpdateInput!
  ) {
    dictionaryValueUpdate(
      dictionaryValueUpdateInput: $dictionaryValueUpdateInput
    ) {
      id
    }
  }
`;

export const DICTIONARY_VALUE_DELETE = gql`
  mutation DictionaryValueDelete(
    $dictionaryValueDeleteInput: DictionaryValueDeleteInput!
  ) {
    dictionaryValueDelete(
      dictionaryValueDeleteInput: $dictionaryValueDeleteInput
    ) {
      id
    }
  }
`;

export const DICTIONARY_EXPORT = gql`
  mutation($id: Int!) {
    dictionaryExportToXlsx(id: $id)
  }
`;
