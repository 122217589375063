import { OutputData } from "@editorjs/editorjs";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { Cell } from "components/cell";
import { Editor } from "components/editor";
import { FormControl } from "components/form-control";
import { PermissionsTable } from "components/form-table";
import { DataType, FormattedValue } from "components/formatted-value";
import { Grid } from "components/grid";
import { LinkRenderer } from "components/link-renderer";
import { Table } from "components/table";
import CategoriesTable from "components/table/categories-table";
import { ActivityLog } from "containers/ActivityLogs/activity-logs";
import { Allergen } from "containers/Allergens/allergens";
import { Article } from "containers/Articles/articles";
import { Avatar } from "containers/Avatars/avatars";
import { Brand } from "containers/Brands/brands";
import { Category } from "containers/Categories/categories.d";
import { Dictionary } from "containers/Dictionaries/dictionaries";
import { Image } from "containers/Images/images";
import { Ingredient } from "containers/Ingredients/ingredients";
import { Product } from "containers/Products/products";
import {
  Author,
  Recipe,
  RecipeIngredient,
  RecipeIngredientsGroup,
  RecipeStep,
} from "containers/Recipes/recipes";
import { Permission, Role } from "containers/Roles/roles";
import { SeoTagset, SeoTagsetSubject } from "containers/SeoTagsets/seo-tagsets";
import { Slide } from "containers/Slides/slides";
import { StaticPage } from "containers/StaticPages/static-pages";
import { Tag } from "containers/Tags/tags";
import { User } from "containers/Users/users";
import { useLoading } from "contexts/loading-context";
import { Field, FieldsGroup, FieldType } from "fields.d";
import React, { useMemo } from "react";
import { Row } from "react-table";
import { Minus } from "tabler-icons-react";
import { renderUserLabel } from "utils/render-user-label";

import { Typename } from "../../constants";

type AppObject =
  | ActivityLog
  | Allergen
  | Article
  | Author
  | Avatar
  | Brand
  | Category
  | Dictionary
  | Image
  | Ingredient
  | Product
  | Recipe
  | Role
  | SeoTagset
  | Slide
  | StaticPage
  | Tag
  | User;

type Props<T> = {
  fields: FieldsGroup[];
  data: T;
  shouldHideFields?: boolean;
};

export default function ObjectViewRenderer<T extends AppObject>({
  fields,
  data,
  shouldHideFields,
}: Props<T>): React.ReactElement {
  const { isFetching, isLoading } = useLoading();
  const [css, theme] = useStyletron();

  const ingredientsColumns = useMemo(
    () => [
      {
        Header: "Składnik",
        id: "ingredientName",
        Cell: ({ row }: { row: Row<RecipeIngredient> }) => (
          <div data-test-id={row.original.ingredient?.name}>
            <FormattedValue
              dataType={DataType.Ingredients}
              data={row.original.ingredient?.id}
            >
              {row.original.ingredient?.name}
            </FormattedValue>
          </div>
        ),
      },
      {
        Header: "Ilość",
        id: "ingredientValue",
        disableGlobalFilter: false,
        Cell: ({ row }: { row: Row<RecipeIngredient> }) => (
          <FormattedValue>{row.original.value}</FormattedValue>
        ),
      },
      {
        Header: "Jednostka",
        id: "ingredientUnit",
        Cell: ({ row }: { row: Row<RecipeIngredient> }) => (
          <FormattedValue>{row.original.unit}</FormattedValue>
        ),
      },
    ],
    [data]
  );

  function renderField(field: Field) {
    switch (field.type) {
      case FieldType.Author: {
        const authorField = (data[field.id as keyof T] as unknown) as Author;

        return authorField?.name ? (
          <FormattedValue>{authorField?.name}</FormattedValue>
        ) : authorField?.user ? (
          <FormattedValue
            dataType={DataType.Users}
            data={authorField?.user?.id}
          >
            {renderUserLabel(authorField?.user)}
          </FormattedValue>
        ) : authorField?.brand ? (
          <FormattedValue
            dataType={DataType.Brands}
            data={authorField?.brand?.id}
          >
            {authorField?.brand?.name}
          </FormattedValue>
        ) : (
          <FormattedValue />
        );
      }

      case FieldType.CategoryRelations: {
        const children = (data["children" as keyof T] as unknown) as Category[];

        const parent = (data["parent" as keyof T] as unknown) as Category;

        return (
          <div>
            {children?.length ? (
              <FormControl label="Kategorie podrzędne">
                <CategoriesTable loading={isLoading} categories={children} />
              </FormControl>
            ) : parent ? (
              <FormControl label="Kategoria nadrzędna">
                <FormattedValue
                  dataType={DataType.Categories}
                  data={parent?.id}
                >
                  {parent?.name}
                </FormattedValue>
              </FormControl>
            ) : (
              <div
                className={css({
                  display: "flex",
                  alignItems: "center",
                  color: "#999999",
                })}
              >
                <>
                  <Minus
                    color="#999999"
                    size={18}
                    className={css({ marginRight: "5px" })}
                  />
                  Brak
                </>
              </div>
            )}
          </div>
        );
      }

      case FieldType.ColorPicker: {
        const color = (data[field.id as keyof T] as unknown) as string;

        return color ? (
          <Block display="flex" alignItems="center">
            <div
              className={css({
                width: "49%",
                height: "40px",
                backgroundColor: color,
                borderRadius: theme.borders.radius300,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "12px",
              })}
            />
            <p
              className={css({
                textTransform: "uppercase",
                fontSize: "14px",
              })}
            >
              {color}
            </p>
          </Block>
        ) : (
          <div
            className={css({
              display: "flex",
              alignItems: "center",
              color: "#999999",
            })}
          >
            <>
              <Minus
                color="#999999"
                size={18}
                className={css({ marginRight: "5px" })}
              />
              Brak
            </>
          </div>
        );
      }

      case FieldType.Editor: {
        const editorData = (data[
          field.id as keyof T
        ] as unknown) as OutputData[] & OutputData;

        return <Editor placeholder={false} readOnly data={editorData} />;
      }

      case FieldType.Image: {
        const url = (data[field.id as keyof T] as unknown) as string;

        return (
          <div
            className={css({
              backgroundColor: theme.colors.inputFill,
              padding: "20px",
              borderRadius: theme.borders.radius200,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            })}
          >
            {url ? (
              <img
                src={url}
                className={css({
                  maxHeight: "400px",
                  maxWidth: "100%",
                  objectFit: "contain",
                  objectPosition: "left",
                })}
              />
            ) : (
              <div
                className={css({
                  display: "flex",
                  alignItems: "center",
                  color: "#999999",
                })}
              >
                <>
                  <Minus
                    color="#999999"
                    size={18}
                    className={css({ marginRight: "5px" })}
                  />
                  Brak
                </>
              </div>
            )}
          </div>
        );
      }

      case FieldType.Multi: {
        const multiField = (data[
          field.id as keyof T
        ] as unknown) as AppObject[];

        return (
          <div
            className={css({ display: "flex", gap: "15px", flexWrap: "wrap" })}
          >
            {multiField?.length ? (
              multiField?.map((object: AppObject) => (
                <FormattedValue
                  key={object.id}
                  dataType={field.dataType}
                  data={object.id}
                >
                  {"name" in object && object.name}
                </FormattedValue>
              ))
            ) : (
              <FormattedValue />
            )}
          </div>
        );
      }

      case FieldType.PermissionsTable:
        return (
          <PermissionsTable
            isReadOnly
            selectedIds={(data as User | Role)?.permissions?.map(
              ({ id }: Permission) => id
            )}
          />
        );

      case FieldType.RecipeSteps: {
        const recipeSteps = (data[
          field.id as keyof T
        ] as unknown) as RecipeStep[];

        return (
          <div
            className={css({ display: "flex", gap: "15px", flexWrap: "wrap" })}
          >
            {recipeSteps?.length ? (
              recipeSteps?.map((recipeStep: RecipeStep, index: number) => (
                <div
                  key={recipeStep.id}
                  className={css({ display: "flex", gap: "16px" })}
                >
                  <div
                    className={css({
                      borderRadius: "50%",
                      width: "20px",
                      height: "20px",
                      color: "white",
                      background: theme.colors.primary,
                      flexShrink: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      fontSize: "10px",
                    })}
                  >
                    {index + 1}
                  </div>

                  <FormattedValue>{recipeStep.content}</FormattedValue>
                </div>
              ))
            ) : (
              <FormattedValue />
            )}
          </div>
        );
      }

      case FieldType.RecipeIngredients: {
        const recipeIngredientGroups = (data[
          field.id as keyof T
        ] as unknown) as RecipeIngredientsGroup[];

        return (
          <div
            className={css({
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              marginTop: "8px",
            })}
          >
            {recipeIngredientGroups?.length ? (
              recipeIngredientGroups?.map(
                (ingredientsGroup: RecipeIngredientsGroup, index: number) => (
                  <div
                    key={ingredientsGroup.id}
                    className={css({
                      display: "flex",
                      flexDirection: "column",
                    })}
                  >
                    <FormattedValue $style={{ fontSize: "14px" }}>
                      {ingredientsGroup.name}
                    </FormattedValue>

                    <div
                      className={css({
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "8px",
                      })}
                    >
                      <Table
                        key={`ingredients-table-${index}`}
                        data={ingredientsGroup?.ingredients || []}
                        columns={ingredientsColumns}
                        compact
                      />
                    </div>
                  </div>
                )
              )
            ) : (
              <FormattedValue />
            )}
          </div>
        );
      }

      case FieldType.SeoTagsetSubject: {
        const handleSeoTagsetSubject = (
          typename: string,
          subject: SeoTagsetSubject
        ) => {
          switch (typename) {
            case Typename.Article:
              return (
                <FormattedValue
                  dataType={DataType.Articles}
                  data={subject?.id}
                  deletedAt={subject?.deletedAt}
                >
                  {subject?.name}
                </FormattedValue>
              );
            case Typename.Brand:
              return (
                <FormattedValue
                  dataType={DataType.Brands}
                  data={subject?.id}
                  deletedAt={subject?.deletedAt}
                >
                  {subject?.name}
                </FormattedValue>
              );
            case Typename.Category:
              return (
                <FormattedValue
                  dataType={DataType.Categories}
                  data={subject?.id}
                  deletedAt={subject?.deletedAt}
                >
                  {subject?.name}
                </FormattedValue>
              );
            case Typename.Recipe:
              return (
                <FormattedValue
                  dataType={DataType.Recipes}
                  data={subject?.id}
                  deletedAt={subject?.deletedAt}
                >
                  {subject?.name}
                </FormattedValue>
              );
            case Typename.StaticPage:
              return (
                <FormattedValue
                  dataType={DataType.StaticPages}
                  data={subject?.id}
                  deletedAt={subject?.deletedAt}
                >
                  {subject?.name}
                </FormattedValue>
              );
            default:
              <FormattedValue />;
          }
        };
        return field.typeName ? (
          handleSeoTagsetSubject(field.typeName, (data as SeoTagset)?.subject)
        ) : (
          <FormattedValue />
        );
      }

      case FieldType.SlideVariant: {
        const slideVariantField = data as any;

        return slideVariantField?.article ? (
          <FormControl label="Artykuł">
            <FormattedValue
              dataType={DataType.Articles}
              data={slideVariantField?.article?.id}
            >
              {slideVariantField?.article?.name}
            </FormattedValue>
          </FormControl>
        ) : slideVariantField?.recipe ? (
          <FormControl label="Przepis">
            <FormattedValue
              dataType={DataType.Recipes}
              data={slideVariantField?.recipe?.id}
            >
              {slideVariantField?.recipe?.name}
            </FormattedValue>
          </FormControl>
        ) : slideVariantField?.url ? (
          <div className={css({ display: "flex", gap: "64px" })}>
            <div>
              <FormControl label="Adres strony WWW">
                <FormattedValue dataType={DataType.Link}>
                  {slideVariantField?.url}
                </FormattedValue>
              </FormControl>
            </div>

            <div>
              <FormControl label="Etykieta przycisku">
                <FormattedValue>
                  {slideVariantField?.buttonLabel}
                </FormattedValue>
              </FormControl>
            </div>
          </div>
        ) : (
          <FormattedValue />
        );
      }

      case FieldType.SocialMedia: {
        const socialMedia = (data[
          field.id as keyof T
        ] as unknown) as AppObject[];

        return (
          <div
            className={css({ display: "flex", gap: "15px", flexWrap: "wrap" })}
          >
            {socialMedia?.length ? (
              socialMedia?.map((object: AppObject) => {
                return (
                  <FormattedValue
                    key={object.id}
                    dataType={DataType.Link}
                    href={"url" in object ? object.url : ""}
                  >
                    {"name" in object && object.name}
                  </FormattedValue>
                );
              })
            ) : (
              <FormattedValue />
            )}
          </div>
        );
      }

      case FieldType.UserFullName: {
        const userField = (data[field.id as keyof T] as unknown) as User;

        return userField ? (
          <FormattedValue dataType={field.dataType} data={userField?.id}>
            {renderUserLabel(userField)}
          </FormattedValue>
        ) : (
          <FormattedValue />
        );
      }

      case FieldType.UserStatus: {
        const userField = data as User;

        if (
          userField?.createdAt &&
          userField?.invitedAt &&
          !userField?.activatedAt &&
          !userField?.blockedAt &&
          !userField?.deletedAt
        )
          return (
            <FormattedValue dataType={field.dataType}>
              Wysłano zaproszenie
            </FormattedValue>
          );
        else if (
          userField?.createdAt &&
          userField?.activatedAt &&
          !userField?.blockedAt &&
          !userField?.deletedAt
        )
          return (
            <FormattedValue dataType={field.dataType}>Aktywny</FormattedValue>
          );
        else if (userField?.blockedAt && !userField?.deletedAt)
          return (
            <FormattedValue dataType={field.dataType}>
              Zablokowany
            </FormattedValue>
          );
        else return <FormattedValue />;
      }

      case FieldType.Link: {
        const slug = (data?.["slug" as keyof T] as unknown) as string;

        return <LinkRenderer slug={slug} />;
      }

      default:
        return (
          <FormattedValue
            dataType={field.dataType}
            data={
              ((data?.[field.id as keyof AppObject] as unknown) as Brand)?.id
            }
          >
            {field.show.accessor
              ? ((data[
                  (field.show.accessor as string[])[0] as keyof AppObject
                ] as unknown) as AppObject)?.[
                  (field.show.accessor as string[])[1] as keyof AppObject
                ]
              : data?.[field.id as keyof AppObject]}
          </FormattedValue>
        );
    }
  }

  return (
    <Grid>
      {fields.map((group) => [
        group.label &&
          (!shouldHideFields ||
            (shouldHideFields &&
              group.fields.some((field) => !field.optionallyHidden))) && (
            <Cell key={group.id + `-group`} span={12}>
              <LabelMedium marginBottom="scale200" marginTop="scale600">
                {group.label}
              </LabelMedium>
              <hr
                className={css({
                  borderWidth: "0px",
                  height: "1px",
                  backgroundColor: "#eee",
                })}
              />
            </Cell>
          ),
        group.fields
          .filter((field) =>
            field.type === FieldType.SeoTagsetSubject
              ? field.show.visible &&
                ((field.typeName &&
                  field.typeName ===
                    (data as SeoTagset)?.subject?.__typename) ||
                  !field.typeName)
              : field.show.visible
          )
          .map((field, index) => {
            if (
              (shouldHideFields && !field.optionallyHidden) ||
              !shouldHideFields
            )
              return (
                <Cell span={field.span || 6} key={group.id + `-field` + index}>
                  <FormControl
                    label={field.label}
                    caption={field.caption}
                    overrides={{
                      ControlContainer: {
                        props: {
                          "data-test-id": field.id,
                        },
                      },
                    }}
                  >
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      renderField(field)
                    )}
                  </FormControl>
                </Cell>
              );
          }),
      ])}
    </Grid>
  );
}
