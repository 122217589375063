import {
  API,
  BlockAPI,
  BlockTool,
  BlockToolConstructorOptions,
  BlockToolData,
  ToolboxConfig,
} from "@editorjs/editorjs";

import { useImageLibrary } from "../../../contexts/image-library-context";

interface GalleryData extends BlockToolData {
  imageUrls?: string[]; // Deprecated, use images instead
  images: {
    id: number;
    imageUrl: string;
    linkUrl?: string;
    openInNewTab?: boolean;
  }[];
  isStretched?: boolean;
}

interface GalleryConfig {
  imageLibraryContext: ReturnType<typeof useImageLibrary>;
  title: "Galeria ogólna";
}

type GalleryNodes = {
  button?: HTMLButtonElement;
  imagesContainer?: HTMLDivElement;
};

type GallerySetting = {
  icon: string;
  name: GallerySettingName;
  label: string;
};

enum GallerySettingName {
  IsStretched = "isStretched",
}

const STYLESHEET = `
  .images-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 10px;
    row-gap: 10px;
  }

  .images-container > div {
    width: 100%; 
    background-color: white;
    position: relative;
    padding: 12px;
    border-radius: 8px;
  }

  .images-container > div > button {
    background-color: rgb(239, 56, 48);
    top: -10px;
    right: -10px;
    position: absolute;
    display: none;
    color: white;
    height: 28px;
  }

  .images-container > div > button:hover {
    background-color: rgb(192 41 35);
  }

  .images-container > div > img {
    width: 100%;
    height: 120px;
    object-fit: contain;
    background-color: #fafafa;
  }

  .images-container > div > input {
    margin-top: 8px;
    width: 100%; 
    border: 1px solid #eee;
    border-radius: 4px;
    padding: 4px 8px;
  }

  .images-container > div > label {
    margin-top: 8px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 6px;
    font-size: 11px;
    justify-content: flex-end;
  }

  .images-container > div > label > input {
    margin: 0;
  }

  .images-container > div:hover > button {
    display: block;
  }
`;

export default class Gallery implements BlockTool {
  public static isReadOnlySupported = true;
  public static enableLineBreaks = true;
  public static sanitize = {
    isStretched: false,
    imageUrls: [],
    images: [],
  };

  private _data: GalleryData;
  private isReadOnly: boolean;
  private api: API;
  private config?: GalleryConfig;
  private nodes: GalleryNodes;
  private block?: BlockAPI;

  static get toolbox(): ToolboxConfig {
    return {
      title: "Galeria ogólna",
      icon: `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 19V19C9.13623 19 8.20435 19 7.46927 18.6955C6.48915 18.2895 5.71046 17.5108 5.30448 16.5307C5 15.7956 5 14.8638 5 13V12C5 9.19108 5 7.78661 5.67412 6.77772C5.96596 6.34096 6.34096 5.96596 6.77772 5.67412C7.78661 5 9.19108 5 12 5H13.5C14.8956 5 15.5933 5 16.1611 5.17224C17.4395 5.56004 18.44 6.56046 18.8278 7.83886C19 8.40666 19 9.10444 19 10.5V10.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16 13V16M16 19V16M19 16H16M16 16H13" stroke="black" stroke-width="2" stroke-linecap="round"/>
        <path d="M6.5 17.5L17.5 6.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18.9919 10.5H19.0015" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.9919 19H11.0015" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5 13L13 5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      `,
    };
  }

  constructor({
    data,
    readOnly,
    api,
    config,
    block,
  }: BlockToolConstructorOptions<GalleryData, GalleryConfig>) {
    this.isReadOnly = readOnly;
    this.api = api;
    this.config = config;
    this.block = block;

    this.nodes = {
      button: undefined,
      imagesContainer: undefined,
    };

    this._data = {
      ...data,
      images:
        data.imageUrls && data.imageUrls.length > 0
          ? data.imageUrls.map((imageUrl, id) => ({ id, imageUrl }))
          : data.images || [],
      isStretched: !!data.isStretched,
    };
  }

  get settings(): GallerySetting[] {
    return [
      {
        icon: `
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 9L20 12L17 15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M14 12H20" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M7 9L4 12L7 15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M4 12H10" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        `,
        name: GallerySettingName.IsStretched,
        label: "Rozszerz/Zwęź",
      },
    ];
  }

  get data(): GalleryData {
    return this._data;
  }

  set data(data: GalleryData) {
    this._data = { ...this.data, ...data };

    if (this.block) {
      this.block.stretched = !!data.isStretched;
    }

    this.renderImages();
  }

  private renderImages() {
    if (this.nodes.imagesContainer) {
      const children: Node[] = [];

      for (const image of this.data.images) {
        const imageObject = document.createElement("div");
        imageObject.dataset.id = image.id.toString();
        imageObject.classList.add(this.api.styles.block, "image");

        const imageNode = document.createElement("img");
        imageNode.src = image.imageUrl;

        imageObject.appendChild(imageNode);

        if (!this.isReadOnly) {
          const removeImage = (event: Event) => {
            event.preventDefault();

            this.data = {
              ...this.data,
              images: this.data.images.filter((item) => item.id !== image.id),
            };
          };

          const button = document.createElement("button");

          button.classList.add(this.api.styles.settingsButton);
          button.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"><path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M8 8L12 12M12 12L16 16M12 12L16 8M12 12L8 16"></path></svg>`;
          button.addEventListener("click", removeImage);

          imageObject.appendChild(button);

          const input = document.createElement("input");
          input.classList.add("link-url");
          input.setAttribute("placeholder", "Link do przekierowania");
          input.setAttribute("type", "text");
          input.setAttribute("value", image.linkUrl ?? "");

          input.addEventListener("change", () => {
            this.update();
          });

          imageObject.appendChild(input);

          const checkbox = document.createElement("input");
          checkbox.classList.add("open-in-new-tab");
          checkbox.setAttribute("type", "checkbox");
          if (image.openInNewTab === true) checkbox.setAttribute("checked", "");

          checkbox.addEventListener("change", () => {
            this.update();
          });

          const label = document.createElement("label");
          label.innerHTML = "Otwórz w nowej karcie";
          label.appendChild(checkbox);

          imageObject.appendChild(label);
        }

        children.push(imageObject);
      }

      // TypeScript throws an error that `replaceChildren` is not available on HTMLDivElement which is not true
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.nodes.imagesContainer.replaceChildren(...children);
    }
  }

  render(): HTMLElement {
    const container = document.createElement("div");
    container.classList.add(this.api.styles.block);
    container.style.textAlign = "center";

    const style = document.createElement("style");
    style.appendChild(document.createTextNode(STYLESHEET));

    const openGalleryLibrary = (event: Event) => {
      event.preventDefault();

      this.config?.imageLibraryContext.open((image) => {
        const url = image?.url;

        this.data = {
          ...this.data,
          images: [...this.data.images, { id: Date.now(), imageUrl: url }],
        };

        this.update();
      });
    };

    const button = document.createElement("button");

    button.classList.add(this.api.styles.button);
    button.innerHTML = `Wgraj lub wybierz z biblioteki (${
      this.config?.title ?? "Galeria ogólna"
    })`;
    button.addEventListener("click", openGalleryLibrary);

    const imagesContainer = document.createElement("div");
    imagesContainer.classList.add(this.api.styles.block, "images-container");

    container.appendChild(style);
    container.appendChild(imagesContainer);

    if (!this.isReadOnly) {
      container.appendChild(button);

      this.nodes.button = button;
    }

    this.nodes.imagesContainer = imagesContainer;

    this.renderImages();

    // If the stretched prop is set without setTimeout, the block doesn't render
    setTimeout(() => {
      if (this.block) {
        this.block.stretched = !!this.data.isStretched;
      }
    }, 0);

    return container;
  }

  renderSettings() {
    return this.settings.map((setting: GallerySetting) => {
      return {
        icon: setting.icon,
        label: setting.label,
        isActive: this.data.isStretched ?? false,
        onActivate: () => this.toggleIsStretched(),
        closeOnActivate: true,
      };
    });
  }

  private toggleIsStretched(): void {
    this.data.isStretched = !this.data.isStretched;
    if (this.block) {
      this.block.stretched = !!this.data.isStretched;
    }

    this.update();
  }

  private update(): void {
    const block = this.api.blocks.insert(
      undefined,
      undefined,
      undefined,
      Infinity
    );

    this.api.blocks.delete(this.api.blocks.getBlockIndex(block.id));
  }

  save(rootElement: HTMLDivElement): GalleryData {
    const caption = rootElement.querySelector(".caption")?.innerHTML || "";
    const alt = rootElement.querySelector(".alt")?.innerHTML || "";

    const images = rootElement.querySelectorAll(".image");

    for (const image of images) {
      const id = parseInt(image.getAttribute("data-id") || "");
      const linkUrl =
        (image.querySelector(".link-url") as HTMLInputElement).value || "";
      const openInNewTab =
        (image.querySelector(".open-in-new-tab") as HTMLInputElement).checked ||
        false;

      this.data.images = this.data.images.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            linkUrl,
            openInNewTab: !!openInNewTab,
          };
        }

        return item;
      });
    }

    return {
      ...this.data,
      imageUrls: [], // Clean up deprecated field
      caption,
      alt,
    };
  }
}

export class DesktopGallery extends Gallery {
  static get toolbox(): ToolboxConfig {
    return {
      title: "Galeria desktop",
      icon: `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 19V19C9.13623 19 8.20435 19 7.46927 18.6955C6.48915 18.2895 5.71046 17.5108 5.30448 16.5307C5 15.7956 5 14.8638 5 13V12C5 9.19108 5 7.78661 5.67412 6.77772C5.96596 6.34096 6.34096 5.96596 6.77772 5.67412C7.78661 5 9.19108 5 12 5H13.5C14.8956 5 15.5933 5 16.1611 5.17224C17.4395 5.56004 18.44 6.56046 18.8278 7.83886C19 8.40666 19 9.10444 19 10.5V10.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16 13V16M16 19V16M19 16H16M16 16H13" stroke="black" stroke-width="2" stroke-linecap="round"/>
        <path d="M6.5 17.5L17.5 6.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18.9919 10.5H19.0015" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.9919 19H11.0015" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5 13L13 5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      `,
    };
  }
}

export class MobileGallery extends Gallery {
  static get toolbox(): ToolboxConfig {
    return {
      title: "Galeria mobile",
      icon: `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 19V19C9.13623 19 8.20435 19 7.46927 18.6955C6.48915 18.2895 5.71046 17.5108 5.30448 16.5307C5 15.7956 5 14.8638 5 13V12C5 9.19108 5 7.78661 5.67412 6.77772C5.96596 6.34096 6.34096 5.96596 6.77772 5.67412C7.78661 5 9.19108 5 12 5H13.5C14.8956 5 15.5933 5 16.1611 5.17224C17.4395 5.56004 18.44 6.56046 18.8278 7.83886C19 8.40666 19 9.10444 19 10.5V10.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16 13V16M16 19V16M19 16H16M16 16H13" stroke="black" stroke-width="2" stroke-linecap="round"/>
        <path d="M6.5 17.5L17.5 6.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18.9919 10.5H19.0015" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.9919 19H11.0015" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5 13L13 5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      `,
    };
  }
}
