import { gql, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Brand } from "containers/Brands/brands";
import { useAuth } from "contexts/auth-context";
import React from "react";
import { Controller, UseControllerProps } from "react-hook-form";
import { Ban } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";

import Select, { SelectProps } from "./select";

export default function BrandsSelect({
  disabled,
  ...rest
}: SelectProps): React.ReactElement {
  const { checkPermission } = useAuth();
  const [css] = useStyletron();
  const { data, loading } = useQuery(
    gql`
      query {
        brands(paging: { limit: 10000 }) {
          nodes {
            id
            name
          }
        }
      }
    `
  );

  const hasPermission = checkPermission(PERMISSIONS.brand.read);

  return (
    <Select
      options={data?.brands?.nodes?.map(({ id, name }: Brand) => ({
        id,
        label: name,
      }))}
      getValueLabel={({ option }) => `${option?.label}`}
      getOptionLabel={({ option }) => `${option?.label}`}
      placeholder={
        hasPermission ? (
          "Wybierz"
        ) : (
          <div
            className={css({
              display: "flex",
              alignItems: "center",
              gap: " 5px",
            })}
          >
            <Ban size={16} />
            Brak uprawnień do przeglądania marek
          </div>
        )
      }
      disabled={disabled || !hasPermission}
      isLoading={loading}
      {...rest}
    />
  );
}

export function ControlledBrandsSelect({
  control,
  disabled,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & SelectProps): React.ReactElement {
  const { checkPermission } = useAuth();
  const [css] = useStyletron();
  const { data, loading } = useQuery(
    gql`
      query {
        brands(paging: { limit: 10000 }) {
          nodes {
            id
            name
          }
        }
      }
    `
  );

  const hasPermission = checkPermission(PERMISSIONS.brand.read);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={data?.brands?.nodes?.map(({ id, name }: Brand) => ({
            id,
            label: name,
          }))}
          placeholder={
            hasPermission ? (
              "Wybierz"
            ) : (
              <div
                className={css({
                  display: "flex",
                  alignItems: "center",
                  gap: " 5px",
                })}
              >
                <Ban size={16} />
                Brak uprawnień do przeglądania marek
              </div>
            )
          }
          disabled={disabled || !hasPermission}
          isLoading={loading}
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          {...(data && { value: value })}
          {...rest}
        />
      )}
    />
  );
}
