import { useStyletron } from "baseui";
import { Input } from "components/input";
import { DictionaryValuesSelect } from "components/select";
import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { Controller, UseControllerProps } from "react-hook-form";
import { Plus, Trash } from "tabler-icons-react";

import { DictionarySystemName } from "../../constants";
import { generateUUID } from "../../utils/misc";
import { Button } from "../button";

export type SocialMedia = {
  id: string;
  name?: string;
  url?: string;
};

type SocialMediaComposerProps = {
  value?: SocialMedia[];
  onChange?: (socialMedia?: SocialMedia[]) => void;
  id?: string;
  error?: boolean;
  disabled?: boolean;
};

export default function SocialMediaComposer({
  value,
  onChange,
  error,
  disabled,
}: SocialMediaComposerProps): ReactElement {
  const [css] = useStyletron();

  const [items, setItems] = useState<SocialMedia[]>(
    value || [
      {
        id: "autoGeneratedFirstItem",
      },
    ]
  );
  const [changes, setChanges] = useState(0);

  useEffect(() => {
    if (changes > 0)
      onChange &&
        onChange(
          items
            ?.filter((item) => item.name && item.url)
            ?.map(
              (item: any) =>
                item.name &&
                item.url && {
                  id: item.id,
                  name: item.name,
                  url: item.url,
                }
            )
        );
  }, [changes]);

  const handleDelete = (id: string) => {
    setItems(items?.filter((item: SocialMedia) => item.id !== id));
    setChanges(changes + 1);
  };

  return (
    <div>
      {!!items?.length &&
        items?.map((item: SocialMedia, index: number) => (
          <div
            key={`step-${item.id}`}
            className={css({
              display: "flex",
              gap: "8px",
              marginTop: "8px",
              ":first-of-type": {
                marginTop: 0,
              },
            })}
            data-test-id="social-media"
          >
            <DictionaryValuesSelect
              {...(item?.name && {
                value: [{ label: item.name }],
              })}
              systemName={DictionarySystemName.SocialMedia}
              error={error}
              disabled={disabled}
              onChange={(params) => {
                setItems((currentItems) => {
                  return currentItems.map((el) => {
                    if (el.id === item.id) {
                      const newName =
                        typeof params.option?.label === "string"
                          ? params.option?.label
                          : "";
                      return {
                        ...el,
                        name: newName,
                      };
                    }
                    return el;
                  });
                });
                setChanges((changes) => changes + 1);
              }}
            />

            <Input
              value={item?.url || ""}
              error={error}
              disabled={disabled}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setItems((items) => {
                  items[index].url = event?.target?.value;
                  return items;
                });
                setChanges((changes) => changes + 1);
              }}
              placeholder="URL"
              size="mini"
            />

            <Button
              onClick={() => {
                handleDelete(item.id);
              }}
              kind="secondary"
              type="button"
              size="mini"
              disabled={disabled}
            >
              <Trash size={16} />
            </Button>
          </div>
        ))}

      <Button
        type="button"
        kind="secondary"
        disabled={disabled}
        onClick={() => {
          const newId = generateUUID();

          setItems((items) => [
            ...items,
            {
              id: newId,
            },
          ]);
          setChanges((changes) => changes + 1);
        }}
        $style={{
          display: "flex",
          gap: "8px",
          alignItems: "center",
          marginTop: "16px",
        }}
        size="mini"
      >
        <Plus size={14} /> Dodaj
      </Button>
    </div>
  );
}

export function ControlledSocialMediaComposer({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & SocialMediaComposerProps): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }) => {
        return (
          <SocialMediaComposer
            onChange={onChange}
            value={value}
            id={name}
            {...rest}
          />
        );
      }}
    />
  );
}
