import { gql } from "@apollo/client";

export const INGREDIENTS_INDEX = gql`
  query IngredientsIndex(
    $pageSize: Int
    $offset: Int
    $filter: IngredientFilter
    $sorting: [IngredientSort!]
  ) {
    ingredients(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        name
        calorieAmount
        proteinAmount
        carbohydrateAmount
        fatAmount
        isVisible
      }
    }
  }
`;

export const INGREDIENTS_SHOW = gql`
  query IngredientShow($id: Int!) {
    ingredient(id: $id) {
      id
      name
      isVisible
      calorieAmount
      proteinAmount
      carbohydrateAmount
      fatAmount
      teaspoon
      unit
      tablespoon
      glass
      pinch
      cube
      can
      bunch
      package
      slice
      twig
    }
  }
`;

export const INGREDIENTS_CREATE = gql`
  mutation IngredientCreate($ingredientCreateInput: IngredientCreateInput!) {
    ingredientCreate(ingredientCreateInput: $ingredientCreateInput) {
      id
    }
  }
`;

export const INGREDIENTS_UPDATE = gql`
  mutation IngredientUpdate($ingredientUpdateInput: IngredientUpdateInput!) {
    ingredientUpdate(ingredientUpdateInput: $ingredientUpdateInput) {
      id
    }
  }
`;

export const INGREDIENTS_DELETE = gql`
  mutation IngredientDelete($ingredientDeleteInput: IngredientDeleteInput!) {
    ingredientDelete(ingredientDeleteInput: $ingredientDeleteInput) {
      id
    }
  }
`;
