import { BasicFilter, FilterType } from "./../../filters.d";
import { AvatarsField } from "./avatars.form";

export const AVATARS_FILTERS: BasicFilter[] = [
  {
    id: AvatarsField.Name,
    label: "Nazwa",
    type: FilterType.Text,
  },
  {
    id: AvatarsField.CreatedAt,
    label: "Utworzenie",
    type: FilterType.Date,
  },
  {
    id: AvatarsField.CreatedBy,
    label: "Utworzono przez",
    type: FilterType.CreatedBy,
  },
];
