import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { StyledLink } from "baseui/link";
import { Skeleton } from "baseui/skeleton";
import {
  Table as BaseTable,
  TableProps as BaseTableProps,
} from "baseui/table-semantic";
import { LabelXSmall } from "baseui/typography";
import { ForwardedButton } from "components/button";
import { Popover } from "components/popover";
import { TableHead } from "components/table";
import { Tooltip } from "components/tooltip";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { usePaging } from "contexts/paging-context";
import { isEqual } from "lodash";
import React, {
  Fragment,
  memo,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { Cell, Column, Row, useExpanded, useTable } from "react-table";
import { StyleObject } from "styletron-react";
import {
  CircleCheck,
  DeviceFloppy,
  Eye,
  EyeCheck,
  EyeOff,
  ListSearch,
  Table as TableIcon,
} from "tabler-icons-react";
import {
  getColumnDataAlign,
  getColumnWidth,
} from "utils/interface/column-sizes";
import { getDefaultColumns } from "utils/interface/default-columns";
import {
  getPreferencedColumns,
  setPreferencedColumns,
} from "utils/interface/preferences";
import { textContent } from "utils/misc";

import { PAGE_SIZE } from "../../constants";

type Props<T> = {
  expandedNumber?: number;
  data: T[];
  columns: Column[];
  compact?: boolean;
  $style?: StyleObject;
  $tableBodyRowStyle?: StyleObject;
  $rows?: (rows: Row[], prepareRow: (row: Row) => void) => React.ReactNode[][];
  $footer?: () => React.ReactNode;
  scrollable?: boolean;
  id?: string;
  isModal?: boolean;
  stickLastColumn?: boolean;
  editable?: boolean;
} & Omit<BaseTableProps, "data" | "columns">;

export default function Table<T>({
  data,
  columns,
  $style,
  $tableBodyRowStyle,
  $rows,
  $footer,
  expandedNumber,
  scrollable = true,
  id,
  stickLastColumn,
  compact,
  editable,
  ...props
}: Props<T>): React.ReactElement {
  const [css, theme] = useStyletron();
  const { isFetching } = useLoading();
  const [fullWindowMode, setFullWindowMode] = useState(false);
  const [tooltipsReady, setTooltipsReady] = useState(false);

  const tableRef = useRef<HTMLDivElement>(null);

  const { pathname } = useLocation();
  const { interfacePreferences, updateUser } = useAuth();
  const { pageSize } = usePaging();

  const preferencedColumns = getPreferencedColumns(
    interfacePreferences,
    pathname
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data: data ? (data as any[]) : [],
      initialState: {
        expanded: Array.from(
          new Array(expandedNumber === undefined ? 100 : expandedNumber).keys()
        ).reduce((a, _b, index) => ({ ...a, [index]: true }), {}),
      },
      autoResetExpanded: false,
    },
    useExpanded
  );

  const allColumns = headerGroups
    ?.map((headerGroup) => {
      return headerGroup.headers.map((column) => column.id);
    })
    .flat();

  const defaultColumns = getDefaultColumns(pathname) || allColumns;

  const [visibleColumns, setVisibleColumns] = useState<string[]>(
    preferencedColumns || defaultColumns
  );

  const [columnsChanged, setColumnsChanged] = useState(false);

  const tableColumns = useMemo(
    () =>
      headerGroups
        .map((headerGroup) => {
          return headerGroup.headers
            .filter(
              (column) =>
                visibleColumns?.includes(column.id) || column.id === "actions"
            )
            .map((column) => {
              return (
                <div
                  key={column.id}
                  className={css({
                    width:
                      column.id !== "actions"
                        ? editable
                          ? "auto"
                          : getColumnWidth(column.id) || "200px"
                        : "90px",
                    ...getColumnDataAlign(column.id),
                    minWidth: "100%",
                  })}
                >
                  {column.render("Header")}
                </div>
              );
            });
        })
        .flat(),
    [headerGroups, visibleColumns]
  );

  const tableRows = useMemo(
    () =>
      rows.map((row: any) => {
        prepareRow(row);
        return row.cells
          .filter(
            (cell: Cell) =>
              visibleColumns.includes(cell.column.id) ||
              cell.column.id === "actions"
          )
          .map((cell: Cell) => {
            const wrapper = document?.querySelector(
              `[data-cell=${cell.column.id.replace(/[^a-zA-Z]/g, "")}${
                cell.row.id
              }] > div`
            );

            const shouldShowTooltip =
              !editable &&
              wrapper &&
              wrapper.clientWidth < wrapper.scrollWidth - 10;

            return cell.column.id === "actions" ? (
              cell.render("Cell")
            ) : (
              <div
                key={cell.value}
                data-cell={`${cell.column.id.replace(/[^a-zA-Z]/g, "")}${
                  cell.row.id
                }`}
              >
                {shouldShowTooltip ? (
                  <Tooltip ignoreBoundary content={cell.render("Cell")}>
                    <div
                      data-ui="tooltip-content"
                      className={css({
                        width: editable
                          ? "auto"
                          : getColumnWidth(cell.column.id) || "200px",
                        ...getColumnDataAlign(cell.column.id),
                        minWidth: "calc(100%)",
                        whiteSpace: "nowrap",
                        display: "block",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      })}
                    >
                      {cell.render("Cell")}
                    </div>
                  </Tooltip>
                ) : (
                  <div
                    className={css({
                      width: editable
                        ? "auto"
                        : getColumnWidth(cell.column.id) || "200px",
                      minWidth: "calc(100%)",
                      whiteSpace: "nowrap",
                      display: "block",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      ...getColumnDataAlign(cell.column.id),
                    })}
                  >
                    {cell.render("Cell")}
                  </div>
                )}
              </div>
            );
          });
      }),
    [rows, visibleColumns, tooltipsReady]
  );

  function highlightColumn(event: any) {
    const index = [...event.target.closest("thead > tr")?.children].indexOf(
      event.target.closest("th")
    );

    event.target
      .closest("[data-ui=table-root]")
      ?.querySelector("[data-baseweb=table-semantic] table")
      ?.querySelectorAll(`td:nth-child(${index + 1})`)
      ?.forEach((el: HTMLElement) => (el.style.backgroundColor = "#fbfbfb"));
  }

  function unhighlightColumn(event: any) {
    const index = [...event.target.closest("thead > tr")?.children].indexOf(
      event.target.closest("th")
    );

    event.target
      .closest("[data-ui=table-root]")
      ?.querySelector("[data-baseweb=table-semantic] table")
      ?.querySelectorAll(`td:nth-child(${index + 1})`)
      ?.forEach((el: HTMLElement) => (el.style.backgroundColor = ""));
  }

  useEffect(() => {
    if (data)
      setTimeout(() => setTooltipsReady((tooltipsReady) => !tooltipsReady), 50);
  }, [data]);

  return (
    <div
      className={css({
        position: fullWindowMode ? "fixed" : "relative",
        scrollSnapType: "x mandatory",
        ...$style,
        ...(fullWindowMode && {
          inset: "0px",
          zIndex: 50,
          overflow: "auto",
          maxHeight: "100vh",
        }),
      })}
      data-ui="table-root"
    >
      {!compact && (
        <div
          className={css({
            position: "absolute",
            right: "3px",
            top: "3px",
            borderRadius: "8px",
            zIndex: 8,
            paddingLeft: "6px",
            paddingRight: "6px",
            paddingBottom: "4px",
            paddingTop: "4px",
          })}
        >
          <Popover
            focusLock
            autoFocus
            content={() => (
              <Block width="200px" padding="scale300">
                <LabelXSmall
                  $style={{
                    fontWeight: 600,
                    borderBottomWidth: "1px",
                    borderBottomStyle: "dotted",
                    borderBottomColor: theme.colors.inputBorder,
                    paddingBottom: theme.sizing.scale300,
                    marginBottom: theme.sizing.scale500,
                  }}
                >
                  Wyświetlaj kolumny
                </LabelXSmall>

                {headerGroups
                  .map((headerGroup) => {
                    return headerGroup.headers
                      ?.filter((column) => column.id !== "actions")
                      .map((column, index) => {
                        return (
                          <div
                            key={`column-${column.id}`}
                            className={css({
                              display: "flex",
                              alignItems: "center",
                              marginTop: theme.sizing.scale300,
                              borderRadius: "6px",
                              ...(!column.disableGlobalFilter && index !== 0
                                ? {
                                    ":hover": {
                                      backgroundColor: "#f9f9f9",
                                      cursor: "pointer",
                                    },
                                  }
                                : {
                                    cursor: "not-allowed",
                                  }),
                            })}
                            {...(!column.disableGlobalFilter &&
                              index !== 0 && {
                                onClick: () => {
                                  const newVisibleColumns = visibleColumns?.includes(
                                    column.id
                                  )
                                    ? visibleColumns.filter(
                                        (columnId) => columnId !== column.id
                                      )
                                    : [...visibleColumns, column.id];

                                  setVisibleColumns(newVisibleColumns);
                                  setColumnsChanged(true);

                                  updateUser({
                                    interfacePreferences: setPreferencedColumns(
                                      interfacePreferences,
                                      pathname,
                                      newVisibleColumns
                                    ) as JSON,
                                  });
                                },
                              })}
                          >
                            <div
                              className={css({
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: visibleColumns?.includes(
                                  column.id
                                )
                                  ? theme.colors.primary
                                  : "#eee",
                                padding: "4px",
                                borderRadius: "6px",
                                marginRight: "8px",
                                flexShrink: 0,
                              })}
                            >
                              {visibleColumns?.includes(column.id) ? (
                                column.disableGlobalFilter || index === 0 ? (
                                  <EyeCheck size={14} color="white" />
                                ) : (
                                  <Eye size={14} color="white" />
                                )
                              ) : (
                                <EyeOff size={14} />
                              )}
                            </div>

                            <LabelXSmall>
                              {textContent(
                                column.render("Header") as ReactElement
                              )}
                            </LabelXSmall>
                          </div>
                        );
                      });
                  })
                  .flat()}

                {!isEqual(visibleColumns, defaultColumns) && (
                  <LabelXSmall
                    display="flex"
                    alignItems="flex-start"
                    $style={{
                      fontWeight: 400,
                      borderTopWidth: "1px",
                      borderTopStyle: "dotted",
                      borderTopColor: theme.colors.inputBorder,
                      paddingTop: theme.sizing.scale300,
                      marginTop: theme.sizing.scale500,
                    }}
                  >
                    <div
                      className={css({
                        flexShrink: 0,
                        marginTop: "3px",
                        marginRight: "8px",
                      })}
                    >
                      {columnsChanged ? (
                        <DeviceFloppy size={14} color={theme.colors.positive} />
                      ) : (
                        <CircleCheck size={14} color={theme.colors.positive} />
                      )}
                    </div>

                    <span>
                      {columnsChanged
                        ? "Zapisano preferencje użytkownika. "
                        : "Wczytano preferencje użytkownika. "}
                      <StyledLink
                        $as={"span"}
                        $style={{ cursor: "pointer" }}
                        onClick={() => {
                          setVisibleColumns(defaultColumns);

                          updateUser({
                            interfacePreferences: setPreferencedColumns(
                              interfacePreferences,
                              pathname,
                              undefined
                            ) as JSON,
                          });
                        }}
                      >
                        Przywróć domyślne
                      </StyledLink>
                    </span>
                  </LabelXSmall>
                )}
              </Block>
            )}
            placement="bottom"
          >
            <span>
              <Tooltip content="Wyświetlaj kolumny">
                <ForwardedButton
                  kind="secondary"
                  size="mini"
                  startEnhancer={<TableIcon size={18} />}
                  disabled={isFetching}
                />
              </Tooltip>
            </span>
          </Popover>
        </div>
      )}

      <TableHead tableRef={tableRef} compact={compact} />

      <BaseTable
        data={($rows && $rows(rows, prepareRow)) || tableRows}
        columns={tableColumns}
        emptyMessage={() => (
          <div
            key="error"
            className={css({
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#999999",
            })}
          >
            <ListSearch
              color="#999999"
              size={18}
              className={css({ marginRight: "5px" })}
            />
            Brak rekordów
          </div>
        )}
        overrides={{
          Root: {
            props: {
              ref: tableRef,
              "data-ui": "table",
              onScroll: (event: any) => {
                const tableRoot = event.target?.closest("[data-ui=table-root]");

                if (tableRoot) {
                  const stickedTableHead = tableRoot?.querySelector(
                    "[data-ui=sticked-table-head]"
                  );

                  if (stickedTableHead) {
                    const scrolling =
                      tableRoot.getAttribute("data-scrolling") === "true"
                        ? true
                        : false;

                    if (scrolling) {
                      tableRoot.setAttribute("data-scrolling", "false");
                      return;
                    }

                    if (
                      stickedTableHead.scrollLeft !==
                      (event.target as HTMLElement).scrollLeft
                    ) {
                      tableRoot.setAttribute("data-scrolling", "true");
                      stickedTableHead.scrollLeft = (event.target as HTMLElement).scrollLeft;
                    }
                  }
                }
              },
            },
          },
          Table: {
            component: ({ $style, children }: any) => {
              return (
                <table
                  id={id}
                  className={css({
                    maxWidth: "100%",
                    overflowX: "auto",
                    scrollSnapType: "x mandatory",
                    ...$style,
                  })}
                >
                  {children}
                  {$footer && (
                    <tfoot
                      className={css({
                        position: "relative",
                        borderTopWidth: "1px",
                        borderTopStyle: "solid",
                        borderTopColor: "rgb(238, 238, 238)",
                        backgroundColor: "rgb(252, 252, 252)",
                      })}
                    >
                      {$footer()}
                    </tfoot>
                  )}
                </table>
              );
            },
            style: {
              position: "relative",
              borderCollapse: "collapse",
              minWidth: "100%",
              boxSizing: "border-box",
              ...getTableProps()?.style,
            },
          },
          TableBody: {
            style: { ...getTableBodyProps()?.style },
          },
          TableHeadCell: {
            style: {
              zIndex: 12,
              fontSize: "13.5px",
              paddingLeft: "10px",
              paddingRight: "10px",
              whiteSpace: scrollable ? "nowrap" : "pre-wrap",
            },
            props: {
              onMouseEnter: highlightColumn,
              onMouseLeave: unhighlightColumn,
            },
          },
          TableBodyCell: {
            style: {
              position: "relative",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              verticalAlign: "middle",
              height: "40px",
              paddingLeft: "10px",
              paddingRight: "10px",
              fontSize: "12px",
              borderRightStyle: "solid",
              borderRightWidth: "0.5px",
              borderRightColor: theme.colors.inputBorder,
              scrollSnapAlign: "start",
              ":last-of-type": {
                borderRightWidth: "0px",
                ...(stickLastColumn && {
                  position: "sticky",
                  right: 0,
                }),
              },
              ":first-of-type": {
                wordBreak: "break-all",
              },
            },
          },
          TableBodyRow: {
            style: () => ({
              ":hover": {
                backgroundColor: "#fbfbfb",
              },
              ...(stickLastColumn && {
                ":hover [data-ui=actions]": {
                  opacity: "100%",
                },
              }),
              borderBottomStyle: "solid",
              borderBottomWidth: "0.5px",
              borderBottomColor: theme.colors.inputBorder,
              ":last-of-type": {
                borderBottomWidth: "0px",
              },
              ...$tableBodyRowStyle,
            }),
          },
          TableLoadingMessage: {
            component: () => {
              return (
                <Fragment>
                  {[...Array(pageSize || PAGE_SIZE)].map((number, index) => (
                    <div
                      key={`skeleton-${index}`}
                      className={css({
                        borderBottomStyle: "solid",
                        borderBottomWidth: "0.5px",
                        borderBottomColor: theme.colors.inputBorder,
                      })}
                    >
                      <Skeleton height="40px" animation />
                    </div>
                  ))}
                </Fragment>
              );
            },
          },
        }}
        {...props}
      />
    </div>
  );
}

export const MemoizedTable = memo(Table);
