import { ApolloError, useMutation } from "@apollo/client";
import { KIND } from "baseui/button";
import { ConfirmDialog } from "components/confirm-dialog";
import { Content } from "components/content";
import { FormRenderer } from "components/form-renderer";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { Role } from "containers/Roles/roles";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { DeviceFloppy, FileOff, Note } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";

import { USERS_FIELDS, UsersFormInputs } from "../users.form";
import { USERS_CREATE } from "../users.gql";

export default function UsersCreate(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const [isClearConfirmDialogOpen, setIsClearConfirmDialogOpen] = useState(
    false
  );
  const { enqueueSnackbar } = useSnackbar();
  const { checkPermission } = useAuth();
  const { isLoading, setIsLoading } = useLoading();
  const history = useHistory();

  const {
    control,
    formState: { errors, isDirty, ...formState },
    handleSubmit,
    reset,
    watch,
    ...methods
  } = useForm<UsersFormInputs>({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      username: "",
      roles: [],
    },
  });

  const [createUser, { error }] = useMutation(USERS_CREATE);

  const onSubmit = async ({
    roles,
    firstName,
    lastName,
    username,
    ...values
  }: UsersFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      const { data } = await createUser({
        variables: {
          userCreateInput: {
            roleIds: roles ? roles.map(({ id }: Role) => id) : [],
            ...(!!firstName && {
              firstName,
            }),
            ...(!!lastName && {
              lastName,
            }),
            ...(!!username && {
              username,
            }),
            ...values,
          },
        },
      });

      enqueueSnackbar({
        message: "Utworzono pomyślnie",
        variant: "success",
      });

      history.push(`/users/${data?.userCreate?.id}`);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!checkPermission(PERMISSIONS.user.create))
    return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title="Nowy użytkownik"
        labels={["Użytkownicy", "Tworzenie"]}
        goBackOption
        buttons={[
          {
            label: "Wyczyść",
            kind: KIND.secondary,
            startEnhancer: <Note size={18} />,
            disabled: isLoading,
            onClick: () => {
              setIsClearConfirmDialogOpen(true);
            },
          },
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Utwórz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit),
            type: "submit",
            disabled: isLoading,
            isLoading: isLoading,
            formId: "createUser",
          },
        ]}
      />
      <Content>
        <FormProvider<UsersFormInputs>
          control={control}
          formState={{ errors, isDirty, ...formState }}
          handleSubmit={handleSubmit}
          reset={reset}
          watch={watch}
          {...methods}
        >
          <FormRenderer<UsersFormInputs>
            id="createUser"
            onSubmit={handleSubmit(onSubmit)}
            type="create"
            isLoading={isLoading}
            error={error}
            fields={USERS_FIELDS}
          />
        </FormProvider>
        <ConfirmDialog
          isOpen={isCancelConfirmDialogOpen}
          label="Anulowanie tworzenia użytkownika"
          close={() => setIsCancelConfirmDialogOpen(false)}
          confirm={() => history.goBack()}
        />
        <ConfirmDialog
          isOpen={isClearConfirmDialogOpen}
          label="Wyczyszczenie formularza"
          close={() => setIsClearConfirmDialogOpen(false)}
          confirm={() => {
            reset();
            setIsClearConfirmDialogOpen(false);
          }}
        />
      </Content>
    </article>
  );
}
