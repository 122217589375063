import { BasicFilter, FilterType } from "./../../filters.d";
import { CategoriesField } from "./categories.form";

export const CATEGORIES_FILTERS: BasicFilter[] = [
  {
    id: CategoriesField.Name,
    label: "Nazwa",
    type: FilterType.Text,
  },
  {
    id: CategoriesField.CreatedAt,
    label: "Data utworzenia",
    type: FilterType.Date,
  },
  {
    id: CategoriesField.Kind,
    label: "Funkcja",
    type: FilterType.CategoryKind,
  },
  {
    id: CategoriesField.IsVisible,
    label: "Widoczność",
    type: FilterType.Boolean,
  },
  {
    id: CategoriesField.IsFeatured,
    label: "Wyróżnienie",
    type: FilterType.Boolean,
  },
  {
    id: CategoriesField.Position,
    label: "Pozycja na stronie głównej",
    type: FilterType.Integer,
  },
  {
    id: CategoriesField.ShowInShortcuts,
    label: "Skrót",
    type: FilterType.Boolean,
  },
  {
    id: CategoriesField.ShortcutPosition,
    label: "Pozycja w skrótach",
    type: FilterType.Integer,
  },
  {
    id: CategoriesField.IsAvailableInFilters,
    label: "Filtry",
    type: FilterType.Boolean,
  },
];
