import { BasicFilter, FilterType } from "./../../filters.d";
import { SlidesField } from "./slides.form";

export const SLIDES_FILTERS: BasicFilter[] = [
  {
    id: SlidesField.Title,
    label: "Tytuł",
    type: FilterType.Text,
  },
  {
    id: SlidesField.Url,
    label: "Adres strony WWW",
    type: FilterType.Text,
  },
  {
    id: SlidesField.CreatedAt,
    label: "Utworzenie",
    type: FilterType.Date,
  },
  {
    id: SlidesField.CreatedBy,
    label: "Utworzono przez",
    type: FilterType.CreatedBy,
  },
  {
    id: SlidesField.UpdatedAt,
    label: "Ostatnia aktualizacja",
    type: FilterType.Date,
  },
  {
    id: SlidesField.UpdatedBy,
    label: "Zaktualizowano przez",
    type: FilterType.UpdatedBy,
  },
  {
    id: SlidesField.IsVisible,
    label: "Widoczność",
    type: FilterType.Boolean,
  },
];
