import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { Cell } from "components/cell";
import { ConfirmDialog } from "components/confirm-dialog";
import { Content } from "components/content";
import { FormControl } from "components/form-control";
import { FormRenderer } from "components/form-renderer";
import { DataType, FormattedValue } from "components/formatted-value";
import { Grid } from "components/grid";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { DeviceFloppy, FileOff } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";

import { Typename } from "../../../constants";
import { SeoTagset } from "../seo-tagsets";
import { SEO_TAGSETS_FIELDS, SeoTagsetsFormInputs } from "../seo-tagsets.form";
import { SEO_TAGSETS_SHOW, SEO_TAGSETS_UPDATE } from "../seo-tagsets.gql";

export default function SeoTagsetsUpdate(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const { enqueueSnackbar } = useSnackbar();
  const { isFetching, setIsFetching, isLoading, setIsLoading } = useLoading();
  const { checkPermission } = useAuth();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const [css] = useStyletron();

  const {
    control,
    formState: { errors, isDirty, ...formState },
    handleSubmit,
    setValue,
    reset,
    watch,
    ...methods
  } = useForm<SeoTagsetsFormInputs>({ mode: "onChange" });

  const { refetch, data, error: queryError } = useQuery(SEO_TAGSETS_SHOW, {
    variables: { id: id ? parseInt(id) : null },
  });
  const seoTagset: SeoTagset = data?.seoTagset;

  useEffect(() => {
    setIsFetching(true);
    if (data?.seoTagset) refetch();
  }, []);

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  useEffect(() => {
    if (seoTagset) {
      setIsFetching(false);
      setValue("metaTitle", seoTagset?.metaTitle);
      setValue("metaDescription", seoTagset?.metaDescription);
      setValue("ogTitle", seoTagset?.ogTitle);
      setValue("ogDescription", seoTagset?.ogDescription);
      setValue("metaKeywords", seoTagset?.metaKeywords);
      setValue("canonical", seoTagset?.canonical);
      !!seoTagset?.robots &&
        setValue("robots", [
          {
            id: seoTagset?.robots,
            label: seoTagset?.robots,
          },
        ]);
    }
  }, [data]);

  const [updateSeoTagset, { error }] = useMutation(SEO_TAGSETS_UPDATE);

  const onSubmit = async ({
    robots,
    ...values
  }: SeoTagsetsFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await updateSeoTagset({
        variables: {
          seoTagsetUpdateInput: {
            id: id ? parseInt(id) : null,
            robots: robots?.length ? robots?.[0]?.label : null,
            ...values,
          },
        },
      });
      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });
      history.push(`/seo-tagsets/${id}`);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubject = (typename: string) => {
    switch (typename) {
      case Typename.Article:
        return (
          <FormattedValue
            dataType={DataType.Articles}
            data={seoTagset?.subject?.id}
            deletedAt={seoTagset?.subject?.deletedAt}
          >
            {seoTagset?.subject?.name}
          </FormattedValue>
        );
      case Typename.Brand:
        return (
          <FormattedValue
            dataType={DataType.Brands}
            data={seoTagset?.subject?.id}
            deletedAt={seoTagset?.subject?.deletedAt}
          >
            {seoTagset?.subject?.name}
          </FormattedValue>
        );
      case Typename.Recipe:
        return (
          <FormattedValue
            dataType={DataType.Recipes}
            data={seoTagset?.subject?.id}
            deletedAt={seoTagset?.subject?.deletedAt}
          >
            {seoTagset?.subject?.name}
          </FormattedValue>
        );
      case Typename.StaticPage:
        return (
          <FormattedValue
            dataType={DataType.StaticPages}
            data={seoTagset?.subject?.id}
            deletedAt={seoTagset?.subject?.deletedAt}
          >
            {seoTagset?.subject?.name}
          </FormattedValue>
        );
      default:
        <FormattedValue />;
    }
  };

  if (!checkPermission(PERMISSIONS.seoTagset.update))
    return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title={`Tag SEO ${seoTagset?.id}`}
        labels={["Tagi SEO", "Edytowanie"]}
        goBackOption
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "updateSeoTagset",
          },
        ]}
      />
      <Content>
        <Grid>
          <Cell span={12}>
            <LabelMedium marginBottom="scale200" marginTop="scale600">
              Obiekt
            </LabelMedium>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          <Cell span={6}>
            <FormControl label="Nazwa">
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <>{handleSubject(seoTagset?.subjectType)}</>
              )}
            </FormControl>
          </Cell>
          <Cell span={6}>
            <FormControl label="Typ">
              {isFetching ? (
                <Skeleton rows={0} height="20px" width="100%" animation />
              ) : (
                <FormattedValue dataType={DataType.Subject}>
                  {seoTagset?.subjectType}
                </FormattedValue>
              )}
            </FormControl>
          </Cell>
        </Grid>
        <FormProvider<SeoTagsetsFormInputs>
          control={control}
          formState={{ errors, isDirty, ...formState }}
          handleSubmit={handleSubmit}
          reset={reset}
          setValue={setValue}
          watch={watch}
          {...methods}
        >
          <FormRenderer<SeoTagsetsFormInputs>
            id="updateSeoTagset"
            onSubmit={handleSubmit(onSubmit)}
            type="update"
            isLoading={isLoading}
            error={error}
            fields={SEO_TAGSETS_FIELDS}
          />
        </FormProvider>
        <ConfirmDialog
          isOpen={isCancelConfirmDialogOpen}
          label="Anulowanie edycji tagów SEO"
          close={() => setIsCancelConfirmDialogOpen(false)}
          confirm={() => history.goBack()}
        />
      </Content>
    </article>
  );
}
