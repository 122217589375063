import { gql } from "@apollo/client";

export const TAGS_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $filter: TagFilter
    $sorting: [TagSort!]
  ) {
    tags(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        name
        createdAt
        updatedAt
        isVisible
        slug
      }
    }
  }
`;

export const TAGS_SHOW = gql`
  query TagShow($id: Int!) {
    tag(id: $id) {
      id
      name
      slug
      isVisible
      createdAt
      updatedAt
    }
  }
`;

export const TAGS_CREATE = gql`
  mutation TagCreate($tagCreateInput: TagCreateInput!) {
    tagCreate(tagCreateInput: $tagCreateInput) {
      id
    }
  }
`;

export const TAGS_UPDATE = gql`
  mutation TagUpdate($tagUpdateInput: TagUpdateInput!) {
    tagUpdate(tagUpdateInput: $tagUpdateInput) {
      id
    }
  }
`;

export const TAGS_DELETE = gql`
  mutation TagDelete($tagDeleteInput: TagDeleteInput!) {
    tagDelete(tagDeleteInput: $tagDeleteInput) {
      id
    }
  }
`;
