import { gql } from "@apollo/client";

export const ACTIVITY_LOGS_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $sorting: [ActivityLogSort!]
    $filter: ActivityLogFilter
  ) {
    activityLogs(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      nodes {
        id
        activity
        description
        properties
        createdAt
        user {
          id
          firstName
          lastName
          deletedAt
        }
        subject {
          __typename
          ... on Allergen {
            id
            name
            deletedAt
          }
          ... on Article {
            id
            name
            deletedAt
          }
          ... on Avatar {
            id
            name
          }
          ... on Brand {
            id
            name
            deletedAt
          }
          ... on Category {
            id
            name
            deletedAt
          }
          ... on Dictionary {
            name
            id
            systemName
          }
          ... on DictionaryValue {
            id
            name
            dictionaryId
            dictionary {
              name
            }
          }
          ... on Product {
            id
            name
            deletedAt
          }
          ... on Recipe {
            id
            name
            deletedAt
          }
          ... on Role {
            id
            name
          }
          ... on SeoTagset {
            id
            subjectType
            subject {
              ... on Article {
                id
                name
              }
              ... on Brand {
                id
                name
              }
              ... on Recipe {
                id
                name
              }
              ... on StaticPage {
                id
                name
              }
            }
          }
          ... on Slide {
            id
            title
          }
          ... on StaticPage {
            id
            name
            deletedAt
          }
          ... on Tag {
            id
            name
            deletedAt
          }
          ... on User {
            id
            firstName
            lastName
            deletedAt
            username
            email
          }
        }
      }
      totalCount
    }
  }
`;

export const ACTIVITY_LOGS_SHOW = gql`
  query($id: Int!) {
    activityLog(id: $id) {
      id
      activity
      description
      properties
      user {
        id
        id
        firstName
        lastName
        deletedAt
      }
      properties
      createdAt
      subject {
        __typename
        ... on Allergen {
          id
          name
          deletedAt
        }
        ... on Article {
          id
          name
          deletedAt
        }
        ... on Avatar {
          id
          name
        }
        ... on Brand {
          id
          name
          deletedAt
        }
        ... on Category {
          id
          name
          deletedAt
        }
        ... on Dictionary {
          name
          id
          systemName
        }
        ... on DictionaryValue {
          id
          name
          dictionaryId
          dictionary {
            name
          }
        }
        ... on Product {
          id
          name
          deletedAt
        }
        ... on Recipe {
          id
          name
          deletedAt
        }
        ... on Role {
          id
          name
        }
        ... on SeoTagset {
          id
          subjectType
          subject {
            ... on Article {
              id
              name
            }
            ... on Brand {
              id
              name
            }
            ... on Recipe {
              id
              name
            }
            ... on StaticPage {
              id
              name
            }
          }
        }
        ... on Slide {
          id
          title
        }
        ... on StaticPage {
          id
          name
          deletedAt
        }
        ... on Tag {
          id
          name
          deletedAt
        }
        ... on User {
          id
          firstName
          lastName
          deletedAt
          username
          email
        }
      }
    }
  }
`;

export const ACTIVITY_LOGS_EXPORT = gql`
  mutation ActivityLogsExport(
    $filter: ActivityLogFilter
    $sorting: [ActivityLogSort!]
  ) {
    activityLogsExportToXlsx(
      paging: { limit: 100000 }
      sorting: $sorting
      filter: $filter
    )
  }
`;
