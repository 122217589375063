import { ApolloError } from "@apollo/client";
import { InputValidationError } from "api";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { ControlledAuthorSelector } from "components/author-selector";
import { Cell } from "components/cell";
import { ControlledCheckbox } from "components/checkbox";
import { ControlledColorPicker } from "components/color-picker";
import { ControlledEditor } from "components/editor";
import { FormControl } from "components/form-control";
import { ControlledPermissionsTable } from "components/form-table/permissions-table";
import { Grid } from "components/grid";
import { ControlledImageCropper } from "components/image-cropper";
import { ControlledInput, SlugInput } from "components/input";
import { ControlledRecipeIngredientsComposer } from "components/recipe-ingredients-composer";
import { ControlledRecipeStepsComposer } from "components/recipe-steps-composer";
import {
  ControlledBrandsSelect,
  ControlledCategoriesSelect,
  ControlledCategoryKindSelect,
  ControlledDifficultySelect,
  ControlledRolesSelect,
  ControlledStaticPageSystemNamesSelect,
  ControlledTagsSelect,
  ControlledUsersSelect,
} from "components/select";
import ControlledAllergensSelect from "components/select/allergens-select";
import { ControlledDictionaryValuesSelect } from "components/select/dictionary-values-select";
import { ControlledSlideVariantSelector } from "components/slide-variant-selector/slide-variant-selector";
import { ControlledSocialMediaComposer } from "components/social-media-composer/social-media-composer";
import { ControlledSwitch } from "components/switch/switch";
import { ControlledTextArea } from "components/text-area";
import { EditorProvider } from "contexts/editor-context";
import { useLoading } from "contexts/loading-context";
import { Field, FieldsGroup, FieldType } from "fields.d";
import { FormErrors } from "form";
import React, { FormEventHandler, ReactElement } from "react";
import { FieldError, useFormContext } from "react-hook-form";
import { AlertOctagon } from "tabler-icons-react";
import { FORM_VALIDATION_MESSAGES } from "utils/form/validation-messages";
import { FORM_VALIDATION_PATTERNS } from "utils/form/validation-patterns";

import {
  BANNED_STATIC_PAGES_SLUGS,
  INPUT_VALIDATION_ERROR,
} from "../../constants";
import { DataType } from "../formatted-value/formatted-value";

type Props = {
  id: string;
  onSubmit: FormEventHandler<HTMLFormElement>;
  fields: FieldsGroup[];
  type: "update" | "create";
  error?: ApolloError;
  isLoading?: boolean;
  shouldHideFields?: boolean;
};

export const XL_SKELETON = [
  FieldType.ImageCropper,
  FieldType.Image,
  FieldType.Editor,
];

export const L_SKELETON = [
  FieldType.SlideVariant,
  FieldType.Author,
  FieldType.TextArea,
];

export const M_SKELETON = [FieldType.BigInput];

export default function FormRenderer<T>({
  id,
  onSubmit,
  fields,
  type,
  error,
  isLoading,
  shouldHideFields,
}: Props): React.ReactElement {
  const [css] = useStyletron();

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { isFetching } = useLoading();

  function renderField(field: Field): ReactElement {
    switch (field.type) {
      case FieldType.AllergensSelect:
        return (
          <ControlledAllergensSelect
            control={control}
            name={field.id}
            disabled={field.readonly}
            {...(field[type].required && {
              rules: {
                required: FORM_VALIDATION_MESSAGES.required,
              },
            })}
          />
        );

      case FieldType.Author:
        return (
          <ControlledAuthorSelector
            control={control}
            name={field.id}
            {...(field[type].required && {
              rules: {
                required: FORM_VALIDATION_MESSAGES.required,
              },
            })}
          />
        );

      case FieldType.BrandsSelect:
        return (
          <ControlledBrandsSelect
            control={control}
            name={field.id}
            disabled={field.readonly}
            {...(field[type].required && {
              rules: {
                required: FORM_VALIDATION_MESSAGES.required,
              },
            })}
          />
        );

      case FieldType.CategoriesSelect:
        return (
          <ControlledCategoriesSelect
            control={control}
            name={field.id}
            disabled={field.readonly}
            onlyParents={field.info?.includes("parents")}
            {...(field[type].required && {
              rules: {
                required: FORM_VALIDATION_MESSAGES.required,
              },
            })}
          />
        );

      case FieldType.CategoryKindSelect:
        return (
          <ControlledCategoryKindSelect
            control={control}
            name={field.id}
            disabled={field.readonly}
            {...(field[type].required && {
              rules: {
                required: FORM_VALIDATION_MESSAGES.required,
              },
            })}
          />
        );

      case FieldType.Switch:
        return <ControlledSwitch control={control} name={field.id} />;

      case FieldType.Checkbox:
        return (
          <Block marginTop="35px">
            <ControlledCheckbox
              control={control}
              name={field.id}
              disabled={isLoading}
            >
              {field.label}
            </ControlledCheckbox>
          </Block>
        );

      case FieldType.ColorPicker:
        return (
          <ControlledColorPicker
            name={field.id}
            control={control}
            {...(field[type].required && {
              rules: {
                required: FORM_VALIDATION_MESSAGES.required,
              },
            })}
          />
        );

      case FieldType.DictionaryValuesSelect:
        return (
          <ControlledDictionaryValuesSelect
            control={control}
            name={field.id}
            disabled={field.readonly}
            systemName={field.dictionarySystemName}
            {...(field[type].required && {
              rules: {
                required: FORM_VALIDATION_MESSAGES.required,
              },
            })}
          />
        );

      case FieldType.DifficultySelect:
        return (
          <ControlledDifficultySelect
            control={control}
            name={field.id}
            disabled={field.readonly}
            {...(field[type].required && {
              rules: {
                required: FORM_VALIDATION_MESSAGES.required,
              },
            })}
          />
        );

      case FieldType.Editor:
        return (
          <EditorProvider>
            <ControlledEditor control={control} name={field.id} />
          </EditorProvider>
        );

      case FieldType.ImageCropper:
        return (
          <ControlledImageCropper
            name={field.id}
            control={control}
            aspect={field.imageRequirements?.aspect}
            rounded={field.imageRequirements?.rounded}
            allowVectors={field.imageRequirements?.allowVectors}
            {...(field[type].required && {
              rules: {
                required: FORM_VALIDATION_MESSAGES.unsavedPhoto,
              },
            })}
          />
        );

      case FieldType.PermissionsTable:
        return (
          <ControlledPermissionsTable
            control={control}
            name={field.id}
            {...(field[type].required && {
              rules: {
                required: FORM_VALIDATION_MESSAGES.requiredPermission,
              },
            })}
          />
        );

      case FieldType.RecipeIngredients:
        return (
          <ControlledRecipeIngredientsComposer
            control={control}
            disabled={isLoading}
            name={field.id}
            {...(field[type].required && {
              rules: {
                required: FORM_VALIDATION_MESSAGES.required,
              },
            })}
          />
        );

      case FieldType.RecipeSteps:
        return (
          <ControlledRecipeStepsComposer
            control={control}
            name={field.id}
            {...(field[type].required && {
              rules: {
                required: FORM_VALIDATION_MESSAGES.required,
              },
            })}
          />
        );

      case FieldType.RolesMultiSelect:
        return (
          <ControlledRolesSelect
            control={control}
            name={field.id}
            disabled={field.readonly}
            multi
            {...(field[type].required && {
              rules: {
                required: FORM_VALIDATION_MESSAGES.required,
              },
            })}
          />
        );

      case FieldType.RolesSelect:
        return (
          <ControlledRolesSelect
            control={control}
            name={field.id}
            disabled={field.readonly}
            {...(field[type].required && {
              rules: {
                required: FORM_VALIDATION_MESSAGES.required,
              },
            })}
          />
        );

      case FieldType.SlideVariant:
        return (
          <ControlledSlideVariantSelector
            control={control}
            name={field.id}
            {...(field[type].required && {
              rules: {
                required: FORM_VALIDATION_MESSAGES.required,
              },
            })}
          />
        );

      case FieldType.SocialMedia:
        return (
          <ControlledSocialMediaComposer
            control={control}
            name={field.id}
            {...(field[type].required && {
              rules: {
                required: FORM_VALIDATION_MESSAGES.required,
              },
            })}
          />
        );

      case FieldType.StaticPageSystemName:
        return (
          <ControlledStaticPageSystemNamesSelect
            control={control}
            name={field.id}
            disabled={field.readonly}
            {...(field[type].required && {
              rules: {
                required: FORM_VALIDATION_MESSAGES.required,
              },
            })}
          />
        );

      case FieldType.TagsSelect:
        return (
          <ControlledTagsSelect
            control={control}
            name={field.id}
            disabled={field.readonly}
            {...(field[type].required && {
              rules: {
                required: FORM_VALIDATION_MESSAGES.required,
              },
            })}
          />
        );

      case FieldType.TextArea:
        return (
          <ControlledTextArea
            control={control}
            name={field.id}
            placeholder={field.placeholder}
            disabled={field.readonly}
            rules={{
              ...(field[type].required && {
                required: FORM_VALIDATION_MESSAGES.required,
              }),
              ...(!!field.maxLength && {
                maxLength: {
                  value: field.maxLength,
                  message: FORM_VALIDATION_MESSAGES.maxLength,
                },
              }),
            }}
          />
        );

      case FieldType.UsersSelect:
        return (
          <ControlledUsersSelect
            control={control}
            name={field.id}
            disabled={field.readonly}
            {...(field[type].required && {
              rules: {
                required: FORM_VALIDATION_MESSAGES.required,
              },
            })}
          />
        );

      case FieldType.SlugInput:
        return (
          <SlugInput
            control={control}
            formType={type}
            name={field.id}
            placeholder={field.placeholder}
            disabled={field.readonly}
            rules={{
              ...(field[type].required && {
                required: FORM_VALIDATION_MESSAGES.required,
              }),
              ...(field.dataType === DataType.Email && {
                pattern: {
                  value: FORM_VALIDATION_PATTERNS.email,
                  message: FORM_VALIDATION_MESSAGES.incorrectEmail,
                },
              }),
              ...(field.info === "staticPageSlug" && {
                validate: (value) => {
                  return BANNED_STATIC_PAGES_SLUGS.some((item: string) =>
                    value.includes(item)
                  )
                    ? FORM_VALIDATION_MESSAGES.bannedWords
                    : true;
                },
              }),
            }}
          />
        );

      case FieldType.BigInput:
        return (
          <ControlledInput
            control={control}
            name={field.id}
            placeholder={field.placeholder}
            disabled={field.readonly}
            endEnhancer={field.endEnhancer}
            autoComplete={field.autocomplete}
            size="default"
            rules={{
              ...(field[type].required && {
                required: FORM_VALIDATION_MESSAGES.required,
              }),
              ...(field.dataType === DataType.Email && {
                pattern: {
                  value: FORM_VALIDATION_PATTERNS.email,
                  message: FORM_VALIDATION_MESSAGES.incorrectEmail,
                },
              }),
              ...(!!field.maxLength && {
                maxLength: {
                  value: field.maxLength,
                  message: `${FORM_VALIDATION_MESSAGES.maxLength} (${field.maxLength})`,
                },
              }),
            }}
          />
        );

      default:
        return (
          <ControlledInput
            control={control}
            name={field.id}
            placeholder={field.placeholder}
            disabled={field.readonly}
            endEnhancer={field.endEnhancer}
            {...(field.type === "number-input" && {
              type: "number",
            })}
            rules={{
              ...(field[type].required && {
                required: FORM_VALIDATION_MESSAGES.required,
              }),
              ...(field.dataType === DataType.Email && {
                pattern: {
                  value: FORM_VALIDATION_PATTERNS.email,
                  message: FORM_VALIDATION_MESSAGES.incorrectEmail,
                },
              }),
              ...(!!field.maxLength && {
                maxLength: {
                  value: field.maxLength,
                  message: FORM_VALIDATION_MESSAGES.maxLength,
                },
              }),
            }}
          />
        );
    }
  }

  return (
    <form id={id} onSubmit={onSubmit}>
      <Grid>
        {fields
          .filter((g) => g.fields.filter((f) => f[type].visible).length > 0)
          .map((group) => [
            group.label &&
              (!shouldHideFields ||
                (shouldHideFields &&
                  group.fields.some((field) => !field.optionallyHidden))) && (
                <Cell key={group.id + `-group`} span={12}>
                  <LabelMedium marginBottom="scale200" marginTop="scale600">
                    {group.label}
                  </LabelMedium>
                  <hr
                    className={css({
                      borderWidth: "0px",
                      height: "1px",
                      backgroundColor: "#eee",
                    })}
                  />
                </Cell>
              ),
            group.fields
              .filter((f) => f[type].visible)
              .map((item, index) => {
                if (
                  (shouldHideFields && !item.optionallyHidden) ||
                  !shouldHideFields
                )
                  return (
                    <Cell
                      span={item.span || 6}
                      key={group.id + `-field` + index}
                    >
                      <FormControl
                        label={
                          item.type === FieldType.Checkbox ? "" : item.label
                        }
                        caption={item.caption}
                        required={item[type].required}
                        error={
                          ((errors as FormErrors<T>)[item.id as keyof T] &&
                            ((errors as FormErrors<T>)[
                              item.id as keyof T
                            ] as FieldError)?.message) ||
                          (error &&
                            error.graphQLErrors[0]?.extensions?.code ===
                              INPUT_VALIDATION_ERROR &&
                            error.graphQLErrors[0]?.extensions?.validationErrors
                              ?.find(
                                (vE: InputValidationError) =>
                                  vE?.property === item.id
                              )
                              ?.errors.map((message: string) => (
                                <div
                                  key="error"
                                  className={css({
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  })}
                                >
                                  {message}
                                  <AlertOctagon color="#999" size={12} />
                                </div>
                              ))[0])
                        }
                        disabled={isLoading}
                      >
                        {isFetching ? (
                          <Skeleton
                            rows={0}
                            height={
                              XL_SKELETON.includes(item.type as FieldType)
                                ? "256px"
                                : L_SKELETON.includes(item.type as FieldType)
                                ? "128px"
                                : M_SKELETON.includes(item.type as FieldType)
                                ? "44px"
                                : "32px"
                            }
                            width="100%"
                            animation
                            {...(item.type === FieldType.Checkbox && {
                              overrides: {
                                Root: {
                                  style: {
                                    marginTop: "32px",
                                  },
                                },
                              },
                            })}
                          />
                        ) : (
                          renderField(item)
                        )}
                      </FormControl>
                    </Cell>
                  );
              }),
          ])}
      </Grid>
    </form>
  );
}
