import { useQuery } from "@apollo/client";
import React, { createContext, useContext } from "react";

import { DictionaryValue } from "../containers/Dictionaries/dictionaries";
import { DICTIONARY_VALUES } from "../containers/Dictionaries/dictionaries.gql";
import { useAuth } from "./auth-context";

type DictionariesContextProps = {
  findValue: (type: string) => void;
  findId: (type: string) => void;
};

export const DictionariesContext = createContext<DictionariesContextProps>(
  {} as DictionariesContextProps
);

export function DictionariesProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const { isAuthenticated } = useAuth();

  const { data } = useQuery(DICTIONARY_VALUES, {
    fetchPolicy: "cache-and-network",
    skip: !isAuthenticated,
  });
  const dictionaryValues: DictionaryValue[] = data?.dictionaryValues;

  const findValue = (type: string) =>
    dictionaryValues?.find(
      (dictionaryValue: DictionaryValue) => dictionaryValue?.value === type
    )?.name;

  const findId = (type: string): number =>
    data?.dictionaryValues?.find(
      (dictionaryValue: DictionaryValue) => dictionaryValue.value === type
    )?.id;

  return (
    <DictionariesContext.Provider
      value={{
        findValue,
        findId,
      }}
    >
      {children}
    </DictionariesContext.Provider>
  );
}

export const useDictionaries = (): DictionariesContextProps =>
  useContext(DictionariesContext);
