import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Input } from "components/input";
import React from "react";
import { HexColorPicker } from "react-colorful";
import { Controller, UseControllerProps } from "react-hook-form";

type Props = {
  color: string;
  setColor: (color: string) => void;
  error?: boolean;
};

export default function ColorPicker({
  color,
  setColor,
  error,
}: Props): React.ReactElement {
  const [css, theme] = useStyletron();
  return (
    <>
      <HexColorPicker
        color={color || "#ffffff"}
        onChange={setColor}
        style={{ width: "100%" }}
      />
      <Block
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginTop="10px"
      >
        <Input
          value={color}
          maxLength={7}
          onChange={(event: React.FormEvent<HTMLInputElement>) => {
            event.stopPropagation();
            setColor(event.currentTarget.value);
          }}
          name="color"
          placeholder="Wybierz lub wpisz"
          error={error}
          overrides={{
            Root: {
              style: () => ({
                borderTopLeftRadius: theme.borders.radius300,
                borderTopRightRadius: theme.borders.radius300,
                borderBottomRightRadius: theme.borders.radius300,
                borderBottomLeftRadius: theme.borders.radius300,
                paddingRight: "5px",
                appearance: "textfield",
                width: "49%",
              }),
            },
            Input: {
              props: {
                onPaste: (event: React.ClipboardEvent) => {
                  event.preventDefault();
                  let data = event.clipboardData.getData("text/plain");
                  data = data.includes("#") ? data : "#" + data;

                  if (typeof data === "string" && data.length === 7) {
                    setColor(data);
                  }
                },
              },
              style: {
                backgroundColor: "transparent",
                ":-webkit-autofill": {
                  WebkitBackgroundClip: "text",
                  backgroundClip: "text",
                },
                paddingRight: 0,
              },
            },
            EndEnhancer: {
              style: {
                fontSize: "12px",
                marginRight: 0,
              },
            },
          }}
        />
        <div
          className={css({
            width: "49%",
            height: "40px",
            backgroundColor: color,
            border: `2px solid ${theme.colors.inputBorder}`,
            borderRadius: theme.borders.radius300,
          })}
        />
      </Block>
    </>
  );
}

export function ControlledColorPicker({
  control,
  name,
  rules,
  error,
  ...rest
}: UseControllerProps<any> & { error?: boolean }): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <ColorPicker
          setColor={(color) => onChange(color)}
          color={value}
          error={error}
          {...rest}
        />
      )}
    />
  );
}
