import { gql } from "@apollo/client";

export const PRODUCTS_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $filter: ProductFilter
    $sorting: [ProductSort!]
  ) {
    products(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        name
        createdAt
        createdBy {
          id
          firstName
          lastName
          username
          email
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
          username
          email
        }
        isVisible
        slug
      }
    }
  }
`;

export const PRODUCTS_SHOW = gql`
  query ProductShow($id: Int!) {
    product(id: $id) {
      id
      name
      slug
      content
      isVisible
      imageUrl
      createdAt
      createdBy {
        id
        firstName
        lastName
        username
        email
      }
      updatedAt
      updatedBy {
        id
        firstName
        lastName
        username
        email
      }
    }
  }
`;

export const PRODUCTS_CREATE = gql`
  mutation ProductCreate($productCreateInput: ProductCreateInput!) {
    productCreate(productCreateInput: $productCreateInput) {
      id
    }
  }
`;

export const PRODUCTS_UPDATE = gql`
  mutation ProductUpdate($productUpdateInput: ProductUpdateInput!) {
    productUpdate(productUpdateInput: $productUpdateInput) {
      id
    }
  }
`;

export const PRODUCTS_DELETE = gql`
  mutation ProductDelete($productDeleteInput: ProductDeleteInput!) {
    productDelete(productDeleteInput: $productDeleteInput) {
      id
    }
  }
`;
