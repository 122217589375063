import { styled, useStyletron } from "baseui";
import { Block } from "baseui/block";
import { SIZE } from "baseui/select";
import { Theme } from "baseui/theme";
import { LabelXSmall } from "baseui/typography";
import { Button, ForwardedButton } from "components/button";
import { Cell } from "components/cell";
import { DatePicker } from "components/date-picker";
import { Grid } from "components/grid";
import { ForwardedInput, Input } from "components/input";
import { Popover } from "components/popover";
import {
  ActivitiesSelect,
  CategoryKindSelect,
  RolesSelect,
  SeoTagsetsSubjectSelect,
  StaticPageSystemNamesSelect,
  UsersSelect,
} from "components/select";
import ActivityLogsSubjectSelect from "components/select/activity-logs-subject-select";
import { User } from "containers/Users/users";
import { useLoading } from "contexts/loading-context";
import {
  BasicFilter,
  FiltersState,
  FilterState,
  FilterType,
  SingleFilter,
} from "filters.d";
import React, {
  ChangeEvent,
  FormEvent,
  Fragment,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router";
import {
  AlertTriangle,
  Calendar,
  Check,
  Checkbox as CheckboxIcon,
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
  CircleCheck,
  CircleX,
  Filter,
  LetterCase,
  Numbers,
  Search,
  Trash,
} from "tabler-icons-react";
import { translateFilterToString } from "utils/filters";
import { generateUUID } from "utils/misc";
import { renderUserLabel } from "utils/render-user-label";

const Container = styled(
  "summary",
  ({ $theme, $sticked }: { $theme?: Theme; $sticked?: boolean }) => ({
    backgroundColor: "white",
    paddingTop: $sticked ? $theme?.sizing.scale300 : $theme?.sizing.scale300,
    paddingBottom: $sticked ? $theme?.sizing.scale300 : $theme?.sizing.scale300,
    position: $sticked ? "sticky" : "absolute",
    top: $sticked ? "72px" : "104px",
    left: "0px",
    right: "0px",
    zIndex: 10,
    borderBottomWidth: $sticked ? "2px" : "0px",
    borderBottomStyle: "solid",
    borderBottomColor: $theme?.colors.backgroundTertiary,
    ...($sticked && {
      marginBottom: "-46px",
      animationIterationCount: "1",
      animationDelay: "0ms",
      animationDuration: "200ms",
      animationFillMode: "both",
      animationName: {
        from: {
          transform: "translateY(-200px)",
        },
        to: {
          transform: "translateY(0px)",
        },
      } as any,
    }),
  })
);

function FilterIcon({
  type,
  size: inheritedSize,
}: {
  type: FilterType;
  size?: number;
}): ReactElement {
  const size = inheritedSize || 14;

  switch (type) {
    case FilterType.Text:
      return <LetterCase size={size} />;

    case FilterType.Date:
      return <Calendar size={size} />;

    case FilterType.Boolean:
      return <Check size={size} />;

    case FilterType.Integer:
      return <Numbers size={size} />;

    default:
      return <Filter size={size} />;
  }
}

type FilterFormProps = {
  type: FilterType;
  filter: BasicFilter;
  state?: FilterState;
  onSubmit?: (state: FilterState) => void;
};

function FilterForm({
  type,
  state: inheritedState,
  onSubmit: inheritedOnSubmit,
}: FilterFormProps): ReactElement {
  const [, theme] = useStyletron();

  const initialState = inheritedState || {
    kind: "single",
  };

  const [state, setState] = useState<FilterState | undefined>(initialState);

  function onSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();

    inheritedOnSubmit && state && inheritedOnSubmit(state);
  }

  function ApplyButton({ isDisabled }: { isDisabled?: boolean }): ReactElement {
    return (
      <Button
        startEnhancer={<CheckboxIcon size={14} />}
        size="mini"
        kind="secondary"
        $style={{ width: "100%", marginTop: "10px" }}
        {...(isDisabled && {
          disabled: true,
        })}
      >
        {inheritedState ? "Zastosuj" : "Dodaj i zastosuj"}
      </Button>
    );
  }

  switch (type) {
    case FilterType.Activity:
      return (
        <form onSubmit={onSubmit}>
          <Block
            marginTop="scale300"
            display="flex"
            backgroundColor="inputBorder"
            overrides={{
              Block: {
                style: {
                  borderTopLeftRadius: theme.borders.radius200,
                  borderTopRightRadius: theme.borders.radius200,
                  borderBottomRightRadius: theme.borders.radius200,
                  borderBottomLeftRadius: theme.borders.radius200,
                },
              },
            }}
          >
            <ActivitiesSelect
              size={SIZE.mini}
              onChange={(params) => {
                setState({
                  ...state,
                  value: params.option?.id,
                  label: params.option?.label,
                });
              }}
              {...(state?.value && {
                value: [{ label: state?.label }],
              })}
            />
          </Block>
          <ApplyButton isDisabled={!state?.value} />
        </form>
      );

    case FilterType.Boolean:
      return (
        <form onSubmit={onSubmit}>
          <Block
            marginTop="scale300"
            display="flex"
            backgroundColor="inputBorder"
            overrides={{
              Block: {
                style: {
                  borderTopLeftRadius: theme.borders.radius200,
                  borderTopRightRadius: theme.borders.radius200,
                  borderBottomRightRadius: theme.borders.radius200,
                  borderBottomLeftRadius: theme.borders.radius200,
                },
              },
            }}
          >
            <Button
              type="button"
              startEnhancer={
                <CircleCheck color={theme.colors.positive} size={14} />
              }
              size="mini"
              kind={!state?.is ? "secondary" : "tertiary"}
              $style={{ flexGrow: 1, flexShrink: 0, width: "50%" }}
              onClick={() => setState({ ...state, is: !state?.is })}
            >
              Tak
            </Button>

            <Button
              type="button"
              startEnhancer={
                <CircleX color={theme.colors.negative} size={14} />
              }
              size="mini"
              kind={state?.is ? "secondary" : "tertiary"}
              $style={{ flexGrow: 1, flexShrink: 0, width: "50%" }}
              onClick={() => setState({ ...state, is: !state?.is })}
            >
              Nie
            </Button>
          </Block>

          <ApplyButton />
        </form>
      );

    case FilterType.HeaderPosition:
      return (
        <form onSubmit={onSubmit}>
          <Input
            size="mini"
            placeholder="Wartość"
            {...(state?.eq && { value: state.eq })}
            required
            onChange={(event) =>
              setState({ ...state, eq: event.currentTarget.value })
            }
            type="number"
            step="1"
          />
          <ApplyButton />
        </form>
      );

    case FilterType.CategoryKind:
      return (
        <form onSubmit={onSubmit}>
          <Block
            marginTop="scale300"
            display="flex"
            backgroundColor="inputBorder"
            overrides={{
              Block: {
                style: {
                  borderTopLeftRadius: theme.borders.radius200,
                  borderTopRightRadius: theme.borders.radius200,
                  borderBottomRightRadius: theme.borders.radius200,
                  borderBottomLeftRadius: theme.borders.radius200,
                },
              },
            }}
          >
            <CategoryKindSelect
              size={SIZE.mini}
              onChange={(params) => {
                setState({
                  ...state,
                  value: params.option?.id,
                  label: params.option?.label,
                });
              }}
              {...(state?.value && {
                value: [{ label: state?.label }],
              })}
            />
          </Block>
          <ApplyButton isDisabled={!state?.value} />
        </form>
      );

    case FilterType.Date:
      return (
        <form onSubmit={onSubmit}>
          <Block display="flex" justifyContent="space-between">
            <Button
              size="mini"
              type="button"
              kind={state?.kind === "single" ? "primary" : "secondary"}
              onClick={() => setState({ ...state, kind: "single" })}
              $style={{
                borderTopRightRadius: "0px",
                borderBottomRightRadius: "0px",
                flexGrow: 1,
                flexShrink: 1,
                marginRight: "0.75px",
              }}
            >
              W dniu
            </Button>

            <Button
              size="mini"
              type="button"
              kind={state?.kind === "between" ? "primary" : "secondary"}
              onClick={() => setState({ ...state, kind: "between" })}
              $style={{
                borderTopLeftRadius: "0px",
                borderBottomLeftRadius: "0px",
                flexGrow: 1,
                flexShrink: 1,
                marginLeft: "0.75px",
              }}
            >
              Od — Do
            </Button>
          </Block>

          {state?.kind === "between" ? (
            <Fragment>
              <Block marginTop="scale300">
                <DatePicker
                  size="mini"
                  placeholder="Data od"
                  {...(state?.to && { maxDate: new Date(state.to) })}
                  {...(state?.from && { value: new Date(state.from) })}
                  setDate={(date) => setState({ ...state, from: date })}
                />
              </Block>

              <Block marginTop="scale300">
                <DatePicker
                  size="mini"
                  placeholder="Data do"
                  {...(state?.from && { minDate: new Date(state.from) })}
                  {...(state?.to && { value: new Date(state.to) })}
                  setDate={(date) => setState({ ...state, to: date })}
                />
              </Block>
            </Fragment>
          ) : (
            <Block marginTop="scale300">
              <DatePicker
                size="mini"
                placeholder="W dniu"
                {...(state?.in && { value: new Date(state.in) })}
                required
                setDate={(date) => setState({ ...state, in: date })}
              />
            </Block>
          )}

          <ApplyButton
            isDisabled={
              state?.kind === "between"
                ? !state?.from || !state?.to
                : !state?.in
            }
          />
        </form>
      );

    case FilterType.Role:
      return (
        <form onSubmit={onSubmit}>
          <Block
            marginTop="scale300"
            display="flex"
            backgroundColor="inputBorder"
            overrides={{
              Block: {
                style: {
                  borderTopLeftRadius: theme.borders.radius200,
                  borderTopRightRadius: theme.borders.radius200,
                  borderBottomRightRadius: theme.borders.radius200,
                  borderBottomLeftRadius: theme.borders.radius200,
                },
              },
            }}
          >
            <RolesSelect
              size={SIZE.mini}
              onChange={(params) => {
                setState({
                  ...state,
                  value: params.option?.id,
                  label: params.option?.label,
                });
              }}
              {...(state?.value && {
                value: [{ id: state?.value }],
              })}
            />
          </Block>
          <ApplyButton isDisabled={!state?.value} />
        </form>
      );

    case FilterType.ActivityLogsSubjectType:
      return (
        <form onSubmit={onSubmit}>
          <Block
            marginTop="scale300"
            display="flex"
            backgroundColor="inputBorder"
            overrides={{
              Block: {
                style: {
                  borderTopLeftRadius: theme.borders.radius200,
                  borderTopRightRadius: theme.borders.radius200,
                  borderBottomRightRadius: theme.borders.radius200,
                  borderBottomLeftRadius: theme.borders.radius200,
                },
              },
            }}
          >
            <ActivityLogsSubjectSelect
              size={SIZE.mini}
              onChange={(params) => {
                setState({
                  ...state,
                  value: params.option?.id,
                  label: params.option?.label,
                });
              }}
              {...(state?.value && {
                value: [{ label: state?.label }],
              })}
            />
          </Block>
          <ApplyButton isDisabled={!state?.value} />
        </form>
      );

    case FilterType.SeoTagsetsSubjectType:
      return (
        <form onSubmit={onSubmit}>
          <Block
            marginTop="scale300"
            display="flex"
            backgroundColor="inputBorder"
            overrides={{
              Block: {
                style: {
                  borderTopLeftRadius: theme.borders.radius200,
                  borderTopRightRadius: theme.borders.radius200,
                  borderBottomRightRadius: theme.borders.radius200,
                  borderBottomLeftRadius: theme.borders.radius200,
                },
              },
            }}
          >
            <SeoTagsetsSubjectSelect
              size={SIZE.mini}
              onChange={(params) => {
                setState({
                  ...state,
                  value: params.option?.id,
                  label: params.option?.label,
                });
              }}
              {...(state?.value && {
                value: [{ label: state?.label }],
              })}
            />
          </Block>
          <ApplyButton isDisabled={!state?.value} />
        </form>
      );

    case FilterType.StaticPageSystemName:
      return (
        <form onSubmit={onSubmit}>
          <Block
            marginTop="scale300"
            display="flex"
            backgroundColor="inputBorder"
            overrides={{
              Block: {
                style: {
                  borderTopLeftRadius: theme.borders.radius200,
                  borderTopRightRadius: theme.borders.radius200,
                  borderBottomRightRadius: theme.borders.radius200,
                  borderBottomLeftRadius: theme.borders.radius200,
                },
              },
            }}
          >
            <StaticPageSystemNamesSelect
              size={SIZE.mini}
              onChange={(params) => {
                setState({
                  ...state,
                  value: params.option?.id,
                  label: params.option?.label,
                });
              }}
              {...(state?.value && {
                value: [{ label: state?.label }],
              })}
            />
          </Block>
          <ApplyButton isDisabled={!state?.value} />
        </form>
      );

    case FilterType.CreatedBy:
      return (
        <form onSubmit={onSubmit}>
          <Block marginTop="scale300">
            <UsersSelect
              size="mini"
              placeholder="Wybierz"
              onChange={(params) => {
                setState({
                  ...state,
                  value: params.option?.id,
                  label: renderUserLabel(params.option as User),
                });
              }}
              {...(state?.value && {
                value: [{ id: state?.value, ...state }],
              })}
            />
          </Block>
          <ApplyButton isDisabled={!state?.value} />
        </form>
      );

    case FilterType.UpdatedBy:
      return (
        <form onSubmit={onSubmit}>
          <Block marginTop="scale300">
            <UsersSelect
              size="mini"
              placeholder="Wybierz"
              onChange={(params) => {
                setState({
                  ...state,
                  value: params.option?.id,
                  label: renderUserLabel(params.option as User),
                });
              }}
              {...(state?.value && {
                value: [{ id: state?.value, ...state }],
              })}
            />
          </Block>
          <ApplyButton isDisabled={!state?.value} />
        </form>
      );

    case FilterType.User:
      return (
        <form onSubmit={onSubmit}>
          <Block marginTop="scale300">
            <UsersSelect
              size="mini"
              placeholder="Wybierz"
              onChange={(params) => {
                setState({
                  ...state,
                  value: params.option?.id,
                  label: renderUserLabel(params.option as User),
                });
              }}
              {...(state?.value && {
                value: [{ id: state?.value, ...state }],
              })}
            />
          </Block>
          <ApplyButton isDisabled={!state?.value} />
        </form>
      );

    case FilterType.Integer:
      return (
        <form onSubmit={onSubmit}>
          <Block display="flex" justifyContent="space-between">
            <Button
              size="mini"
              type="button"
              kind={state?.kind === "single" ? "primary" : "secondary"}
              onClick={() => setState({ ...state, kind: "single" })}
              $style={{
                borderTopRightRadius: "0px",
                borderBottomRightRadius: "0px",
                flexGrow: 1,
                flexShrink: 1,
                marginRight: "0.75px",
              }}
            >
              Wartość
            </Button>

            <Button
              size="mini"
              type="button"
              kind={state?.kind === "between" ? "primary" : "secondary"}
              onClick={() => setState({ ...state, kind: "between" })}
              $style={{
                borderTopLeftRadius: "0px",
                borderBottomLeftRadius: "0px",
                flexGrow: 1,
                flexShrink: 1,
                marginLeft: "0.75px",
              }}
            >
              Od — Do
            </Button>
          </Block>

          {state?.kind === "between" ? (
            <Fragment>
              <Block marginTop="scale300">
                <Input
                  size="mini"
                  placeholder="Od"
                  {...(state?.to && { max: state.to })}
                  {...(state?.from && { value: state.from })}
                  onChange={(event) =>
                    setState({ ...state, from: event.currentTarget.value })
                  }
                  type="number"
                  step="1"
                  endEnhancer={
                    <Block
                      display="flex"
                      overrides={{
                        Block: {
                          style: {
                            borderTopLeftRadius: theme.borders.radius200,
                            borderTopRightRadius: theme.borders.radius200,
                            borderBottomRightRadius: theme.borders.radius200,
                            borderBottomLeftRadius: theme.borders.radius200,
                          },
                        },
                      }}
                    >
                      <Button
                        type="button"
                        startEnhancer={<ChevronRight size={15} />}
                        size="mini"
                        disabled={!state?.from}
                        kind={!state?.gtOrEq ? "secondary" : "minimal"}
                        $style={{ flexGrow: 1, flexShrink: 0, width: "50%" }}
                        onClick={() => setState({ ...state, gtOrEq: false })}
                      />

                      <Button
                        type="button"
                        startEnhancer={<ChevronsRight size={15} />}
                        size="mini"
                        disabled={!state?.from}
                        kind={state?.gtOrEq ? "secondary" : "minimal"}
                        $style={{ flexGrow: 1, flexShrink: 0, width: "50%" }}
                        onClick={() => setState({ ...state, gtOrEq: true })}
                      />
                    </Block>
                  }
                />
              </Block>

              <Block marginTop="scale300">
                <Input
                  size="mini"
                  placeholder="Do"
                  {...(state?.from && { min: state.from })}
                  {...(state?.to && { value: state.to })}
                  onChange={(event) =>
                    setState({ ...state, to: event.currentTarget.value })
                  }
                  type="number"
                  step="1"
                  endEnhancer={
                    <Block
                      display="flex"
                      overrides={{
                        Block: {
                          style: {
                            borderTopLeftRadius: theme.borders.radius200,
                            borderTopRightRadius: theme.borders.radius200,
                            borderBottomRightRadius: theme.borders.radius200,
                            borderBottomLeftRadius: theme.borders.radius200,
                          },
                        },
                      }}
                    >
                      <Button
                        type="button"
                        startEnhancer={<ChevronLeft size={15} />}
                        size="mini"
                        disabled={!state?.to}
                        kind={!state?.ltOrEq ? "secondary" : "minimal"}
                        $style={{ flexGrow: 1, flexShrink: 0, width: "50%" }}
                        onClick={() => setState({ ...state, ltOrEq: false })}
                      />

                      <Button
                        type="button"
                        startEnhancer={<ChevronsLeft size={15} />}
                        size="mini"
                        disabled={!state?.to}
                        kind={state?.ltOrEq ? "secondary" : "minimal"}
                        $style={{ flexGrow: 1, flexShrink: 0, width: "50%" }}
                        onClick={() => setState({ ...state, ltOrEq: true })}
                      />
                    </Block>
                  }
                />
              </Block>
            </Fragment>
          ) : (
            <Block marginTop="scale300">
              <Input
                size="mini"
                placeholder="Wartość"
                {...(state?.eq && { value: state.eq })}
                required
                onChange={(event) =>
                  setState({ ...state, eq: event.currentTarget.value })
                }
                type="number"
                step="1"
              />
            </Block>
          )}

          <ApplyButton />
        </form>
      );

    default:
      return (
        <form onSubmit={onSubmit}>
          <Input size="mini" placeholder="Wartość" />
          <ApplyButton />
        </form>
      );
  }
}

type FiltersProps = {
  filters?: BasicFilter[];
  state?: FiltersState;
  setState?: (items: FiltersState) => void;
};

export default function Filters({
  filters,
  state,
  setState,
}: FiltersProps): React.ReactElement {
  const [css, theme] = useStyletron();
  const [sticked, setSticked] = useState(false);
  const [selectedSearchables, setSelectedSearchables] = useState<BasicFilter[]>(
    filters?.filter((filter: BasicFilter) => filter.type === FilterType.Text) ||
      []
  );
  const [selectedFilter, setSelectedFilter] = useState<BasicFilter | null>(
    null
  );
  const [choosedFilters, setChoosedFilters] = useState<BasicFilter[]>([]);
  const [shouldResetResults, setShouldResetResults] = useState(false);
  const [textFilterPhrase, setTextFilterPhrase] = useState("");
  const { isFetching } = useLoading();
  const containerRef = useRef<HTMLDivElement>(null);
  const filtersRef = useRef<HTMLDivElement>(null);

  const history = useHistory();
  const { search } = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    containerRef?.current?.parentNode?.parentNode?.addEventListener(
      "scroll",

      (event: Event) => {
        if ((event?.target as HTMLDivElement)?.scrollTop > 52) setSticked(true);
        else setSticked(false);
      }
    );
  }, [containerRef]);

  useEffect(() => {
    setState &&
      setState([
        ...(state
          ? state.filter(({ filter }) => filter.type !== FilterType.Text)
          : []),
        ...(selectedSearchables.length && textFilterPhrase.length
          ? selectedSearchables.map((filter) => ({
              uuid: generateUUID(),
              filter,
              state: { like: textFilterPhrase },
            }))
          : []),
      ]);
  }, [selectedSearchables, textFilterPhrase]);

  useEffect(() => {
    const columnFilters = state?.filter(
      ({ filter }) => filter.type !== FilterType.Text
    );

    if (window && state) {
      const searchParams = new URLSearchParams({
        ...(params && Object.fromEntries(params)),
        filters: window.btoa(
          unescape(encodeURIComponent(JSON.stringify(columnFilters)))
        ),
        ...(shouldResetResults && { page: "1" }),
      });

      if (!columnFilters || columnFilters?.length === 0)
        searchParams.delete("filters");

      if (searchParams.toString() !== params.toString())
        history.push({ search: searchParams.toString() });
    }

    if (shouldResetResults) {
      setShouldResetResults(false);

      document?.querySelector("main > section")?.scrollTo({
        top: 0,
        left: 0,
        behavior: "auto",
      });
    }
  }, [state]);

  useEffect(() => {
    const filters = params.get("filters");

    const parsedFilters = filters
      ? JSON.parse(decodeURIComponent(escape(window.atob(filters as string))))
      : null;

    setChoosedFilters([
      ...(parsedFilters
        ? parsedFilters?.map((item: SingleFilter) => item?.filter)
        : []),
    ]);

    setState &&
      filters &&
      window &&
      setState([
        ...(selectedSearchables.length
          ? selectedSearchables.map((filter) => ({
              uuid: generateUUID(),
              filter,
              state: { like: textFilterPhrase },
            }))
          : []),
        ...(parsedFilters.length
          ? JSON.parse(decodeURIComponent(escape(window.atob(filters))))
          : []),
      ]);
  }, [search]);

  const shouldShowInput =
    !!filters?.length &&
    filters?.some((filter: BasicFilter) => filter.type === FilterType.Text);
  const shouldShowButton =
    !!filters?.length &&
    filters?.some((filter: BasicFilter) => filter.type !== FilterType.Text);

  return (
    <Container $sticked={sticked} ref={containerRef} data-ui="filters">
      <Grid>
        <Cell span={12}>
          <div
            className={css({ display: "flex", gap: "10px", height: "30px" })}
          >
            {shouldShowInput && (
              <Popover
                content={() => (
                  <Block width="200px" padding="scale300">
                    <LabelXSmall
                      $style={{
                        fontWeight: 600,
                        borderBottomWidth: "1px",
                        borderBottomStyle: "dotted",
                        borderBottomColor: theme.colors.inputBorder,
                        paddingBottom: theme.sizing.scale300,
                        marginBottom: theme.sizing.scale500,
                      }}
                    >
                      Przeszukuj po
                    </LabelXSmall>

                    {filters
                      ?.filter(({ type }) => type === FilterType.Text)
                      ?.map((filter) => (
                        <div
                          key={`filterToSelect-${filter.id}`}
                          className={css({
                            display: "flex",
                            alignItems: "center",
                            marginTop: theme.sizing.scale300,
                            borderRadius: "6px",
                            ":hover": {
                              backgroundColor: "#f9f9f9",
                              cursor: "pointer",
                            },
                          })}
                          onClick={() => {
                            setSelectedSearchables((selectedSearchables) =>
                              selectedSearchables?.includes(filter)
                                ? selectedSearchables.filter(
                                    (searchable) => searchable !== filter
                                  )
                                : [...selectedSearchables, filter]
                            );
                          }}
                        >
                          <div
                            className={css({
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: selectedSearchables?.includes(
                                filter
                              )
                                ? theme.colors.primary
                                : "#eee",
                              padding: "4px",
                              borderRadius: "6px",
                              marginRight: "8px",
                              flexShrink: 0,
                            })}
                          >
                            {selectedSearchables?.includes(filter) ? (
                              <Check size={14} color="white" />
                            ) : (
                              <FilterIcon type={filter.type} />
                            )}
                          </div>

                          <LabelXSmall>{filter.label}</LabelXSmall>
                        </div>
                      ))}

                    {!selectedSearchables.length && (
                      <LabelXSmall
                        display="flex"
                        alignItems="flex-start"
                        $style={{
                          fontWeight: 400,
                          borderTopWidth: "1px",
                          borderTopStyle: "dotted",
                          borderTopColor: theme.colors.inputBorder,
                          paddingTop: theme.sizing.scale300,
                          marginTop: theme.sizing.scale500,
                        }}
                      >
                        <div
                          className={css({
                            flexShrink: 0,
                            marginTop: "3px",
                            marginRight: "8px",
                          })}
                        >
                          <AlertTriangle
                            size={14}
                            color={theme.colors.warning400}
                          />
                        </div>
                        Wybierz przynajmniej jedno pole, aby rozpocząć
                        przeszukiwanie.
                      </LabelXSmall>
                    )}
                  </Block>
                )}
                placement="bottom"
              >
                <div
                  className={css({
                    width: "300px",
                    height: "30px",
                    flexShrink: 0,
                  })}
                >
                  <ForwardedInput
                    size="mini"
                    startEnhancer={() => <Search size={12} />}
                    {...(!selectedSearchables.length && {
                      endEnhancer: () => (
                        <AlertTriangle
                          size={14}
                          color={theme.colors.warning400}
                        />
                      ),
                    })}
                    clearable={true}
                    disabled={isFetching}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      setTextFilterPhrase(event.target.value);
                    }}
                    $style={{
                      height: "30px",
                      fontSize: "12px",
                      paddingTop: "1px",
                      lineHeight: "12px",
                    }}
                  />
                </div>
              </Popover>
            )}

            {state &&
              state.filter(({ filter }) => filter.type !== FilterType.Text)
                .length > 0 && (
                <div
                  ref={filtersRef}
                  className={css({
                    overflowX: "scroll",
                    overflowY: "hidden",
                    position: "relative",
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                    "::-webkit-scrollbar": {
                      display: "none",
                    },
                  })}
                >
                  <div
                    className={css({
                      gap: "10px",
                      display: "flex",
                    })}
                  >
                    {state
                      .filter(({ filter }) => filter.type !== FilterType.Text)
                      .map((instance) => {
                        const {
                          uuid: filterUuid,
                          filter,
                          state: filterState,
                        } = instance;

                        return (
                          <Popover
                            key={generateUUID()}
                            ignoreBoundary
                            content={({ close }) => (
                              <Block padding="scale300" minWidth={"300px"}>
                                <LabelXSmall
                                  display="flex"
                                  alignItems="flex-end"
                                  $style={{
                                    fontWeight: 600,
                                    borderBottomWidth: "1px",
                                    borderBottomStyle: "dotted",
                                    borderBottomColor: theme.colors.inputBorder,
                                    paddingBottom: theme.sizing.scale300,
                                    marginBottom: theme.sizing.scale500,
                                  }}
                                >
                                  <div
                                    className={css({
                                      flexShrink: 0,
                                      marginRight: "5px",
                                      height: "15px",
                                      cursor: "pointer",
                                    })}
                                    onClick={() => setSelectedFilter(null)}
                                  >
                                    <FilterIcon type={filter.type} size={14} />
                                  </div>

                                  {filter.label}
                                </LabelXSmall>

                                <FilterForm
                                  type={filter.type}
                                  filter={filter}
                                  state={filterState}
                                  onSubmit={(filterState) => {
                                    close();
                                    setSelectedFilter(null);
                                    setShouldResetResults(true);
                                    setState &&
                                      setState([
                                        ...state.filter(
                                          ({ uuid }) => uuid !== filterUuid
                                        ),
                                        {
                                          uuid: filterUuid,
                                          filter: filter,
                                          state: filterState as JSON,
                                        },
                                      ]);
                                  }}
                                />

                                <Button
                                  startEnhancer={<Trash size={14} />}
                                  size="mini"
                                  kind="secondary"
                                  $style={{
                                    width: "100%",
                                    marginTop: "10px",
                                  }}
                                  onClick={() => {
                                    close();

                                    setSelectedFilter(null);
                                    setChoosedFilters([
                                      ...choosedFilters?.filter(
                                        (choosedFilter) =>
                                          !state?.some(
                                            (item) =>
                                              item.filter.id ===
                                              choosedFilter.id
                                          )
                                      ),
                                    ]);
                                    setShouldResetResults(true);

                                    setState &&
                                      setState([
                                        ...state.filter(
                                          ({ uuid }) => uuid !== filterUuid
                                        ),
                                      ]);
                                  }}
                                >
                                  Usuń
                                </Button>
                              </Block>
                            )}
                            placement="bottom"
                          >
                            <ForwardedButton
                              startEnhancer={
                                <FilterIcon type={filter.type} size={14} />
                              }
                              size="mini"
                              kind="tertiary"
                              $style={{
                                minWidth: "100px",
                                maxWidth: "320px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                height: "30px",
                                justifyContent: "flex-start",
                              }}
                            >
                              {translateFilterToString(instance)}
                            </ForwardedButton>
                          </Popover>
                        );
                      })}
                  </div>
                </div>
              )}

            {shouldShowButton && (
              <Popover
                content={({ close }) => {
                  if (
                    choosedFilters.length ===
                    filters?.filter(
                      (filter: BasicFilter) => filter.type !== FilterType.Text
                    )?.length
                  )
                    close();

                  return (
                    <Block padding="scale300" minWidth={"300px"}>
                      <LabelXSmall
                        display="flex"
                        alignItems="flex-end"
                        $style={{
                          fontWeight: 600,
                          borderBottomWidth: "1px",
                          borderBottomStyle: "dotted",
                          borderBottomColor: theme.colors.inputBorder,
                          paddingBottom: theme.sizing.scale300,
                          marginBottom: theme.sizing.scale500,
                        }}
                      >
                        {selectedFilter && (
                          <div
                            className={css({
                              flexShrink: 0,
                              marginRight: "5px",
                              height: "15px",
                              cursor: "pointer",
                            })}
                            onClick={() => setSelectedFilter(null)}
                          >
                            <ChevronLeft size={14} />
                          </div>
                        )}

                        {selectedFilter ? selectedFilter.label : "Filtruj po"}
                      </LabelXSmall>

                      {selectedFilter ? (
                        <FilterForm
                          type={selectedFilter.type}
                          filter={selectedFilter}
                          onSubmit={(filterState) => {
                            close();

                            setShouldResetResults(true);
                            setChoosedFilters([
                              ...choosedFilters,
                              selectedFilter,
                            ]);
                            setSelectedFilter(null);
                            setState &&
                              setState([
                                ...(state ? state : []),
                                {
                                  uuid: generateUUID(),
                                  filter: selectedFilter,
                                  state: filterState as JSON,
                                },
                              ]);
                          }}
                        />
                      ) : (
                        filters
                          ?.filter(({ type }) => type !== FilterType.Text)
                          ?.filter(
                            (filter) =>
                              !choosedFilters?.some(
                                (choosedFilter) =>
                                  choosedFilter?.id === filter?.id
                              )
                          )
                          .map((filter) => (
                            <div
                              key={`filterToSelect-${filter.id}`}
                              className={css({
                                display: "flex",
                                alignItems: "center",
                                marginTop: theme.sizing.scale300,
                                borderRadius: "6px",
                                ":hover": {
                                  backgroundColor: "#f9f9f9",
                                  cursor: "pointer",
                                },
                              })}
                              onClick={() => setSelectedFilter(filter)}
                            >
                              <div
                                className={css({
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  backgroundColor: "#eee",
                                  padding: "4px",
                                  borderRadius: "6px",
                                  marginRight: "8px",
                                  flexShrink: 0,
                                })}
                              >
                                <FilterIcon type={filter.type} />
                              </div>

                              <LabelXSmall>{filter.label}</LabelXSmall>
                            </div>
                          ))
                      )}
                    </Block>
                  );
                }}
                placement="bottom"
              >
                <ForwardedButton
                  kind="secondary"
                  size="compact"
                  type="button"
                  startEnhancer={<Filter size={14} />}
                  disabled={
                    choosedFilters.length ===
                      filters?.filter(
                        (filter: BasicFilter) => filter.type !== FilterType.Text
                      )?.length || isFetching
                  }
                  $style={{
                    flexShrink: 0,
                  }}
                >
                  Filtruj
                </ForwardedButton>
              </Popover>
            )}

            {filters &&
              !!filters?.filter(
                (filter: BasicFilter) => filter.type !== FilterType.Text
              )?.length && (
                <ForwardedButton
                  kind="secondary"
                  size="compact"
                  type="button"
                  startEnhancer={<Trash size={14} />}
                  disabled={!choosedFilters.length}
                  $style={{
                    flexShrink: 0,
                  }}
                  {...(setState && {
                    onClick: () => {
                      setState([]);
                      setChoosedFilters([]);
                      setShouldResetResults(true);
                    },
                  })}
                >
                  Usuń filtry
                </ForwardedButton>
              )}
          </div>
        </Cell>
      </Grid>
    </Container>
  );
}
