export enum FieldType {
  AllergensSelect = "allergens-select",
  Author = "author",
  BrandsSelect = "brands-select",
  CategoryRelations = "category-relations",
  CategoriesSelect = "categories-select",
  CategoriesTable = "categories-table",
  CategoryKindSelect = "category-kind-select",
  Checkbox = "checkbox",
  ColorPicker = "color-picker",
  DatePicker = "date-picker",
  DictionaryValuesSelect = "dictionary-values-select",
  DifficultySelect = "difficulty-select",
  Editor = "editor",
  FilesList = "files-list",
  FilesPicker = "files-picker",
  Image = "image",
  ImageCropper = "image-cropper",
  RecipeIngredients = "recipe-ingredients",
  RecipeSteps = "recipe-steps",
  Input = "input",
  BigInput = "big-input",
  Multi = "multi",
  NumberInput = "number-input",
  PermissionsTable = "permissions-table",
  RadioButton = "radio-button",
  RobotsSelect = "robots-select",
  RolesSelect = "roles-select",
  RolesMultiSelect = "roles-multi-select",
  Select = "select",
  SlugInput = "slug-input",
  SeoTagsetSubject = "subject",
  SlideVariant = "slide-variant",
  SocialMedia = "social-media",
  StaticPageSystemName = "static-page-system-name",
  TagsSelect = "tags-select",
  UserFullName = "user-full-name",
  UsersSelect = "users-select",
  UserStatus = "user-status",
  TextArea = "text-area",
  Link = "link",
  Switch = "switch",
}

type BasicField = {
  id: string;
  label: string;
  caption?: string;
  placeholder?: string;
  info?: string | string[];
  span?: number;
  skip?: number;
  dataType?: string;
  typeName?: string;
  type: FieldType[keyof FieldType];
  mask?: string;
  maskChar?: string;
  endEnhancer?: string;
  startEnhancer?: string;
  readonly?: boolean;
  maxLength?: number;
  autocomplete?: string;
  imageRequirements?: {
    aspect?: number;
    rounded?: boolean;
    allowVectors?: boolean;
  };
  dictionarySystemName?: string;
  optionallyHidden?: boolean;
};

export type Field = {
  show: { visible: boolean; accessor?: string | string[] };
  create: { visible: boolean; required?: boolean };
  update: { visible: boolean; required?: boolean };
} & BasicField;

type BasicFieldsGroup = {
  id: string;
  label: string;
};

export type FieldsGroup = {
  fields: Field[];
  accessor?: string;
} & BasicFieldsGroup;
