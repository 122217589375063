import { gql } from "@apollo/client";

export const ALLERGENS_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $filter: AllergenFilter
    $sorting: [AllergenSort!]
  ) {
    allergens(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        name
        isVisible
        createdAt
        createdBy {
          id
          firstName
          lastName
          username
          email
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
          username
          email
        }
      }
    }
  }
`;

export const ALLERGENS_SHOW = gql`
  query AllergenShow($id: Int!) {
    allergen(id: $id) {
      id
      name
      imageUrl
      isVisible
      createdAt
      createdBy {
        id
        firstName
        lastName
        username
        email
      }
      updatedAt
      updatedBy {
        id
        firstName
        lastName
        username
        email
      }
    }
  }
`;

export const ALLERGENS_CREATE = gql`
  mutation AllergenCreate($allergenCreateInput: AllergenCreateInput!) {
    allergenCreate(allergenCreateInput: $allergenCreateInput) {
      id
    }
  }
`;

export const ALLERGENS_UPDATE = gql`
  mutation AllergenUpdate($allergenUpdateInput: AllergenUpdateInput!) {
    allergenUpdate(allergenUpdateInput: $allergenUpdateInput) {
      id
    }
  }
`;

export const ALLERGENS_DELETE = gql`
  mutation AllergenDelete($allergenDeleteInput: AllergenDeleteInput!) {
    allergenDelete(allergenDeleteInput: $allergenDeleteInput) {
      id
    }
  }
`;
