import { gql } from "@apollo/client";

export const ARTICLES_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $filter: ArticleFilter
    $sorting: [ArticleSort!]
  ) {
    articles(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        name
        brand {
          id
          name
        }
        createdAt
        createdBy {
          id
          firstName
          lastName
          username
          email
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
          username
          email
        }
        isVisible
        slug
        author {
          name
          user {
            id
            firstName
            lastName
            username
            email
          }
          brand {
            id
            name
          }
        }
      }
    }
  }
`;

export const ARTICLES_LIVE_SEARCH = gql`
  query($offset: Int, $filter: ArticleFilter, $sorting: [ArticleSort!]) {
    articles(
      paging: { limit: 5, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      nodes {
        id
        name
      }
    }
  }
`;

export const ARTICLES_SHOW = gql`
  query ArticleShow($id: Int!) {
    article(id: $id) {
      id
      name
      slug
      lead
      content
      isVisible
      createdAt
      readTime
      imageUrl
      brand {
        id
        name
      }
      createdBy {
        id
        firstName
        lastName
      }
      updatedAt
      updatedBy {
        id
        firstName
        lastName
      }
      tags {
        id
        name
      }
      categories {
        id
        name
      }
      seoTagset {
        id
        metaTitle
        metaDescription
        metaKeywords
        ogTitle
        ogDescription
        canonical
        robots
      }
      author {
        name
        user {
          id
          firstName
          lastName
          username
          email
        }
        brand {
          id
          name
        }
      }
    }
  }
`;

export const ARTICLES_CREATE = gql`
  mutation ArticleCreate(
    $articleCreateInput: ArticleCreateInput!
    $authorAssignInput: AuthorAssignInput!
    $seoTagsetUpsertInput: SeoTagsetUpsertInput
  ) {
    articleCreate(
      articleCreateInput: $articleCreateInput
      authorAssignInput: $authorAssignInput
      seoTagsetUpsertInput: $seoTagsetUpsertInput
    ) {
      id
    }
  }
`;

export const ARTICLES_UPDATE = gql`
  mutation ArticleUpdate(
    $articleUpdateInput: ArticleUpdateInput!
    $authorAssignInput: AuthorAssignInput
    $seoTagsetUpsertInput: SeoTagsetUpsertInput
  ) {
    articleUpdate(
      articleUpdateInput: $articleUpdateInput
      authorAssignInput: $authorAssignInput
      seoTagsetUpsertInput: $seoTagsetUpsertInput
    ) {
      id
    }
  }
`;

export const ARTICLES_DELETE = gql`
  mutation ArticleDelete($articleDeleteInput: ArticleDeleteInput!) {
    articleDelete(articleDeleteInput: $articleDeleteInput) {
      id
    }
  }
`;
