import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { ConfirmDialog } from "components/confirm-dialog";
import { Content } from "components/content";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { ObjectViewRenderer } from "components/object-view-renderer";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Lock, LockOpen, Pencil, Send, Trash } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";
import { renderUserLabel } from "utils/render-user-label";

import { User } from "../users";
import { USERS_FIELDS } from "../users.form";
import {
  USERS_BLOCK,
  USERS_DELETE,
  USERS_RESEND_INVITATION,
  USERS_SHOW,
  USERS_UNBLOCK,
} from "../users.gql";

export default function UsersShow(): React.ReactElement {
  const [, theme] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const { checkPermission, user: viewer } = useAuth();
  const [isConfirmBlockDialogOpen, setIsConfirmBlockDialogOpen] = useState(
    false
  );
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(
    false
  );
  const [
    isConfirmResendInvitationDialogOpen,
    setIsConfirmResendInvitationDialogOpen,
  ] = useState(false);
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { setIsLoading, setIsFetching } = useLoading();

  const { refetch, data, error } = useQuery(USERS_SHOW, {
    variables: { id: id ? parseInt(id) : null },
  });
  const user: User = data?.user;
  const shouldDisableActions = id && parseInt(id) === viewer?.id;

  useEffect(() => {
    if (error?.graphQLErrors)
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (user) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => user && setIsFetching(false), [data]);

  const [blockUser] = useMutation(USERS_BLOCK);
  const [unblockUser] = useMutation(USERS_UNBLOCK);
  const [deleteUser] = useMutation(USERS_DELETE);
  const [resendInvitation] = useMutation(USERS_RESEND_INVITATION);

  enum ActionType {
    Block = "block",
    Delete = "delete",
    Unblock = "unblock",
    ResendInvitation = "resend-invitation",
  }

  const onSubmit = async (actionType: ActionType) => {
    setIsLoading(true);

    try {
      actionType === ActionType.Block &&
        (await blockUser({
          variables: {
            userBlockInput: {
              id: id ? parseInt(id) : null,
            },
          },
        }));
      actionType === ActionType.Delete &&
        (await deleteUser({
          variables: {
            userDeleteInput: {
              id: id ? parseInt(id) : null,
            },
          },
        }));
      actionType === ActionType.Unblock &&
        (await unblockUser({
          variables: {
            userUnblockInput: {
              id: id ? parseInt(id) : null,
            },
          },
        }));
      actionType === ActionType.ResendInvitation &&
        (await resendInvitation({
          variables: {
            id: id ? parseInt(id) : null,
          },
        }));

      setIsConfirmBlockDialogOpen(false);

      enqueueSnackbar({
        message:
          actionType === ActionType.Block
            ? "Zablokowano pomyślnie"
            : actionType === ActionType.Delete
            ? "Usunięto pomyślnie"
            : actionType === ActionType.Unblock
            ? "Odblokowano pomyślnie"
            : "Wysłano ponowne zaproszenie",
        variant: "success",
      });

      refetch();
    } catch (error: unknown) {
      setIsConfirmBlockDialogOpen(false);
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!checkPermission(PERMISSIONS.user.read)) return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title={renderUserLabel(user)}
        labels={["Użytkownicy", "Wyświetlanie"]}
        goBackOption
        buttons={[
          {
            label: "Edytuj",
            kind: KIND.secondary,
            startEnhancer: <Pencil size={18} />,
            permission: checkPermission(PERMISSIONS.user.update),
            onClick: () => history.push(`/users/${id}/update`),
          },
        ]}
        actions={[
          {
            label: "Wyślij ponownie zaproszenie",
            icon: Send,
            color: theme.colors.positive,
            onClick: () => setIsConfirmResendInvitationDialogOpen(true),
            permission: !user?.activatedAt,
          },
          {
            label: "Odblokuj użytkownika",
            icon: LockOpen,
            color: theme.colors.positive,
            onClick: () => setIsConfirmBlockDialogOpen(true),
            permission:
              checkPermission(PERMISSIONS.user.unblock) &&
              !!user?.blockedAt &&
              !shouldDisableActions,
          },
          {
            label: "Zablokuj użytkownika",
            icon: Lock,
            color: theme.colors.negative,
            onClick: () => setIsConfirmBlockDialogOpen(true),
            permission:
              checkPermission(PERMISSIONS.user.block) &&
              !user?.blockedAt &&
              !shouldDisableActions,
          },
          {
            label: "Usuń użytkownika",
            icon: Trash,
            color: theme.colors.negative,
            onClick: () => setIsConfirmDeleteDialogOpen(true),
            permission:
              checkPermission(PERMISSIONS.user.delete) && !shouldDisableActions,
          },
        ]}
      />
      <Content>
        {data && <ObjectViewRenderer<User> fields={USERS_FIELDS} data={user} />}
        <ConfirmDialog
          isOpen={isConfirmBlockDialogOpen}
          label={`${
            user?.blockedAt ? "Odblokowanie" : "Zablokowanie"
          } użytkownika ${renderUserLabel(user)}`}
          close={() => setIsConfirmBlockDialogOpen(false)}
          confirm={() => {
            onSubmit(user?.blockedAt ? ActionType.Unblock : ActionType.Block);
            setIsConfirmBlockDialogOpen(false);
          }}
        />
        <ConfirmDialog
          isOpen={isConfirmDeleteDialogOpen}
          label={`Usunięcie użytkownika ${renderUserLabel(user)}`}
          close={() => setIsConfirmDeleteDialogOpen(false)}
          confirm={() => {
            onSubmit(ActionType.Delete);
            setIsConfirmDeleteDialogOpen(false);
            history.push("/users");
          }}
        />
        <ConfirmDialog
          isOpen={isConfirmResendInvitationDialogOpen}
          label={`Ponowne wysłanie zaproszenia do użytkownika ${renderUserLabel(
            user
          )}`}
          close={() => setIsConfirmResendInvitationDialogOpen(false)}
          confirm={() => {
            onSubmit(ActionType.ResendInvitation);
            setIsConfirmResendInvitationDialogOpen(false);
          }}
        />
      </Content>
    </article>
  );
}
