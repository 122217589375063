import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import {
  ALIGN,
  Radio,
  RadioGroup,
  RadioGroupProps,
  RadioProps,
} from "baseui/radio";
import { Input } from "components/input";
import { BrandsSelect, UsersSelect } from "components/select";
import { User } from "containers/Users/users";
import { useLoading } from "contexts/loading-context";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Controller, UseControllerProps } from "react-hook-form";
import { renderUserLabel } from "utils/render-user-label";

export enum AuthorType {
  AuthorName = "authorName",
  Brand = "brand",
  User = "user",
}

export type AuthorSelectorValue = {
  type?: AuthorType | string;
  value?: string | number;
  user?: {
    firstName?: string;
    lastName?: string;
    username?: string;
    email?: string;
  };
};

type AuthorSelectorProps = {
  value?: AuthorSelectorValue;
  onChange?: (value?: AuthorSelectorValue) => void;
};

export default function AuthorSelector({
  value,
  onChange,
  ...rest
}: RadioProps & RadioGroupProps & AuthorSelectorProps): React.ReactElement {
  const [css, theme] = useStyletron();

  const buttons = [
    { id: AuthorType.User, label: "Użytkownik" },
    { id: AuthorType.Brand, label: "Marka" },
    { id: AuthorType.AuthorName, label: "Nazwa własna" },
  ];

  const { isLoading } = useLoading();
  const [selectedAuthor, setSelectedAuthor] = useState<
    AuthorSelectorValue | undefined
  >(value);

  useEffect(() => {
    selectedAuthor
      ? onChange && onChange(selectedAuthor)
      : onChange && onChange(undefined);
  }, [selectedAuthor]);

  useEffect(() => {
    setSelectedAuthor(value);
  }, [value]);

  const renderAuthorInput = (authorType?: AuthorType) => {
    switch (authorType) {
      case AuthorType.Brand:
        return (
          <BrandsSelect
            id="brands"
            disabled={isLoading}
            placeholder="Wybierz markę"
            onChange={(params) =>
              setSelectedAuthor((selectedAuthor) => ({
                type: selectedAuthor?.type,
                value: params.option?.id,
              }))
            }
            {...(selectedAuthor?.value && {
              value: [{ id: selectedAuthor?.value }],
            })}
            $controlContainerStyle={{ backgroundColor: "white" }}
          />
        );

      case AuthorType.User:
        return (
          <UsersSelect
            id="users"
            disabled={isLoading}
            placeholder="Wybierz użytkownika"
            onChange={(params) =>
              setSelectedAuthor((selectedAuthor) => ({
                type: selectedAuthor?.type,
                value: params.option?.id,
                user: {
                  firstName: params.option?.firstName,
                  lastName: params.option?.lastName,
                  username: params.option?.username,
                  email: params.option?.email,
                },
              }))
            }
            {...(selectedAuthor?.value && {
              value: [
                {
                  id: selectedAuthor?.value,
                  label: renderUserLabel(selectedAuthor.user as User),
                  ...selectedAuthor.user,
                },
              ],
            })}
            $controlContainerStyle={{ backgroundColor: "white" }}
          />
        );

      case AuthorType.AuthorName:
        return (
          <Input
            id="authorName"
            disabled={isLoading}
            placeholder="Wpisz nazwę autora"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setSelectedAuthor((selectedAuthor) => ({
                type: selectedAuthor?.type,
                value: e.currentTarget?.value,
              }))
            }
            value={selectedAuthor?.value}
            $style={{ backgroundColor: "white" }}
          />
        );
    }
  };

  return (
    <Block
      display="flex"
      width="100%"
      padding="12px"
      backgroundColor="inputFill"
      flexDirection="column"
      overrides={{
        Block: {
          style: {
            borderTopLeftRadius: theme.borders.radius200,
            borderTopRightRadius: theme.borders.radius200,
            borderBottomRightRadius: theme.borders.radius200,
            borderBottomLeftRadius: theme.borders.radius200,
            borderLeftWidth: "2px",
            borderLeftStyle: "solid",
            borderLeftColor: theme.colors.inputBorder,
            borderBottomWidth: "2px",
            borderBottomStyle: "solid",
            borderBottomColor: theme.colors.inputBorder,
            borderRightWidth: "2px",
            borderRightStyle: "solid",
            borderRightColor: theme.colors.inputBorder,
            borderTopWidth: "2px",
            borderTopStyle: "solid",
            borderTopColor: theme.colors.inputBorder,
            gap: "2px",
          },
        },
      }}
    >
      <Block>
        <RadioGroup
          value={selectedAuthor?.type}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setSelectedAuthor({
              type: e.target.value as AuthorType,
              value: "",
            })
          }
          name="number"
          align={ALIGN.horizontal}
          {...rest}
        >
          {buttons.map((button) => (
            <Radio
              value={button.id}
              key={button.id}
              overrides={{
                RadioMarkInner: {
                  style: ({ $checked }) => ({
                    width: $checked ? "6px" : "12px",
                    height: $checked ? "6px" : "12px",
                  }),
                },
                RadioMarkOuter: {
                  style: {
                    width: "16px",
                    height: "16px",
                  },
                },
                Label: {
                  style: {
                    fontSize: "13px",
                    fontWeight: 400,
                    paddingLeft: "6px",
                  },
                },
                Root: {
                  style: {
                    marginRight: "16px",
                    marginTop: "0px",
                    marginBottom: "0px",
                  },
                },
              }}
            >
              {button.label}
            </Radio>
          ))}
        </RadioGroup>
      </Block>

      <Block
        display="flex"
        alignItems="center"
        justifyContent="center"
        marginTop="12px"
      >
        {selectedAuthor &&
          renderAuthorInput(selectedAuthor?.type as AuthorType)}
      </Block>
    </Block>
  );
}

export function ControlledAuthorSelector({
  control,
  name,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & AuthorSelectorProps): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        validate: (value) => {
          return !value?.value
            ? `Nie ${
                value?.type === AuthorType.AuthorName ? "wpisano" : "wybrano"
              } ${
                value?.type === AuthorType.Brand
                  ? "marki"
                  : value?.type === AuthorType.User
                  ? "użytkownika"
                  : "nazwy"
              }`
            : true;
        },
      }}
      render={({ field: { onChange, value, name } }) => {
        return (
          <AuthorSelector
            onChange={onChange}
            name={name}
            value={value}
            {...rest}
          />
        );
      }}
    />
  );
}
