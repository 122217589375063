import { useStyletron } from "baseui";
import {
  DatePicker as BaseDatePicker,
  DatepickerProps,
} from "baseui/datepicker";
import pl from "date-fns/locale/pl";
import React, { HTMLProps } from "react";
import { Controller, UseControllerProps } from "react-hook-form";
import { StyleObject } from "styletron-react";
import { X } from "tabler-icons-react";

type Props = {
  $style?: StyleObject;
  isDisabled?: boolean;
  date?: Date | null;
  setDate?: (date: Date) => void;
  id?: string;
} & DatepickerProps;

export default function DatePicker({
  $style,
  isDisabled,
  date,
  setDate,
  id,
  ...props
}: Props): React.ReactElement {
  const [css, theme] = useStyletron();

  return (
    <BaseDatePicker
      value={date}
      onChange={({ date }) => setDate && setDate(date as Date)}
      locale={pl}
      formatString="dd.MM.yyyy"
      placeholder="DD.MM.RRRR"
      clearable
      overrides={{
        Popover: {
          props: {
            overrides: {
              Body: {
                style: {
                  zIndex: 300,
                  borderTopLeftRadius: theme.borders.radius200,
                  borderTopRightRadius: theme.borders.radius200,
                  borderBottomRightRadius: theme.borders.radius200,
                  borderBottomLeftRadius: theme.borders.radius200,
                  borderLeftWidth: "2px",
                  borderLeftStyle: "solid",
                  borderLeftColor: theme.colors.borderSelected,
                  borderBottomWidth: "2px",
                  borderBottomStyle: "solid",
                  borderBottomColor: theme.colors.borderSelected,
                  borderRightWidth: "2px",
                  borderRightStyle: "solid",
                  borderRightColor: theme.colors.borderSelected,
                  borderTopWidth: "2px",
                  borderTopStyle: "solid",
                  borderTopColor: theme.colors.borderSelected,
                  boxShadow: "unset",
                },
              },
            },
          },
        },
        MonthYearSelectPopover: {
          props: {
            overrides: {
              Body: {
                style: {
                  borderTopLeftRadius: theme.borders.radius200,
                  borderTopRightRadius: theme.borders.radius200,
                  borderBottomRightRadius: theme.borders.radius200,
                  borderBottomLeftRadius: theme.borders.radius200,
                  borderLeftWidth: "2px",
                  borderLeftStyle: "solid",
                  borderLeftColor: theme.colors.inputFill,
                  borderBottomWidth: "2px",
                  borderBottomStyle: "solid",
                  borderBottomColor: theme.colors.inputFill,
                  borderRightWidth: "2px",
                  borderRightStyle: "solid",
                  borderRightColor: theme.colors.inputFill,
                  borderTopWidth: "2px",
                  borderTopStyle: "solid",
                  borderTopColor: theme.colors.inputFill,
                  boxShadow: "unset",
                  zIndex: 301,
                },
              },
            },
          },
        },
        CalendarHeader: {
          style: {
            backgroundColor: theme.colors.borderFocus,
          },
        },
        Input: {
          props: {
            id,
            size: "compact",
            overrides: {
              Root: {
                style: () => ({
                  borderTopLeftRadius: theme.borders.radius200,
                  borderTopRightRadius: theme.borders.radius200,
                  borderBottomRightRadius: theme.borders.radius200,
                  borderBottomLeftRadius: theme.borders.radius200,
                  ...$style,
                }),
              },
              ClearIcon: {
                props: {
                  overrides: {
                    Svg: (props: HTMLProps<HTMLElement>) => (
                      <span
                        {...props}
                        className={css({
                          cursor: "pointer",
                          display: "inline",
                          lineHeight: 0,
                        })}
                      >
                        <X color={theme.colors.borderFocus} size={16} />
                      </span>
                    ),
                  },
                },
              },
            },
            disabled: isDisabled,
          },
        },
        MonthHeader: {
          style: {
            backgroundColor: theme.colors.backgroundTertiary,
            color: "#000",
          },
        },
      }}
      {...props}
    />
  );
}

export function ControlledDatePicker({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & Props): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value, name } }) => (
        <DatePicker
          value={value}
          onChange={({ date }) => onChange(date as Date)}
          id={name}
          {...rest}
        />
      )}
    />
  );
}
