import { ApolloError, useLazyQuery, useMutation } from "@apollo/client";
import { InputValidationError } from "api";
import { useStyletron } from "baseui";
import { KIND } from "baseui/button";
import { ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import { Skeleton } from "baseui/skeleton";
import { Button } from "components/button";
import { ControlledCheckbox } from "components/checkbox";
import { FormControl } from "components/form-control";
import { ControlledInput } from "components/input";
import { Modal } from "components/modal";
import { useLoading } from "contexts/loading-context";
import { FieldType } from "fields.d";
import { FormErrors } from "form";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AlertOctagon } from "tabler-icons-react";
import { FORM_VALIDATION_MESSAGES } from "utils/form/validation-messages";

import { INPUT_VALIDATION_ERROR } from "../../../constants";
import {
  DICTIONARY_VALUES_FIELDS,
  DictionaryValuesFormInputs,
} from "../dictionaries.form";
import {
  DICTIONARY_VALUE_SHOW,
  DICTIONARY_VALUE_UPDATE,
} from "../dictionaries.gql";

type Props = {
  close: () => void;
  dictionaryId: number | undefined;
  id?: number;
  isOpen: boolean;
  onCompleted: () => void;
};

export default function ValueUpdate({
  close,
  dictionaryId,
  id,
  isOpen,
  onCompleted,
}: Props): React.ReactElement {
  const [css] = useStyletron();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { isFetching, setIsFetching } = useLoading();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<DictionaryValuesFormInputs>();

  const [fetchValue, { refetch, data }] = useLazyQuery(DICTIONARY_VALUE_SHOW, {
    variables: { id },
  });
  const dictionaryValue = data?.dictionaryValue;

  useEffect(() => {
    if (data) {
      setValue("name", dictionaryValue?.name);
      setValue("value", dictionaryValue?.value);
      setValue("isHidden", dictionaryValue?.isHidden);
      setIsFetching(false);
    }
  }, [data]);

  useEffect(() => {
    if (dictionaryValue && refetch) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => {
    id && id > 0 && fetchValue();
  }, [id]);

  const [updateValue, { loading: mutationLoading, error }] = useMutation(
    DICTIONARY_VALUE_UPDATE
  );

  const onSubmit = async (
    values: DictionaryValuesFormInputs
  ): Promise<void> => {
    setIsLoading(true);

    try {
      await updateValue({
        variables: {
          dictionaryValueUpdateInput: {
            id,
            dictionaryId,
            ...values,
          },
        },
      });
      enqueueSnackbar({
        message: "Wartość została zmieniona",
        variant: "success",
      });
      close();
      onCompleted();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal onClose={close} isOpen={isOpen}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>Edycja wartości</ModalHeader>
        <ModalBody>
          {DICTIONARY_VALUES_FIELDS.filter(
            (g) => g.fields.filter((f) => f.update.visible).length > 0
          ).map((group) => [
            group.fields
              .filter((f) => f.update.visible)
              .map((item, index) => (
                <FormControl
                  key={group.id + `-field` + index}
                  label={item.label}
                  caption={item.caption}
                  required={item.update.required}
                  error={
                    ((errors as FormErrors<DictionaryValuesFormInputs>)[
                      item.id as keyof DictionaryValuesFormInputs
                    ] &&
                      (errors as FormErrors<DictionaryValuesFormInputs>)[
                        item.id as keyof DictionaryValuesFormInputs
                      ]?.message) ||
                    (error &&
                      error.graphQLErrors[0]?.extensions?.code ===
                        INPUT_VALIDATION_ERROR &&
                      error.graphQLErrors[0]?.extensions?.validationErrors
                        ?.find(
                          (vE: InputValidationError) => vE?.property === item.id
                        )
                        ?.errors.map((message: string) => (
                          <div
                            key="error"
                            className={css({
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            })}
                          >
                            {message}
                            <span
                              className={css({
                                color: "#999",
                                marginLeft: "auto",
                                marginRight: "5px",
                              })}
                            >
                              Walidacja serwera
                            </span>
                            <AlertOctagon color="#999" size={12} />
                          </div>
                        ))[0])
                  }
                  disabled={isLoading}
                >
                  {isFetching ? (
                    <Skeleton rows={0} height="30px" width="100%" animation />
                  ) : item.type === FieldType.Checkbox ? (
                    <ControlledCheckbox name={item.id} control={control}>
                      Ukryta
                    </ControlledCheckbox>
                  ) : (
                    <ControlledInput
                      control={control}
                      name={item.id}
                      id={item.id}
                      placeholder={item.placeholder}
                      {...(item.update.required && {
                        rules: {
                          required: FORM_VALIDATION_MESSAGES.required,
                        },
                      })}
                    />
                  )}
                </FormControl>
              )),
          ])}
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            kind={KIND.secondary}
            $style={{ marginRight: "10px" }}
            onClick={close}
            isLoading={mutationLoading}
          >
            Anuluj
          </Button>
          <Button type="submit" kind={KIND.primary} isLoading={mutationLoading}>
            Zapisz
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
