import { useStyletron } from "baseui";
import { TextArea } from "components/text-area";
import { RecipeStep } from "containers/Recipes/recipes";
import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { Controller, UseControllerProps } from "react-hook-form";
import { Menu, Plus, Trash } from "tabler-icons-react";

import { generateUUID } from "../../utils/misc";
import { Button } from "../button";

type RecipeStepsComposerProps = {
  value?: RecipeStep[];
  onChange?: (steps?: RecipeStep[]) => void;
  id?: string;
  error?: boolean;
  disabled?: boolean;
};

export default function RecipeStepsComposer({
  value,
  onChange,
  error,
  disabled,
}: RecipeStepsComposerProps): ReactElement {
  const [css, theme] = useStyletron();

  const [recipeSteps, setRecipeSteps] = useState<RecipeStep[]>(
    value || [
      {
        id: "autoGeneratedFirstItem",
      },
    ]
  );
  const [changes, setChanges] = useState(0);

  useEffect(() => {
    if (changes > 0)
      onChange &&
        onChange(
          recipeSteps
            ?.filter(
              (recipeStep) => recipeStep.content && recipeStep.additionalInfo
            )
            ?.map((recipeStep: RecipeStep, index: number) => ({
              additionalInfo: recipeStep.additionalInfo,
              content: recipeStep.content,
              position: index + 1,
            }))
        );
  }, [changes]);

  const handleDelete = (additionalInfo: string) => {
    setRecipeSteps(
      recipeSteps?.filter(
        (recipeStep: RecipeStep) => recipeStep.additionalInfo !== additionalInfo
      )
    );
    setChanges(changes + 1);
  };

  const handleReorderItems = (
    items: RecipeStep[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(items);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    setChanges((changes) => changes + 1);

    return result;
  };

  const handleOnDragEnd = ({ source, destination }: DropResult) => {
    if (!destination) return;

    const newItems = handleReorderItems(
      recipeSteps,
      source.index,
      destination.index
    );

    setRecipeSteps(newItems);

    setChanges((changes) => changes + 1);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="recipeSteps">
        {(provided) => (
          <div
            id="recipeSteps"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {!!recipeSteps?.length &&
              recipeSteps?.map((recipeStep: RecipeStep, index: number) => (
                <Draggable
                  key={`step-${recipeStep.additionalInfo}`}
                  draggableId={recipeStep.additionalInfo as string}
                  index={index}
                >
                  {(provided) => (
                    <div
                      key={`step-${recipeStep.id}`}
                      className={css({
                        display: "flex",
                        gap: "8px",
                        marginTop: "8px",
                      })}
                      data-test-id="recipe-steps"
                      {...provided.draggableProps}
                      ref={provided.innerRef}
                    >
                      <Button
                        kind="secondary"
                        type="button"
                        size="mini"
                        disabled={disabled}
                        $style={{ alignSelf: "start" }}
                        {...provided.dragHandleProps}
                      >
                        <Menu size={16} />
                      </Button>

                      <TextArea
                        value={recipeStep?.content}
                        error={error}
                        disabled={disabled}
                        size="mini"
                        onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                          setRecipeSteps((recipeSteps) => [
                            ...recipeSteps.map((step) => {
                              return step.additionalInfo ===
                                recipeStep.additionalInfo
                                ? {
                                    ...step,
                                    content: event?.target?.value as string,
                                  }
                                : step;
                            }),
                          ]);

                          setChanges((changes) => changes + 1);
                        }}
                      />

                      <Button
                        onClick={() => {
                          handleDelete(recipeStep.additionalInfo as string);
                        }}
                        kind="secondary"
                        type="button"
                        size="mini"
                        disabled={disabled}
                        $style={{ alignSelf: "start" }}
                      >
                        <Trash size={16} />
                      </Button>
                    </div>
                  )}
                </Draggable>
              ))}

            {provided.placeholder}

            <div
              className={css({
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              })}
            >
              <Button
                type="button"
                kind="secondary"
                disabled={disabled}
                onClick={() => {
                  const newId = generateUUID();

                  setRecipeSteps((recipeSteps) => [
                    ...recipeSteps,
                    {
                      id: newId,
                      additionalInfo: newId,
                    },
                  ]);
                  setChanges((changes) => changes + 1);
                }}
                $style={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                  marginTop: "16px",
                  marginRight: "40px",
                }}
                size="mini"
              >
                <Plus size={14} /> Dodaj krok
              </Button>
            </div>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export function ControlledRecipeStepsComposer({
  control,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & RecipeStepsComposerProps): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }) => {
        return (
          <RecipeStepsComposer
            onChange={onChange}
            value={value}
            id={name}
            {...rest}
          />
        );
      }}
    />
  );
}
