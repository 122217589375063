import { format } from "date-fns/esm";

import { FiltersState, FilterType, SingleFilter } from "../filters.d";

function translateFilterToObject(
  instance: SingleFilter
): Record<string, unknown> {
  switch (instance.filter.type) {
    case FilterType.Activity:
      return {
        activity: {
          like: instance.state.value,
        },
      };

    case FilterType.Boolean:
      return {
        [instance.filter.id]: {
          [!instance.state.not ? "is" : "isNot"]: !!instance.state.is,
        },
      };

    case FilterType.CategoryKind:
      return {
        kind: {
          like: instance.state.value,
        },
      };

    case FilterType.CreatedBy:
      return {
        createdById: {
          eq: parseInt(instance.state.value),
        },
      };

    case FilterType.Date:
      switch (instance.state.kind) {
        case "between":
          return {
            [instance.filter.id]: {
              [!instance.state.not ? "between" : "notBetween"]: {
                lower: new Date(instance.state.from),
                upper: new Date(+new Date(instance.state.to) + 86400000),
              },
            },
          };

        default:
        case "single":
          return {
            [instance.filter.id]: {
              [!instance.state.not ? "between" : "notBetween"]: {
                lower: new Date(instance.state.in),
                upper: new Date(+new Date(instance.state.in) + 86400000),
              },
            },
          };
      }

    case FilterType.DictionaryValues:
      return {
        [instance.filter.id]: {
          like: `%${instance.state.value}%`,
        },
      };

    case FilterType.Role:
      return {
        roles: {
          id: {
            eq: parseInt(instance.state.value),
          },
        },
      };

    case FilterType.ActivityLogsSubjectType:
      return {
        subjectType: {
          like: instance.state.value,
        },
      };

    case FilterType.SeoTagsetsSubjectType:
      return {
        subjectType: {
          like: instance.state.value,
        },
      };

    case FilterType.StaticPageSystemName:
      return {
        systemName: {
          like: instance.state.value,
        },
      };

    case FilterType.Text:
      return {
        [instance.filter.id]: {
          like: `%${instance.state.like}%`,
        },
      };

    case FilterType.UpdatedBy:
      return {
        updatedById: {
          eq: parseInt(instance.state.value),
        },
      };

    case FilterType.User:
      return {
        userId: {
          eq: parseInt(instance.state.value),
        },
      };

    case FilterType.HeaderPosition:
      return {
        [instance.filter.id]: {
          eq: parseInt(instance.state.eq),
        },
      };

    case FilterType.Integer:
      switch (instance.state.kind) {
        case "between":
          return {
            and: [
              ...(instance.state.from
                ? [
                    {
                      [instance.filter.id]: {
                        [!instance.state.not
                          ? instance.state.gtOrEq
                            ? "gte"
                            : "gt"
                          : instance.state.gtOrEq
                          ? "lt"
                          : "lte"]: parseFloat(instance.state.from),
                      },
                    },
                  ]
                : []),
              ...(instance.state.to
                ? [
                    {
                      [instance.filter.id]: {
                        [!instance.state.not
                          ? instance.state.ltOrEq
                            ? "lte"
                            : "lt"
                          : instance.state.ltOrEq
                          ? "gt"
                          : "gte"]: parseFloat(instance.state.to),
                      },
                    },
                  ]
                : []),
            ],
          };

        default:
        case "single":
          return {
            [instance.filter.id]: {
              [!instance.state.not ? "eq" : "neq"]: parseFloat(
                instance.state.eq
              ),
            },
          };
      }

    default:
      return {
        [instance.filter.id]: {},
      };
  }
}

export function translateFiltersState(state: FiltersState): unknown {
  let object = {};

  state
    .filter((instance) => instance.filter.type !== FilterType.Text)
    .forEach((instance) => {
      if (Object.keys(object).length === 0) {
        object = {
          ...translateFilterToObject(instance),
        };
      } else {
        object = {
          ...translateFilterToObject(instance),
          and: object,
        };
      }
    });

  const textFilters = state
    .filter((instance) => instance.filter.type === FilterType.Text)
    .map((instance) => translateFilterToObject(instance));

  if (textFilters.length) {
    object = {
      or: textFilters,
      ...(Object.keys(object).length !== 0 && { and: object }),
    };
  }

  return object;
}

export function translateFilterToString(instance: SingleFilter): string {
  let string = `${instance.filter.label}: `;

  switch (instance.filter.type) {
    case FilterType.Activity:
      string += instance.state.label;
      break;

    case FilterType.Boolean:
      string += instance.state.is ? "Tak" : "Nie";
      break;

    case FilterType.CategoryKind:
      string += instance.state.label;
      break;

    case FilterType.CreatedBy:
      string += instance.state.label;
      break;

    case FilterType.UpdatedBy:
      string += instance.state.label;
      break;

    case FilterType.HeaderPosition:
      string += instance.state.eq;
      break;

    case FilterType.Date:
      switch (instance.state.kind) {
        case "between":
          string += `${format(new Date(instance.state.from), "dd.MM.yyyy")} 
          — ${format(new Date(instance.state.to), "dd.MM.yyyy")}`;
          break;

        default:
        case "single":
          string += `${format(new Date(instance.state.in), "dd.MM.yyyy")}`;
          break;
      }
      break;

    case FilterType.DictionaryValues:
      string += instance.state.label;
      break;

    case FilterType.Role:
      string += instance.state.label;
      break;

    case FilterType.ActivityLogsSubjectType:
      string += instance.state.label;
      break;

    case FilterType.SeoTagsetsSubjectType:
      string += instance.state.label;
      break;

    case FilterType.StaticPageSystemName:
      string += instance.state.label;
      break;

    case FilterType.User:
      string += instance.state.label;
      break;

    case FilterType.Integer:
      switch (instance.state.kind) {
        case "between":
          string += `${
            instance.state.from ? (instance.state.gtOrEq ? "≥" : ">") : ""
          } 
          ${
            instance.state.from
              ? new Intl.NumberFormat("pl-PL", {
                  minimumFractionDigits: 2,
                }).format(parseInt(instance.state.from))
              : ""
          } ${instance.state.to ? (instance.state.ltOrEq ? "≤" : "<") : ""}  ${
            instance.state.to
              ? new Intl.NumberFormat("pl-PL", {
                  minimumFractionDigits: 2,
                }).format(parseInt(instance.state.to))
              : ""
          }`;
          break;

        default:
        case "single":
          string += `${
            instance.state.eq &&
            new Intl.NumberFormat("pl-PL", {
              minimumFractionDigits: 2,
            }).format(parseInt(instance.state.eq))
          }`;
          break;
      }
      break;

    default:
      break;
  }

  return string;
}
