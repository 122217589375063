import {
  API,
  BlockAPI,
  BlockTool,
  BlockToolConstructorOptions,
  BlockToolData,
  ToolboxConfig,
} from "@editorjs/editorjs";

import { useImageLibrary } from "../../../contexts/image-library-context";

interface ImageData extends BlockToolData {
  url?: string;
  caption?: string;
  alt?: string;
  isStretched?: boolean;
  href?: string;
  target?: string;
  rel?: string[];
}

interface ImageConfig {
  imageLibraryContext: ReturnType<typeof useImageLibrary>;
}

type ImageNodes = {
  button?: HTMLButtonElement;
  image?: HTMLImageElement;
  caption?: HTMLDivElement;
  alt?: HTMLDivElement;
  copyrights?: HTMLDivElement;
  href?: HTMLDivElement;
  linkOptions?: HTMLDivElement;
};

type ImageSetting = {
  icon: string;
  name: ImageSettingName;
  label: string;
};

enum ImageSettingName {
  IsStretched = "isStretched",
}

export default class Image implements BlockTool {
  public static isReadOnlySupported = true;
  public static enableLineBreaks = true;
  public static sanitize = {
    url: false,
    isStretched: false,
    caption: {},
    alt: {},
    copyrights: {},
    href: {},
    target: {},
    rel: {},
  };

  private _data: ImageData;
  private isReadOnly: boolean;
  private api: API;
  private config?: ImageConfig;
  private nodes: ImageNodes;
  private block?: BlockAPI;

  static get toolbox(): ToolboxConfig {
    return {
      title: "Zdjęcie",
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <rect x="5" y="5" width="14" height="14" rx="4" stroke="black" stroke-width="2"/>
      <path d="M5.13968 15.32L8.69058 11.5661C9.02934 11.2036 9.48873 11 9.96774 11C10.4467 11 10.9061 11.2036 11.2449 11.5661L15.3871 16M13.5806 14.0664L15.0132 12.533C15.3519 12.1705 15.8113 11.9668 16.2903 11.9668C16.7693 11.9668 17.2287 12.1705 17.5675 12.533L18.841 13.9634" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.7778 9.33331H13.7867" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>`,
    };
  }

  constructor({
    data,
    readOnly,
    api,
    config,
    block,
  }: BlockToolConstructorOptions<ImageData, ImageConfig>) {
    this.isReadOnly = readOnly;
    this.api = api;
    this.config = config;
    this.block = block;

    this.nodes = {
      button: undefined,
      image: undefined,
      caption: undefined,
      alt: undefined,
      copyrights: undefined,
      href: undefined,
      linkOptions: undefined,
    };

    this._data = {
      ...data,
      isStretched: !!data.isStretched,
    };
  }

  get settings(): ImageSetting[] {
    return [
      {
        icon: `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17 9L20 12L17 15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14 12H20" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7 9L4 12L7 15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4 12H10" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      `,
        name: ImageSettingName.IsStretched,
        label: "Rozszerz/Zwęź",
      },
    ];
  }

  get data(): ImageData {
    return this._data;
  }

  set data(data: ImageData) {
    this._data = { ...this.data, ...data };

    if (this.block) {
      this.block.stretched = !!data.isStretched;
    }

    if (data.url) {
      if (this.nodes.image) {
        this.nodes.image.src = data.url;
        this.nodes.image.style.display = "block";
      }

      if (this.nodes.caption) {
        this.nodes.caption.style.display = "block";
      }

      if (this.nodes.alt) {
        this.nodes.alt.style.display = "block";
      }

      if (this.nodes.copyrights) {
        this.nodes.copyrights.style.display = "block";
      }

      if (this.nodes.href) {
        this.nodes.href.style.display = "block";
      }

      if (this.nodes.linkOptions) {
        this.nodes.linkOptions.style.display = "block";
      }

      if (this.nodes.button) {
        this.nodes.button.style.display = "none";
      }
    } else {
      if (this.nodes.image) {
        this.nodes.image.src = "";
        this.nodes.image.style.display = "none";
      }

      if (this.nodes.caption) {
        this.nodes.caption.style.display = "none";
      }

      if (this.nodes.alt) {
        this.nodes.alt.style.display = "none";
      }

      if (this.nodes.copyrights) {
        this.nodes.copyrights.style.display = "none";
      }

      if (this.nodes.href) {
        this.nodes.href.style.display = "none";
      }

      if (this.nodes.linkOptions) {
        this.nodes.linkOptions.style.display = "none";
      }

      if (this.nodes.button) {
        this.nodes.button.style.display = "block";
      }
    }
  }

  render(): HTMLElement {
    const container = document.createElement("div");
    container.classList.add(this.api.styles.block);
    container.style.textAlign = "center";

    const openImageLibrary = (event: Event) => {
      event.preventDefault();

      this.config?.imageLibraryContext?.open((image) => {
        const url = image?.url;

        this.data = {
          ...this.data,
          url,
        };

        this.api.blocks.insert(undefined, undefined, undefined, Infinity);
        this.api.blocks.delete(Infinity);
      });
    };

    const button = document.createElement("button");
    button.classList.add(this.api.styles.button);
    button.innerHTML = "Wgraj lub wybierz z biblioteki";
    button.addEventListener("click", openImageLibrary);

    const image = document.createElement("img");
    image.classList.add(this.api.styles.block, "image");
    image.style.width = "100%";

    // const caption = document.createElement("div");
    // caption.classList.add(this.api.styles.input, "caption");
    // caption.contentEditable = String(!this.isReadOnly);
    // caption.innerHTML = this.data.caption ?? "";
    // caption.style.textAlign = "left";
    // caption.dataset.placeholder = "Tytuł obrazka";

    // const alt = document.createElement("div");
    // alt.classList.add(this.api.styles.input, "alt");
    // alt.contentEditable = String(!this.isReadOnly);
    // alt.innerHTML = this.data.alt ?? "";
    // alt.style.textAlign = "left";
    // alt.dataset.placeholder = "Opis obrazka (alt)";

    // const copyrights = document.createElement("div");
    // copyrights.classList.add(this.api.styles.input, "copyrights");
    // copyrights.contentEditable = String(!this.isReadOnly);
    // copyrights.innerHTML = this.data.copyrights ?? "";
    // copyrights.style.textAlign = "left";
    // copyrights.dataset.placeholder = "Prawa autorskie";

    // const href = document.createElement("div");
    // href.classList.add(this.api.styles.input, "href");
    // href.contentEditable = String(!this.isReadOnly);
    // href.innerHTML = this.data.href ?? "";
    // href.style.textAlign = "left";
    // href.dataset.placeholder = "URL (opcjonalnie)";

    // const linkOptions = document.createElement("div");
    // linkOptions.classList.add(this.api.styles.input);

    // const targetOptions = ["_blank", "_self"];
    // const target = document.createElement("select");
    // target.classList.add(this.api.styles.input, "target");
    // target.onchange = (event) => {
    //   event.preventDefault();
    //   this.data.target = Array.from(target.selectedOptions)
    //     .map((el) => (el as HTMLOptionElement).value)
    //     .join("");
    //   this.update();
    // };

    // for (let i = 0; i < targetOptions.length; i++) {
    //   const option = document.createElement("option");
    //   option.value = targetOptions[i];
    //   option.text = targetOptions[i];
    //   option.selected = !!this.data.target?.includes(targetOptions[i]);
    //   target.appendChild(option);
    // }

    // const relOptions = ["nofollow", "dofollow", "noreferrer", "author"];
    // const rel = document.createElement("select");
    // rel.multiple = true;
    // rel.classList.add(this.api.styles.input, "rel");
    // rel.onchange = (event) => {
    //   event.preventDefault();
    //   this.data.rel = Array.from(rel.selectedOptions).map(
    //     (el) => (el as HTMLOptionElement).value
    //   );
    //   this.update();
    // };

    // for (let i = 0; i < relOptions.length; i++) {
    //   const option = document.createElement("option");
    //   option.value = relOptions[i];
    //   option.text = relOptions[i];
    //   option.selected = !!this.data.rel?.includes(relOptions[i]);
    //   rel.appendChild(option);
    // }

    // linkOptions.appendChild(target);
    // linkOptions.appendChild(rel);

    if (this.data.url) {
      image.src = this.data.url;
      button.style.display = "none";
    } else {
      image.style.display = "none";
      // caption.style.display = "none";
      // alt.style.display = "none";
      // copyrights.style.display = "none";
      // href.style.display = "none";
      // linkOptions.style.display = "none";
    }

    container.appendChild(button);
    container.appendChild(image);
    // container.appendChild(caption);
    // container.appendChild(alt);
    // container.appendChild(copyrights);
    // container.appendChild(href);
    // container.appendChild(linkOptions);

    this.nodes.button = button;
    this.nodes.image = image;
    // this.nodes.caption = caption;
    // this.nodes.alt = alt;
    // this.nodes.copyrights = copyrights;
    // this.nodes.href = href;
    // this.nodes.linkOptions = linkOptions;

    // If the stretched prop is set without setTimeout, the block doesn't render
    setTimeout(() => {
      if (this.block) {
        this.block.stretched = !!this.data.isStretched;
      }
    }, 0);

    return container;
  }

  renderSettings() {
    return this.settings.map((setting: ImageSetting) => {
      return {
        icon: setting.icon,
        label: setting.label,
        isActive: this.data.isStretched ?? false,
        onActivate: () => this.toggleIsStretched(),
        closeOnActivate: true,
      };
    });
  }

  private toggleIsStretched(): void {
    this.data.isStretched = !this.data.isStretched;
    if (this.block) {
      this.block.stretched = !!this.data.isStretched;
    }

    this.update();
  }

  private update(): void {
    this.api.blocks.insert(undefined, undefined, undefined, Infinity);
    this.api.blocks.delete(Infinity);
  }

  save(rootElement: HTMLDivElement): ImageData {
    const caption = rootElement.querySelector(".caption")?.innerHTML || "";
    const alt = rootElement.querySelector(".alt")?.innerHTML || "";
    const copyrights =
      rootElement.querySelector(".copyrights")?.innerHTML || "";
    const href = rootElement.querySelector(".href")?.innerHTML || "";
    const target =
      (rootElement.querySelector(".target") as HTMLSelectElement)?.value || "";

    const selected = rootElement.querySelectorAll(".rel option:checked");
    const rel = Array.from(selected).map(
      (el) => (el as HTMLOptionElement).value
    );

    return {
      ...this.data,
      caption,
      alt,
      copyrights,
      href,
      target,
      rel,
    };
  }
}
