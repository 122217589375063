import { CategoryKind } from "containers/Categories/categories.d";
import React, { ReactElement } from "react";
import { Controller, UseControllerProps } from "react-hook-form";

import Select, { SelectProps } from "./select";

const CATEGORY_KINDS = [
  { id: CategoryKind.BestRecipes, label: "Najlepsze na Uwielbiam" },
  { id: CategoryKind.Cuisine, label: "Rodzaj kuchni" },
  { id: CategoryKind.Diet, label: "Rodzaj diety" },
  { id: CategoryKind.Meal, label: "Rodzaj posiłku" },
  { id: CategoryKind.Occasion, label: "Okazja" },
];

export default function CategoryKindSelect({
  ...rest
}: SelectProps): ReactElement {
  return <Select options={CATEGORY_KINDS} placeholder="Wybierz" {...rest} />;
}

export function ControlledCategoryKindSelect({
  control,
  disabled,
  name,
  rules,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & SelectProps): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Select
          id={name}
          options={CATEGORY_KINDS}
          placeholder="Wybierz"
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          disabled={disabled}
          {...rest}
          {...rest}
        />
      )}
    />
  );
}
