import { BasicFilter, FilterType } from "./../../filters.d";
import { BrandsField } from "./brands.form";

export const BRANDS_FILTERS: BasicFilter[] = [
  {
    id: BrandsField.Name,
    label: "Nazwa",
    type: FilterType.Text,
  },
  {
    id: BrandsField.Slug,
    label: "Slug",
    type: FilterType.Text,
  },
  {
    id: BrandsField.WebsiteUrl,
    label: "Adres strony WWW",
    type: FilterType.Text,
  },
  {
    id: BrandsField.CreatedAt,
    label: "Utworzenie",
    type: FilterType.Date,
  },
  {
    id: BrandsField.CreatedBy,
    label: "Utworzono przez",
    type: FilterType.CreatedBy,
  },
  {
    id: BrandsField.UpdatedAt,
    label: "Ostatnia aktualizacja",
    type: FilterType.Date,
  },
  {
    id: BrandsField.UpdatedBy,
    label: "Zaktualizowano przez",
    type: FilterType.UpdatedBy,
  },
  {
    id: BrandsField.IsVisible,
    label: "Widoczność",
    type: FilterType.Boolean,
  },
];
