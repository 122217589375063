import { OutputData } from "@editorjs/editorjs";
import { AuthorSelectorValue } from "components/author-selector/author-selector";
import { DataType } from "components/formatted-value";
import { SelectValue } from "components/select/select";
import { Brand } from "containers/Brands/brands";
import { Category } from "containers/Categories/categories";
import { SeoTagsetsField } from "containers/SeoTagsets/seo-tagsets.form";
import { Tag } from "containers/Tags/tags";

import { DictionarySystemName } from "../../constants";
import { FieldsGroup, FieldType } from "../../fields.d";

export type ArticlesFormInputs = {
  name: string;
  slug: string;
  lead: string;
  isVisible: boolean;
  categories: Category[];
  tags: Tag[];
  image: File;
  metaTitle: string;
  metaDescription: string;
  metaKeywords: string;
  ogTitle: string;
  ogDescription: string;
  canonical: string;
  robots: SelectValue;
  content: OutputData[];
  author: AuthorSelectorValue;
  brand: Brand[];
};

export enum ArticlesField {
  Name = "name",
  Slug = "slug",
  Lead = "lead",
  Content = "content",
  CreatedAt = "createdAt",
  UpdatedAt = "updatedAt",
  CreatedBy = "createdBy",
  UpdatedBy = "updatedBy",
  IsVisible = "isVisible",
  Categories = "categories",
  Tags = "tags",
  ReadTime = "readTime",
  Image = "image",
  ImageUrl = "imageUrl",
  Author = "author",
  SeoTagset = "seoTagset",
  Brand = "brand",
}

export const ARTICLES_FIELDS: FieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: ArticlesField.Name,
        label: "Nazwa",
        type: FieldType.BigInput,
        span: 12,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
      },
      {
        id: ArticlesField.Slug,
        label: "Slug",
        type: FieldType.SlugInput,
        dataType: DataType.Pre,
        span: 12,
        show: { visible: true },
        create: { visible: true, required: true },
        update: { visible: true, required: true },
        caption:
          "Slug jest generowany automatycznie na podstawie nazwy. Możesz go zmienić, edytując powyższe pole.",
      },
      {
        id: ArticlesField.Slug,
        label: "Link publiczny",
        type: FieldType.Link,
        span: 12,
        show: { visible: true },
        caption:
          "Link do udostępnienia. Uwaga: Jeśli widoczność jest ustawiona na „nie”, pozycja nie jest dostępna publicznie",
        create: { visible: false, required: false },
        update: { visible: false, required: false },
      },
      {
        id: ArticlesField.Author,
        label: "Autor",
        span: 12,
        type: FieldType.Author,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: ArticlesField.IsVisible,
        label: "Widoczność",
        type: FieldType.Switch,
        dataType: DataType.VisibilityBoolean,
        readonly: true,
        span: 6,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: ArticlesField.ReadTime,
        label: "Czas czytania",
        type: FieldType.NumberInput,
        dataType: DataType.Minutes,
        endEnhancer: "min",
        span: 6,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
    ],
  },
  {
    id: "media",
    label: "Media",
    fields: [
      {
        id: ArticlesField.ImageUrl,
        label: "Grafika",
        type: FieldType.Image,
        span: 12,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ArticlesField.Image,
        label: "Grafika",
        type: FieldType.ImageCropper,
        span: 12,
        imageRequirements: { aspect: 1.5 },
        show: { visible: false },
        create: { visible: true },
        update: { visible: true },
      },
    ],
  },
  {
    id: "content",
    label: "Treści",
    fields: [
      {
        id: ArticlesField.Lead,
        label: "Lead",
        span: 12,
        type: FieldType.TextArea,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: ArticlesField.Content,
        label: "Treść",
        span: 12,
        type: FieldType.Editor,
        show: { visible: true },
        create: { visible: true },
        update: { visible: true },
      },
    ],
  },
  {
    id: ArticlesField.Tags,
    label: "Relacje",
    fields: [
      {
        id: ArticlesField.Brand,
        label: "Marka",
        type: FieldType.BrandsSelect,
        dataType: DataType.Brands,
        span: 12,
        show: { visible: true, accessor: ["brand", "name"] },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: ArticlesField.Categories,
        label: "Kategorie",
        span: 12,
        type: FieldType.CategoriesSelect,
        dataType: DataType.Categories,
        show: { visible: false },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: ArticlesField.Categories,
        label: "Kategorie",
        span: 12,
        type: FieldType.Multi,
        dataType: DataType.Categories,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ArticlesField.Tags,
        label: "Tagi",
        span: 12,
        type: FieldType.TagsSelect,
        dataType: DataType.Tags,
        show: { visible: false },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: ArticlesField.Tags,
        label: "Tagi",
        span: 12,
        type: FieldType.Multi,
        dataType: DataType.Tags,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
    ],
  },
  {
    id: "seo",
    label: "Tagi SEO",
    fields: [
      {
        id: SeoTagsetsField.MetaTitle,
        label: "Tytuł meta",
        caption: "Maks. 70 znaków ze spacjami",
        maxLength: 70,
        span: 6,
        type: FieldType.Input,
        show: {
          visible: true,
          accessor: [ArticlesField.SeoTagset, SeoTagsetsField.MetaTitle],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.OgTitle,
        label: "Tytuł OG",
        caption: "Maks. 70 znaków ze spacjami",
        maxLength: 70,
        span: 6,
        type: FieldType.Input,
        show: {
          visible: true,
          accessor: [ArticlesField.SeoTagset, SeoTagsetsField.OgTitle],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.MetaDescription,
        label: "Opis meta",
        caption: "Maks. 256 znaków ze spacjami",
        maxLength: 256,
        span: 6,
        type: FieldType.TextArea,
        show: {
          visible: true,
          accessor: [ArticlesField.SeoTagset, SeoTagsetsField.MetaDescription],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.OgDescription,
        label: "Opis OG",
        caption: "Maks. 256 znaków ze spacjami",
        maxLength: 256,
        span: 6,
        type: FieldType.TextArea,
        show: {
          visible: true,
          accessor: [ArticlesField.SeoTagset, SeoTagsetsField.OgDescription],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.MetaKeywords,
        label: "Słowa kluczowe meta",
        caption: "Wpisz po przecinku",
        span: 12,
        type: FieldType.Input,
        show: {
          visible: true,
          accessor: [ArticlesField.SeoTagset, SeoTagsetsField.MetaKeywords],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.Robots,
        label: "Robots",
        span: 4,
        type: FieldType.DictionaryValuesSelect,
        dictionarySystemName: DictionarySystemName.RobotsSeoTagsets,
        show: {
          visible: true,
          accessor: [ArticlesField.SeoTagset, SeoTagsetsField.Robots],
        },
        create: { visible: true },
        update: { visible: true },
      },
      {
        id: SeoTagsetsField.Canonical,
        label: "Link canonical",
        span: 8,
        type: FieldType.Input,
        show: {
          visible: true,
          accessor: [ArticlesField.SeoTagset, SeoTagsetsField.Canonical],
        },
        create: { visible: true },
        update: { visible: true },
      },
    ],
  },
  {
    id: "system",
    label: "Dane systemowe",
    fields: [
      {
        id: ArticlesField.CreatedAt,
        label: "Utworzenie",
        span: 3,
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ArticlesField.CreatedBy,
        label: "Utworzono przez",
        span: 3,
        type: FieldType.UserFullName,
        dataType: DataType.Users,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ArticlesField.UpdatedAt,
        label: "Ostatnia aktualizacja",
        span: 3,
        type: FieldType.Input,
        dataType: DataType.DateTime,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
      {
        id: ArticlesField.UpdatedBy,
        label: "Zaktualizowano przez",
        span: 3,
        type: FieldType.UserFullName,
        dataType: DataType.Users,
        show: { visible: true },
        create: { visible: false },
        update: { visible: false },
      },
    ],
  },
];
