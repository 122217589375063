import { gql } from "@apollo/client";

export const CATEGORIES_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $filter: CategoryFilter
    $sorting: [CategorySort!]
  ) {
    categories(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        name
        slug
        isVisible
        isFeatured
        createdAt
        updatedAt
        position
        showInShortcuts
        shortcutPosition
        isAvailableInFilters
        kind
        parent {
          id
          name
        }
        children {
          id
          name
          slug
          createdAt
          updatedAt
        }
        color
      }
    }
  }
`;

export const CATEGORIES_SHOW = gql`
  query CategoryShow($id: Int!) {
    category(id: $id) {
      id
      name
      slug
      description
      isVisible
      isFeatured
      imageUrl
      createdAt
      updatedAt
      position
      showInShortcuts
      shortcutPosition
      isAvailableInFilters
      kind
      parent {
        id
        name
      }
      children {
        id
        name
        slug
        createdAt
        updatedAt
      }
      color
      seoTagset {
        id
        metaTitle
        metaDescription
        metaKeywords
        ogTitle
        ogDescription
        canonical
        robots
      }
    }
  }
`;

export const CATEGORIES_CREATE = gql`
  mutation CategoryCreate(
    $categoryCreateInput: CategoryCreateInput!
    $seoTagsetUpsertInput: SeoTagsetUpsertInput
  ) {
    categoryCreate(
      categoryCreateInput: $categoryCreateInput
      seoTagsetUpsertInput: $seoTagsetUpsertInput
    ) {
      id
    }
  }
`;

export const CATEGORIES_UPDATE = gql`
  mutation CategoryUpdate(
    $categoryUpdateInput: CategoryUpdateInput!
    $seoTagsetUpsertInput: SeoTagsetUpsertInput
  ) {
    categoryUpdate(
      categoryUpdateInput: $categoryUpdateInput
      seoTagsetUpsertInput: $seoTagsetUpsertInput
    ) {
      id
    }
  }
`;

export const CATEGORIES_DELETE = gql`
  mutation CategoryDelete($categoryDeleteInput: CategoryDeleteInput!) {
    categoryDelete(categoryDeleteInput: $categoryDeleteInput) {
      id
    }
  }
`;
