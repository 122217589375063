import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { KIND } from "baseui/button";
import { AuthorType } from "components/author-selector/author-selector";
import { ConfirmDialog } from "components/confirm-dialog";
import { Content } from "components/content";
import { FormRenderer } from "components/form-renderer";
import { Header } from "components/header";
import { NoPermissionsRedirect } from "components/no-permissions-redirect";
import { Category } from "containers/Categories/categories";
import { Tag } from "containers/Tags/tags";
import { useAuth } from "contexts/auth-context";
import { useLoading } from "contexts/loading-context";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { DeviceFloppy, FileOff } from "tabler-icons-react";
import { PERMISSIONS } from "utils/permissions";
import { scrollOnError } from "utils/scrollOnError";

import { Article } from "../articles";
import { ARTICLES_FIELDS, ArticlesFormInputs } from "../articles.form";
import { ARTICLES_SHOW, ARTICLES_UPDATE } from "../articles.gql";

export default function ArticlesUpdate(): React.ReactElement {
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] = useState(
    false
  );
  const { enqueueSnackbar } = useSnackbar();
  const {
    setIsPartialFetching,
    setIsFetching,
    isLoading,
    setIsLoading,
  } = useLoading();
  const { checkPermission } = useAuth();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();

  const {
    control,
    formState: { errors, isDirty, dirtyFields, ...formState },
    handleSubmit,
    setValue,
    reset,
    watch,
    ...methods
  } = useForm<ArticlesFormInputs>();

  const { refetch, data, error: queryError } = useQuery(ARTICLES_SHOW, {
    variables: { id: id ? parseInt(id) : null },
  });
  const article: Article = data?.article;
  useEffect(() => {
    setIsFetching(true);

    if (data?.article) refetch();
  }, []);

  useEffect(() => {
    if (queryError?.graphQLErrors)
      enqueueSnackbar({
        message: (queryError as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [queryError]);

  useEffect(() => {
    async function setFile() {
      const response = await fetch(article?.imageUrl);
      const data = await response.blob();

      const extension = /\.(jpe?g|png|gif|svg)$/gi.exec(article?.imageUrl)?.[1];

      const metadata = {
        type: `image/${extension === "svg" ? "svg+xml" : "png"}`,
      };

      const file = new File([data], `${article?.slug}.${extension}`, metadata);

      setValue("image", file);

      setIsFetching(false);
    }

    if (article) {
      setValue("name", article?.name);
      setValue("lead", article?.lead);
      setValue("isVisible", article?.isVisible);
      setValue("slug", article?.slug);
      setValue("content", article?.content);
      setValue("tags", article?.tags);
      setValue("categories", article?.categories);
      setValue("metaTitle", article?.seoTagset?.metaTitle);
      setValue("metaDescription", article?.seoTagset?.metaDescription);
      setValue("ogTitle", article?.seoTagset?.ogTitle);
      setValue("ogDescription", article?.seoTagset?.ogDescription);
      setValue("metaKeywords", article?.seoTagset?.metaKeywords);
      setValue("canonical", article?.seoTagset?.canonical);
      !!article?.seoTagset?.robots &&
        setValue("robots", [
          {
            id: article?.seoTagset?.robots,
            label: article?.seoTagset?.robots,
          },
        ]);
      !!article?.brand && setValue("brand", [article?.brand]);

      if (article?.author?.user) {
        setValue("author", {
          type: AuthorType.User,
          value: article?.author?.user?.id,
          user: {
            firstName: article?.author?.user?.firstName,
            lastName: article?.author?.user?.lastName,
            username: article?.author?.user?.username,
            email: article?.author?.user?.email,
          },
        });
      }
      if (article?.author?.brand) {
        setValue("author", {
          type: AuthorType.Brand,
          value: article?.author?.brand?.id,
        });
      }
      if (article?.author?.name) {
        setValue("author", {
          type: AuthorType.AuthorName,
          value: article?.author?.name,
        });
      }

      setTimeout(() => setIsFetching(false), 250);

      if (article?.imageUrl) {
        setTimeout(() => {
          setIsPartialFetching(true);

          setFile().then(() => setIsPartialFetching(false));
        }, 500);
      }
    }
  }, [data]);

  const [errorTimeStamp, setErrorTimeStamp] = useState<number>();

  const onError = (errors: Record<string, unknown>, e: any) =>
    setErrorTimeStamp(e.timeStamp);

  useEffect(() => {
    if (errors) scrollOnError(errors, ARTICLES_FIELDS, "update");
  }, [errorTimeStamp]);

  const [updateArticle, { error }] = useMutation(ARTICLES_UPDATE);

  const onSubmit = async ({
    categories,
    isVisible,
    tags,
    metaTitle,
    metaDescription,
    metaKeywords,
    ogTitle,
    ogDescription,
    canonical,
    robots,
    author,
    brand,
    image,
    ...values
  }: ArticlesFormInputs): Promise<void> => {
    setIsLoading(true);

    try {
      await updateArticle({
        variables: {
          articleUpdateInput: {
            id: id ? parseInt(id) : null,
            isVisible: !!isVisible,
            categoryIds: categories?.map((category: Category) => category?.id),
            brandId: brand?.length ? brand?.[0]?.id : null,
            tagIds: tags?.map((tag: Tag) => tag?.id),
            ...(dirtyFields.image && { image: image || null }),
            ...values,
          },
          authorAssignInput: {
            ...(author.type === AuthorType.User && {
              userId: author.value,
            }),
            ...(author.type === AuthorType.Brand && {
              brandId: author.value,
            }),
            ...(author.type === AuthorType.AuthorName && {
              authorName: author.value,
            }),
          },
          seoTagsetUpsertInput: {
            metaTitle,
            metaDescription,
            metaKeywords,
            ogTitle,
            ogDescription,
            canonical,
            robots: robots?.length ? robots?.[0]?.label : null,
          },
        },
      });

      enqueueSnackbar({
        message: "Zapisano pomyślnie",
        variant: "success",
      });
      history.push(`/articles/${id}`);
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!checkPermission(PERMISSIONS.article.update))
    return <NoPermissionsRedirect />;

  return (
    <article>
      <Header
        title={article?.name}
        labels={["Artykuły", "Edytowanie"]}
        goBackOption
        buttons={[
          {
            label: "Anuluj",
            kind: KIND.secondary,
            startEnhancer: <FileOff size={18} />,
            disabled: isLoading,
            onClick: () =>
              isDirty ? setIsCancelConfirmDialogOpen(true) : history.goBack(),
          },
          {
            label: "Zapisz",
            kind: KIND.primary,
            startEnhancer: <DeviceFloppy size={18} />,
            onClick: handleSubmit(onSubmit, onError),
            disabled: isLoading,
            isLoading: isLoading,
            type: "submit",
            formId: "updateArticle",
          },
        ]}
      />
      <Content>
        <FormProvider<ArticlesFormInputs>
          control={control}
          formState={{ errors, isDirty, dirtyFields, ...formState }}
          handleSubmit={handleSubmit}
          reset={reset}
          setValue={setValue}
          watch={watch}
          {...methods}
        >
          <FormRenderer<ArticlesFormInputs>
            id="updateArticle"
            onSubmit={handleSubmit(onSubmit, onError)}
            type="update"
            isLoading={isLoading}
            error={error}
            fields={ARTICLES_FIELDS}
          />
        </FormProvider>
        <ConfirmDialog
          isOpen={isCancelConfirmDialogOpen}
          label="Anulowanie edycji artykułu"
          close={() => setIsCancelConfirmDialogOpen(false)}
          confirm={() => history.goBack()}
        />
      </Content>
    </article>
  );
}
